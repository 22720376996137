<main id="main" class="main">
    <div class="pagetitle">
        <span class="header-large">{{ 'profiles.profiles' | translate }}</span>
        <div class="pagetitle-buttons">
            <button class="ct-button ct-button--secondary" (click)="refrescar()">
                <mat-icon class="ct-icon" svgIcon="refresh-ccw-01"></mat-icon> 
                <span class="hide-on-mobile"> {{'common.refresh' | translate }} </span>
            </button>

            <ng-container *ngIf="rol.canByInput(rol.functIds.edit_profiles)">
                <button class="ct-button ct-button--primary" (click)="onNew()">
                    <mat-icon class="ct-icon" svgIcon="plus"></mat-icon> 
                    <span class="hide-on-mobile"> {{ 'profiles.new' | translate }} </span>
                </button>
            </ng-container>
        </div>
    </div>

    <!-- Filtro de perfiles -->
    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

    <div id="tableDescription" hidden> {{'profiles.tableDescription' | translate }} </div>

    <!-- Tabla de Perfiles -->
    <div class="mat-elevation-z2 tablaPersonal">
        <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" matSort
            (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter" [hiddenColumns]="getNotExportColumns()">
            
            <!-- Column Definitions -->
            
            <!-- Columna Nombre -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
                    <div mat-sort-header>{{ 'profiles.name' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{ element.name }} </td>
            </ng-container>

            <!-- Operator Column -->
            <ng-container matColumnDef="idOperator">
                <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'hierarchy.level_1' | translate }}</div></th>
                <td mat-cell *matCellDef="let element"> {{element.idOperator | levelName:'LEVEL_1'}} </td>
            </ng-container>

            <!-- Columna Cliente -->
            <ng-container matColumnDef="idClient">
                <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'hierarchy.level_2' | translate }}</div></th>
                <td mat-cell *matCellDef="let element">{{ element.idClient | levelName:'LEVEL_2' }}</td>
            </ng-container>

            <!-- Columna Subcliente -->
            <ng-container matColumnDef="idSubClient">
                <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'hierarchy.level_3' | translate }}</div></th>
                <td mat-cell *matCellDef="let element">{{ element.idSubClient | levelName:'LEVEL_3' }}</td>
            </ng-container>

            <!-- Columna Descripción -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef style="width:30%"><div mat-sort-header>{{ 'profiles.description' | translate }}</div></th>
                <td mat-cell *matCellDef="let element" class="cell-truncate" style="width:30%"> {{ element.description
                    }} </td>
            </ng-container>

            <!-- Columna Fecha Creación -->
            <ng-container matColumnDef="dateCreate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'profiles.dateCreate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="cell-truncate"> {{ element.dateCreate |
                    dateOnTimeZone:true }}
                </td>
            </ng-container>
            <!-- Columna Fecha Actualización -->
            <ng-container matColumnDef="dateUpdate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'profiles.dateUpdate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="cell-truncate"> {{ element.dateUpdate |
                    dateOnTimeZone:true }}
                </td>
            </ng-container>

            <!-- Columna Dispositivos Asociados -->
            <ng-container matColumnDef="numMaquinas">
                <th mat-header-cell *matHeaderCellDef style="width: 90px;">
                    <div mat-sort-header i18n>{{ 'profiles.associated' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="cell-truncat" style="width: 90px;"> {{ element.numMaquinas
                    }} </td>
            </ng-container>

            <!-- Columna Código -->
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef style="width: 90px;">{{ 'profiles.code' | translate }}</th>
                <td mat-cell *matCellDef="let element" style="width: 90px;">
                    <div class="hidden-tag">{{ element.cod }}</div>
                    <button *ngIf="element.cod" mat-icon-button (click)="onQrCodeClick(element)">
                        <i class="bi bi-qr-code"></i>
                    </button>
                </td>
            </ng-container>

            <!-- Columna Acciones (Editar) -->
            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
                <td mat-cell *matCellDef="let element" class="button" style="width: 60px;">
                    <ng-container *ngIf="rol.canByInput(rol.functIds.edit_profiles)">
                        <button matTooltip="{{ 'common.view' | translate }}" (click)="onEdit(element)" mat-icon-button
                            class="iconoTabla">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                    </ng-container>
                </td>
            </ng-container>

            <!-- Filas de la tabla -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- Empty state -->
        <ng-container *ngIf="!dataSource.data.length">
            <app-table-empty-state></app-table-empty-state>
        </ng-container>

        <!-- Botón para exportar -->
        <button id="export" class="icoDownload" mat-icon-button
            (click)="exporter.exportTable('xlsx', { fileName: exportFileName })">
            <i class="bi bi-file-earmark-arrow-down"></i>
        </button>

        <!-- Paginador -->
        <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</main>