<ng-container *ngIf="profile">
  <div class="edit-panel">
    <div class="header">
      <div class="title">{{ profile.name }}</div>
      <div class="action-buttons">
        <button class="ct-button ct-button--secondary" (click)="onClone()" [disabled]="!form.valid">
          {{ 'common.clonar' | translate }}
        </button>
        
        <button class="ct-button ct-button--primary" (click)="onSave()" [disabled]="form.pristine || form.invalid">
          {{ 'common.save' | translate }}
        </button>

        <button class="ct-button ct-button--tertiary" (click)="onCancel()">
          <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
        </button>
      </div>
    </div>

    <!-- Main Form -->
    <form [formGroup]="form">
      <!-- Details Section -->
      <fieldset class="edit-container bordered">
        <legend class="details-header" (click)="toggleSection('details')">
          <label class="title-small">{{ 'profiles.details' | translate }}</label>
          <mat-icon class="toggle-icon">{{ isOpen['details'] ? 'expand_less' : 'expand_more' }}</mat-icon>
        </legend>
        <div class="edit-content" *ngIf="isOpen['details']">
          <div class="form-group">
            <mat-form-field appearance="outline"> 
              <mat-label>{{ 'profiles.name'| translate }}</mat-label>             
              <input matInput formControlName="name" placeholder="{{ 'profiles.placeholder.name' | translate }}" required>
              <mat-error *ngIf="form.get('name')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
              <mat-error *ngIf="form.get('name')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'profiles.description'| translate }}</mat-label>   
              <textarea matInput formControlName="description" rows="4" maxlength="200" 
                cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"
                placeholder="{{ 'profiles.placeholder.description' | translate }}" class="textarea" required>
              </textarea>
              <mat-error *ngIf="form.get('description')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
              <mat-hint align="end">{{ form.get('description')?.value?.length || 0 }} / 200</mat-hint>
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'common.factory'| translate }}</mat-label> 
              <input matInput formControlName="manufacID" placeholder="{{ 'profiles.placeholder.factory' | translate }}" required>
            </mat-form-field>
          </div>
          
        </div>
      </fieldset>
     

      <!-- Terminals Section -->
      <fieldset class="edit-container bordered">
        <legend class="details-header" (click)="toggleSection('terminals')">
          <label class="title-small">{{ 'profiles.terminals' | translate }}</label>
          <mat-icon class="toggle-icon">{{ isOpen['terminals'] ? 'expand_less' : 'expand_more' }}</mat-icon>
        </legend>
        <div class="edit-content" *ngIf="isOpen['terminals']">
          <mat-action-list class="p-0">
            <button mat-list-item class="mat-action-list" (click)="openEditTerminalsScreen()">
              <div mat-line>{{ 'profiles.associatedTerminals' | translate }}</div>
              <div mat-line class="detail">{{ profile.numMaquinas }} {{ 'profiles.associated' | translate }}</div>
              <div matListItemIcon class="mat-action-list-action">
                <label>{{ 'common.modify' | translate }}</label>
                <mat-icon>chevron_right</mat-icon>
              </div>
            </button>
            <button mat-list-item class="mat-action-list" (click)="openAddTerminalsScreen()">
              <div mat-line>{{ 'profiles.newTerminals' | translate }}</div>
              <div matListItemIcon class="mat-action-list-action">
                <label>{{ 'common.add' | translate }}</label>
                <mat-icon>chevron_right</mat-icon>
              </div>
            </button>
          </mat-action-list>
        </div>
      </fieldset>

      <!-- Apps Section -->
      <fieldset class="edit-container bordered">
        <legend class="details-header" (click)="toggleSection('apps')">
          <label class="title-small">{{ 'profiles.aplications' | translate }}</label>
          <mat-icon class="toggle-icon">{{ isOpen['apps'] ? 'expand_less' : 'expand_more' }}</mat-icon>
        </legend>
        <div class="edit-content" *ngIf="isOpen['apps']">
          <div>
            <div class="d-flex justify-content-between my-2">
              <label class="title-xs">{{ 'profiles.associatedApps' | translate }} {{ profile.listApp.length }}</label>
              <button class="ct-button ct-button--secondary" (click)="saveRelatedApps()"> {{ 'common.saveOrder' | translate }} </button>
            </div>
            <app-list-related-apps [idProfile]="profile.id" [data]="profile.listApp" [apps]="availableApps"
              [parameters]="parameters" (delete)="deleteApp($event)" (edit)="updateRelatedAppScreen($event)" (sort)="sortedRelatedApps($event)"></app-list-related-apps>
            <mat-action-list>
              <button mat-list-item class="mat-action-list" (click)="addRelatedAppsScreen()">
                <div mat-line>{{ 'profiles.newApp' | translate }}</div>
                <div matListItemIcon class="mat-action-list-action">
                  <label>{{ 'common.add' | translate }}</label>
                  <mat-icon>chevron_right</mat-icon>
                </div>
              </button>
            </mat-action-list>
          </div>
        </div>
      </fieldset>


 <!-- Comandos Section 
 <fieldset class="edit-container bordered">
  <legend class="details-header" (click)="toggleSection('comandos')">
    <label class="title-small">{{ 'profiles.comandos' | translate }}</label>
    <mat-icon class="toggle-icon">{{ isOpen['comandos'] ? 'expand_less' : 'expand_more' }}</mat-icon>
  </legend>
  <div class="edit-content" *ngIf="isOpen['comandos']">
    <div class="c">
      <label class="title-xs">{{ 'profiles.associatedComand' | translate }}</label>

      //////////////// Añadir filas ///////////////////

      <mat-action-list>
        <button mat-list-item class="mat-action-list" (click)="addRelatedComandScreen()">
          <div mat-line>{{ 'profiles.newComand' | translate }}</div>
          <div matListItemIcon class="mat-action-list-action">
            <label>{{ 'common.add' | translate }}</label>
            <mat-icon>chevron_right</mat-icon>
          </div>
        </button>
      </mat-action-list>
    </div>
  </div>
</fieldset>-->


      <!-- Distance Section -->
       
      <!-- [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad -->
      <!--fieldset class="edit-container bordered">
        <legend class="details-header" (click)="toggleSection('distance')">
          <label class="title-small">{{ 'profiles.distance' | translate }}</label>
          <mat-icon class="toggle-icon">{{ isOpen['distance'] ? 'expand_less' : 'expand_more' }}</mat-icon>
        </legend>
        <div class="edit-content" *ngIf="isOpen['distance']">
          <mat-slide-toggle formControlName="enableLocation" class="toggle-selector">
            {{ 'profiles.distanceLocation' | translate }}
          </mat-slide-toggle>
          <div class="form-group distance-units-container my-3">
            <div class="distance-field">
              <mat-form-field appearance="outline">                
                <mat-label>{{ 'profiles.distance'| translate }}</mat-label> 
                <input matInput formControlName="distanceLocation" type="number" min="0" placeholder="{{ 'profiles.placeholder.distance' | translate }}">
                <mat-error *ngIf="form.get('distanceLocation')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                <mat-error *ngIf="form.get('distanceLocation')?.hasError('max')">{{ 'error.maxValue' | translate }}</mat-error>
                <mat-error *ngIf="form.get('distanceLocation')?.hasError('min')">{{ 'error.minValue' | translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="units-field">
              <mat-form-field appearance="outline">                
                <mat-label>{{ 'profiles.units'| translate }}</mat-label> 
                <mat-select formControlName="distanceUnit">
                  <mat-option value="km">{{'common.kilometers' | translate }}</mat-option>
                  <mat-option value="m">{{'common.meters' | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </fieldset!-->


   <!-- Hierarchy Section -->
   <fieldset class="edit-container bordered">
    <legend class="details-header" (click)="toggleSection('hierarchy')">
      <label class="title-small">{{ 'editPanel.hierarchy' | translate }}</label>
      <mat-icon class="toggle-icon">{{ isOpen['hierarchy'] ? 'expand_less' : 'expand_more' }}</mat-icon>
    </legend>
    
    <div class="edit-content row" *ngIf="isOpen['hierarchy']">
      <div class="form-group">
        <!-- Hierarchy Form-->
        <app-hierarchy-selector formControlName="hierarchy"></app-hierarchy-selector>
      </div>
    </div>
  </fieldset>

  <!-- Date Fields Section -->
  <fieldset class="edit-container bordered">
    <legend class="details-header" (click)="toggleSection('dateFields')">
      <label class="title-small">{{ 'profiles.dates' | translate }}</label>
      <mat-icon class="toggle-icon">{{ isOpen['dateFields'] ? 'expand_less' : 'expand_more' }}</mat-icon>
    </legend>
    <div class="edit-content row" *ngIf="isOpen['dateFields']">
      <!-- Registration Date -->
      <div class="form-group col col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'profiles.dateCreate'| translate }}</mat-label> 
          <mat-icon matPrefix>calendar_month</mat-icon>
          <input matInput formControlName="dateCreate" type="text"
            placeholder="{{ 'profiles.dateCreate' | translate }}">
        </mat-form-field>
      </div>

      <!-- Activation Date -->
      <div class="form-group col col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'profiles.dateUpdate'| translate }}</mat-label> 
          <mat-icon matPrefix>calendar_month</mat-icon>
          <input matInput formControlName="dateUpdate" type="text"
            placeholder="{{ 'profiles.dateUpdate' | translate }}">
        </mat-form-field>
      </div>
    </div>
  </fieldset>


      <!-- Footer with Buttons -->
      <div class="footer" *ngIf="rol.canByInput(rol.functIds.delete_profiles)">
        <button class="ct-button ct-button--secondary" (click)="onDelete()">
          {{ 'common.delete' | translate }}
          <mat-icon class="ct-icon red" svgIcon="trash-01"></mat-icon>
        </button>
      </div>
    </form>
  </div>
</ng-container>