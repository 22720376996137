<main id="main" class="main">
    <div class="my-3">
        <span class="header-large">{{ 'maintenace.tabs.sendFile' | translate }}</span>
    </div>

    <section class="row w-100">
        <ng-container  *ngIf="!hasDefaultVendingMachine">
            <article class="col-lg-6 px-3 form-container">
                <app-select-machine-detail [dataSource]="selectedVendingMachines"></app-select-machine-detail>
            </article>
        </ng-container>
        
        <article class="col-lg-6 px-3 form-container  entero-{{hasDefaultVendingMachine}}">        
            <form [formGroup]="fileForm">
                <div class="row mb-2">
                    <app-input-files [multiple]="true" (onChanges)="onFilesSelected($event)"></app-input-files>
                </div>

                <div class="row my-2">
                    <div class="d-flex flex-column gap-2">
                        <ng-container *ngFor="let file of fileList; index as idx">
                            <app-file-display [fileName]="file.name" (delete)="removeFile(idx)"></app-file-display>
                        </ng-container>
                    </div>
                </div>

                
                <!-- [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad -->
                <!--div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <mat-form-field appearance="outline" ngDefaultControl>
                                <mat-label>{{ 'maintenace.input.startTime' | translate }}</mat-label>
                                <input type="datetime-local" matInput formControlName="initTime">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <mat-form-field appearance="outline" ngDefaultControl class="misColores">
                                <mat-label>{{ 'maintenace.input.endTime' | translate }}</mat-label>
                                <input  type="datetime-local" matInput formControlName="endTime">
                            </mat-form-field>
                        </div>
                    </div>
                </div!-->

                <!-- Tipo de Archivo -->
                <div class="form-group">
                    <filterable-select formControlName="typeFile" [options]="sendFileTypes" 
                    [title]="'common.fileType' | translate" [required]="true"></filterable-select> 
                </div>

                <!-- Tipo de directorio -->
                <div class="form-group" *ngIf="fileForm.get('storageType')?.enabled">
                    <filterable-select formControlName="storageType" [options]="storageTypes" 
                    [title]="'common.directoryType' | translate"  [required]="true"></filterable-select> 
                </div>

                <!-- Nombre del paquete -->
                <div class="form-group" *ngIf="fileForm.get('packageName')?.enabled">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'maintenace.input.packageName' | translate }}<span
                                class="obligatorio"></span></mat-label>
                        <input matInput formControlName="packageName" placeholder="{{ 'maintenace.placeholder.packageName' | translate }}" required>
                        <mat-error *ngIf="fileForm.get('packageName')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                        <mat-error *ngIf="fileForm.get('packageName')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
                    </mat-form-field>
                </div>
                
                <!-- Ruta -->
                <div class="form-group" *ngIf="fileForm.get('path')?.enabled">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'maintenace.input.path' | translate }} <span
                                class="obligatorio"></span></mat-label>
                        <input matInput formControlName="path"
                            placeholder="{{ 'maintenace.placeholder.path' | translate }}" required>
                      
                        <mat-error *ngIf="fileForm.get('path')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                        <mat-error *ngIf="fileForm.get('path')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
                    </mat-form-field>
                </div>

                <div class="botonera">
                    <button class="ct-button ct-button--primary" (click)="onFormSubmit()" [disabled]="fileForm.pristine || fileForm.invalid">
                        {{ 'common.send' | translate }}
                    </button>
                </div>
            </form>
        </article>
    </section>
</main>