<div mat-dialog-title class="ct-dialog-header">
    <span class="header-large">{{ 'monitoring.uploadFile' | translate }}</span>
    <button class="ct-button ct-button--tertiary" (click)="onClose()">
        <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
    </button>
</div>

<mat-dialog-content class="ct-dialog-content">
    <div class="terminals-container">
        <app-terminals-selector [(machines)]="terminals" [fixDropZone]="true" [hideButtons]="true"></app-terminals-selector>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="botonera">
    <button class="ct-button ct-button--secondary" (click)="onClose()">
        {{ 'common.cancel' | translate }}
    </button>
    <button class="ct-button ct-button--primary" [disabled]="!terminals.length" (click)="submitChanges()">
        {{ 'common.submit' | translate }}
    </button>
</mat-dialog-actions>