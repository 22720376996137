import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { MonitoringService } from './monitoring.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { TabsComponent } from './tabs/tabs.component';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { Profile } from 'src/app/models/profiles.model';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { AddTerminalComponent } from './add-terminal/add-terminal.component'; 
import { PerfilesService } from '../config/profile/perfiles.service';
import { AuthService } from 'src/app/auth/auth.service';
import { DatesHandlerService } from 'src/app/services/dates-handler.service';

enum BaseColumns {
  serialNumber = 'serialNumber',
  manufacID = 'manufacID',
  modelNumber = 'modelNumber',
  idOperator = 'idOperator',
  idClient = 'idClient',
  idSubClient = 'idSubClient',
  //swRev: 'swRev',
  //commerce: 'commerce',
  
  //[FASE-2] TODO: Habilitar cuando este disponible la funcionalidad 
  // puntoVenta  = 'puntoVenta',
  lastConect = 'lastConect',
  listPerfil = 'listPerfil',
  coverage = 'coverageLevel',
  battery = 'battery',
  status = 'status',
  view = 'view'
};

const FILTER_COLUMNS: { field: string, type: FieldType, options?: FilterOption[] }[] = [
  { field: 'serialNumber', type: 'string' },
  { field: 'manufacID', type: 'string' },
  { field: 'modelNumber', type: 'string' },
  //[FASE-2] TODO: Habilitar cuando este disponible la funcionalidad 
  //{ field: 'puntoVenta', type: 'string' },
  //{ field: 'softwareRevision', type: 'string' },
]
  
@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.css'],
  providers: [{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})

export class MonitoringComponent implements OnInit, AfterViewInit {
  displayedColumns = Object.keys(BaseColumns);
  filterColumns: FilterField[] = FILTER_COLUMNS.map((column) => {
    const fielterField = new FilterField(column.field, `monitoring.${column.field}`, column.type, column.options);
    return fielterField;
  });

  dataSource = new MatTableDataSource<VendingMachineAtento>();
  pageLength = 0;
  inactiveTerminals = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId: string | null = BaseColumns.serialNumber + "";
  direccion: string | null = "ASC";
  showMap = false;
  exportFileName: string | undefined;

  filterList: FilterableValue[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private dialogRef!: MatDialogRef<TabsComponent>;

  constructor(
    private servicios: MonitoringService,
    private profileService:PerfilesService,
    private auth: AuthService,
    private dialog: MatDialog,
    private dateHandler:DatesHandlerService,
    private translate: TranslateService,
    public rol: RolHandlerService,
    public hierarchy: HierarchyHandlerService
  ) { }

  async lanzarLLamada() {
    const request = new UsersConsoleRequest();

    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    request.filterAndPagBean = new FilterAndPagBean(
      this.direccion,
      this.sortId,
      this.filterList,
      startPage,
      endPage,
      pageIndex
    );

    let response = await this.servicios.find(request);
    let pagCount = await this.servicios.countTotal(request);

    if (response && response.data) {
      const sessionUser = this.auth.currentUserValue();
      // Procesar cada elemento para decidir si mostrar los iconos de cobertura
      const dataWithCoverageIcons = response.data.map((item: VendingMachineAtento) => {
        return new VendingMachineAtento({
          ...item,
          listPerfil: Array.isArray(item.listPerfil) ? item.listPerfil.filter((perfil: Profile) => perfil != null) : [],
          minInactivo: sessionUser.minInactivo
        })
      });

      this.dataSource = new MatTableDataSource<VendingMachineAtento>(dataWithCoverageIcons);
      if (pagCount && pagCount.data) {
        let activos = pagCount.data.active ?? 0;
        let total = pagCount.data.total ?? 0;

        this.pageLength = total;
        const inactiveTerminals = total - activos;
        this.inactiveTerminals = inactiveTerminals;
      }
    } else {
      this.dataSource.data = [];
      this.inactiveTerminals = 0;
    }
  }

  async loadProfiles(){
    const request = new FilterAndPagBean(null,null,[],null,null,null);
    const result = await this.profileService.find(request);
    if (result?.status === 0) {
      const options = result.data.map((item:Profile) => ({value:item.id, label:item.name}));
      this.filterColumns.push(new FilterField('idPerfil', 'monitoring.profile','long', options));
    }
  }


  async ngOnInit(): Promise<void> {
    this.displayedColumns = this.hierarchy.filterColumns(Object.keys(BaseColumns));
    this.hierarchy.getFilterFieldsAsync(this.filterColumns);
    this.exportFileName = this.translate.instant('export.fileTerminal');
    this.loadProfiles();
    this.lanzarLLamada();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onFilterApply(appliedFilters: AppliedFilter[]) {
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter) => {
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  pageEvent() {
    this.lanzarLLamada();
  }

  resetPaginatorState() {
    this.paginator.firstPage();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_", ".")
    this.sortId = active;

    if (sortState.direction) {
      this.direccion = sortState.direction.toUpperCase()
    } else {
      this.direccion = null
      this.sortId = null
    }

    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  toggleMap() {
    this.showMap = !this.showMap;
  }
  onView(row: VendingMachineAtento) {
    // Encuentra la fila actualizada después de lanzar la llamada
    const updatedRow = this.dataSource.data.find(item => item.id === row.id);
    this.dialogRef = this.dialog.open(TabsComponent, {
      width: '60%',
      minWidth:'700px',
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel',
      data: { 
        terminalId: updatedRow?.id
      }
    });
    
    
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
    
  }

  openUploadModal(){
    const dialogRef = this.dialog.open(AddTerminalComponent, {
      width: '600px',
      panelClass: 'custom-modalbox',
    });
    
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 1)  this.lanzarLLamada()
    });
  }
  
  getNotExportColumns() {
    const actionsColIdx = (this.displayedColumns.length - 1);
    return [actionsColIdx];
  }


  getLastConnectionToNow(element:VendingMachineAtento){
    if(!element.lastConect) return '';
    const diffToNow = this.dateHandler.getDateToNow(element.lastConect.toString());
    return `${this.translate.instant('monitoring.lastConnection')}: ${diffToNow} `
  }
}
