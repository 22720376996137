import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Users } from 'src/app/models/users.model';
import { UsersProfileService } from './users-profile.service';
import { NotifierService } from 'angular-notifier';
import { LangTypes, LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { RegexpValidators } from 'src/app/util/constants';
import { ComponentsStateService } from 'src/app/services/components-state.service';
import { Operator } from 'src/app/models/operator.model';
import { Client, SubClient } from 'src/app/models/client.model';


class UserProfile {
  name?:string;
  email?:string;
  phone?:string;
  lang?:LangTypes;
  rolName?:string;
  nickName?:string;
  hierarchy?:string;
  
  operator?:Operator;
  client?:Client;
  subClient?:SubClient;
}

@Component({
  selector: 'app-users-profile',
  templateUrl: './users-profile.component.html',
  styleUrls: ['./users-profile.component.css']
})
export class UsersProfileComponent implements OnInit {
  registerForm!: FormGroup;
  registerFormPass!: FormGroup;
  userSession?: Users;
  languages: LangTypes[];
  userProfile:UserProfile = new UserProfile;

  hidePassword = true;
  hideComparePassword = true;
    
  constructor(private authService: AuthService, 
    private notifier: NotifierService, 
    private formBuilder:FormBuilder,
    private usersProfileService: UsersProfileService,
    private translate: TranslateService,
    private languageService:LanguageService,
    private componentsState:ComponentsStateService
  ) { 
      this.userSession = this.authService.currentUserValue();
      this.languages = this.languageService.availableLangs;
  }

  private invalidComparePassword(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const invalidPass = control.value != control.parent?.get('newpass')?.value;
      return invalidPass ? { passNocoincide: true } : null;
    };
  }

  ngOnInit(): void {
    if (this.userSession) {
      this.userProfile = this.setUserProfile(new Users(this.userSession));
    }
    
    this.registerForm = this.formBuilder.group({
      firstname: [this.userProfile.name, Validators.compose([
        Validators.required, 
        Validators.maxLength(45)
      ])],    
      email: [this.userProfile.email, Validators.compose([
        Validators.required,
        Validators.maxLength(45),
        Validators.pattern(RegexpValidators.email)
      ])],     
      phone: [this.userProfile.phone,Validators.compose([
        Validators.pattern(RegexpValidators.phone),
        Validators.maxLength(25)
      ])],
      lang:[this.userProfile.lang, Validators.required]
    });

    this.registerFormPass = this.formBuilder.group({      
      newpass: ["", Validators.compose([Validators.required,
        Validators.minLength(12),
        Validators.maxLength(20),
        Validators.pattern(new RegExp(RegexpValidators.newPassword))])],
      repeatpass: ["", Validators.compose([Validators.required, 
        Validators.minLength(12),
        Validators.maxLength(20),
        Validators.pattern(new RegExp(RegexpValidators.newPassword)),
        this.invalidComparePassword()
      ])]
    });
  }
  
  setUserProfile(user:Users): UserProfile {
    const userProfile:UserProfile = new UserProfile;
    userProfile.name = user.name;
    userProfile.email = user.email;
    userProfile.phone = user.phone;
    userProfile.lang = user.lang as LangTypes;
    userProfile.nickName = user.nickName;
    userProfile.rolName = user.rol?.name;
    userProfile.operator = user.operator;    
    userProfile.client = user.client;
    userProfile.subClient = user.subClient;
    return userProfile;
  }

  async onFormChangePass(){
    if (this.registerFormPass.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
   
    const data = this.applyFormats(this.registerFormPass);
    let newpass=data['newpass']
    let repeatpass=data['repeatpass']

      if(newpass!=repeatpass){
        return;
      }

      if(this.userSession){      
        this.userSession.pass=newpass   
        
        const result = await this.usersProfileService.editProfile(this.userSession)
        if(result && result.status >= 0){
          this.notifier.notify('success', this.translate.instant('success.save'));
        }
      }     

  }

  async onFormSubmit(){
    if (this.registerForm.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }   

    const data = this.applyFormats(this.registerForm);
    let firstname=data['firstname']
    let email=data['email']
    let phone=data['phone']
    let lang=data['lang']


    if(this.userSession){      
      this.userSession.name=firstname
      this.userSession.email=email
      this.userSession.phone=phone
      this.userSession.lang=lang      
      
      let result = await this.usersProfileService.editProfile(this.userSession)
      if(result && result.status >= 0){
        this.notifier.notify('success', this.translate.instant('success.save'));
        this.languageService.setLanguage(this.userSession.lang as LangTypes);
        this.componentsState.updateLabelsState();
        this.userProfile = this.setUserProfile(this.userSession); 
      }   
    }
  }


  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }


  public changeTab(){
    this.registerFormPass.reset();
    this.registerFormPass.markAsUntouched();
    this.registerFormPass.markAsPristine();
  }
  
  toggleInput(event:Event){
    event.preventDefault();
    event.stopPropagation();
    this.hideComparePassword = !this.hideComparePassword;
  }
}
