
<div mat-dialog-title class="ct-dialog-header">
    <ng-template #defaultTitle>
      <span class="header-large">{{ 'editPanel.addNewVersion' | translate }}</span>
    </ng-template>

    <ng-container *ngIf="data.versionId; else defaultTitle">
      <span class="header-large">{{ data.application.name }}</span>    
    </ng-container>

    <button class="ct-button ct-button--tertiary" (click)="onClose()">
      <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
    </button>
</div>

<form [formGroup]="versionForm">
  <mat-dialog-content class="ct-dialog-content">
      <!-- Zona de carga de archivos -->
      <div class="mb-3">
        <ng-container *ngIf="!fileNameInput?.value; else hasFile">
          <app-input-files class="w-100" [invalid]="fileUploadError && !fileInput?.value" (onChanges)="onFileSelected($event)"></app-input-files>
        </ng-container>
      
        <ng-template #hasFile>
          <app-file-display [fileName]="fileNameInput?.value" [canDelete]="!data.versionId" (delete)="resetFileSelection()"></app-file-display>
        </ng-template>
      </div>
      
      <!-- Input para la nueva versión -->
      <div class="form-group">
          <mat-form-field appearance="outline">
              <mat-label>{{ 'editPanel.version' | translate }} <span class="obligatorio"></span></mat-label>
              <input matInput formControlName="version" placeholder="{{ 'editPanel.version' | translate }}" required/>
              <mat-error *ngIf="versionForm.get('version')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
              <mat-error *ngIf="versionForm.get('version')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
          </mat-form-field>
      </div>

      
      <div class="row form-group-inline">
        <div class="col-12 col-md-6 form-group">
          <filterable-select #manuFacSelect formControlName="manufacID" [title]="'common.factory' | translate" 
              [options]="factories.list" valueField="name" displayField="name"  [required]="true"
          ></filterable-select>
        </div>
        <div class="col-12 col-md-6 form-group">
          <filterable-select formControlName="modelNumber" [title]="'common.terminalModel' | translate" 
              [options]="terminalModels" valueField="name" displayField="name"
          ></filterable-select> 
        </div>
    </div>
      
      <div class="form-group">
          <mat-form-field appearance="outline">
              <mat-label>{{ 'editPanel.description' | translate }} <span class="obligatorio"></span></mat-label>
              <textarea matInput formControlName="description"  placeholder="{{ 'editPanel.description' | translate }}" maxlength="200" rows="3" cdkTextareaAutosize
              cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5">
              </textarea>
              <mat-error *ngIf="versionForm.get('description')?.hasError('required')"> {{ 'error.required.field' | translate }} </mat-error>
              <mat-hint align="end">{{ versionForm.get('description')?.value?.length || 0 }} / 200</mat-hint>
          </mat-form-field>
      </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="botonera">
    <button class="ct-button ct-button--secondary" (click)="onClose()">{{ 'common.cancel' | translate }}</button>
    <button class="ct-button ct-button--primary" (click)="onSaveVersion()">{{ 'common.submit' | translate }}</button>
  </mat-dialog-actions>
</form>