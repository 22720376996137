<div  *ngFor="let packageName of getKeys(groupedDataByPackageAndType)" class="mat-elevation-z2 zonaStadisticas" [id]="'_' + packageName">
    <div>
        <span class="header-medium">
            {{ packageName }}
        </span>
    </div>

    <div *ngFor="let type of getKeys(groupedDataByPackageAndType[packageName])">
            <div class="grafica">
            <span class="header-medium">
                {{ type }}
                <button (click)="redimensionar('_' + packageName)" class="botonExpander">
                    <i class="bi bi-arrows-angle-expand expand"></i>
                    <i class="bi bi-arrows-angle-contract contract" style="display: none;"></i>
                </button>
            </span>
            <apx-chart
            [series]="groupedDataByPackageAndType[packageName][type].chartOptions.series || []"
            [chart]="groupedDataByPackageAndType[packageName][type].chartOptions.chart || { type: 'line' }"
            [xaxis]="groupedDataByPackageAndType[packageName][type].chartOptions.xaxis || { categories: [] }"
            [yaxis]="groupedDataByPackageAndType[packageName][type].chartOptions.yaxis || {}"
            [dataLabels]="groupedDataByPackageAndType[packageName][type].chartOptions.dataLabels || {}"
            [stroke]="groupedDataByPackageAndType[packageName][type].chartOptions.stroke || {}"
            [title]="groupedDataByPackageAndType[packageName][type].chartOptions.title || { text: '' }"
            [legend]="groupedDataByPackageAndType[packageName][type].chartOptions.legend || {} "
            [plotOptions]="groupedDataByPackageAndType[packageName][type].chartOptions.plotOptions || {}"
            [fill]="groupedDataByPackageAndType[packageName][type].chartOptions.fill || {}"
          >
          </apx-chart>
    </div>
    </div>


</div>