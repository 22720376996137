<div mat-dialog-title class="ct-dialog-header">
    <span class="header-large">{{ 'profiles.aplications' | translate }}</span>

    <button class="ct-button ct-button--tertiary" (click)="onClose()">
        <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
    </button>
</div>

<mat-dialog-content class="ct-dialog-content" *ngIf="profile">
    <!-- Componente para selector de aplicaciones -->
    <app-apps-selector [hierarchy]="hierarchy" [factory]="profile.manufacID" 
        [(list)]="resultListApp" [referenceList]="profile.listApp">
    </app-apps-selector>
</mat-dialog-content>

<mat-dialog-actions align="end" class="botonera">
    <button class="ct-button ct-button--secondary" (click)="onClose()">
        {{ 'common.cancel' | translate }}
    </button>
    <button class="ct-button ct-button--primary" (click)="submitChanges()" [disabled]="!resultListApp.length">
        {{ 'common.submit' | translate }}
    </button>
</mat-dialog-actions>