<div mat-dialog-title class="ct-dialog-header">
  <div class="header-large">{{ 'roles.add' | translate }}</div>
  
  <button class="ct-button ct-button--tertiary" (click)="onClose()">
    <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
  </button>
</div>

<mat-dialog-content class="ct-dialog-content ct-dialog-content__extended p-0">
<section class="section">
  <form [formGroup]="registerFormStep1">
    <mat-stepper [linear]="true">

      <!-- Paso 1: Nombre y descripción -->
      <mat-step>
        <ng-template matStepLabel>{{ 'roles.details' | translate }}</ng-template>
        <!-- Formulario para paso 1 (nombre, descripción) -->
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'roles.name' | translate }}</mat-label>
              <!-- Campo de texto para el nombre -->
              <input matInput formControlName="nombre" required>
              <mat-error *ngIf="registerFormStep1.get('nombre')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
              <mat-error *ngIf="registerFormStep1.get('nombre')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'roles.description' | translate }}</mat-label>
              <input matInput formControlName="description" required>
              <mat-error *ngIf="registerFormStep1.get('description')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
              <mat-error *ngIf="registerFormStep1.get('description')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div *ngIf="handleRol.isAdmin()">
              <div class="form-group">
                <filterable-select formControlName="idOperator" [options]="operatorsList" 
                  [title]="'users.operator' | translate" valueField="id" displayField="name"></filterable-select> 
              </div>
          </div>
      </div>

        <!-- Botón "Next" para pasar al siguiente paso -->
        <div class="button-group">
          <button class="ct-button ct-button--secondary" (click)="onClose()">{{ 'common.cancel' | translate }}</button>
          <button class="ct-button ct-button--primary" matStepperNext [disabled]="registerFormStep1.invalid">{{ 'common.next' | translate }}</button>
        </div>
      </mat-step>

      <!-- Paso 2: Permisos y acciones -->
      <mat-step>
        <ng-template matStepLabel>{{ 'roles.step2' | translate }}</ng-template>
        <form [formGroup]="registerFormStep2">
          <!-- Monitorización -->
          <fieldset class="edit-container bordered">
            <div class="permission-group-container">
              <label class="title-small">{{ 'sidebar.monitoring' | translate }}</label>
              <div class="permission-grid">
                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.terminals' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_terminals"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="edit_terminals"></mat-checkbox>
                      <i class="bi bi-pencil"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="delete_terminals"></mat-checkbox>
                      <i class="bi bi-trash"></i>
                    </div>
                  </div>
                </div>
                
                <!-- [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad -->
                <!--div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.view_sells' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_sells"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                  </div>
                </div!-->
              </div>
            </div>
          </fieldset>

          <!-- Estadísticas -->
          <fieldset class="edit-container bordered">
            <div class="permission-group-container">
              <label class="title-small">{{ 'sidebar.statistic' | translate }}</label>
              <div class="permission-grid">
                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.functionality' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_functionality"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                  </div>
                </div>
                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.status' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_status"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                  </div>
                </div>
                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.view_sensors' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_sensors"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <!-- Soporte -->
          <fieldset class="edit-container bordered">
            <div class="permission-group-container">
              <label class="title-small">{{ 'sidebar.support' | translate }}</label>
              <div class="permission-grid">
                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.alerts' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_alerts"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                  </div>
                </div>
                
                <!-- [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad -->
                <!--div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.incidences' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_incidences"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="edit_incidences"></mat-checkbox>
                      <i class="bi bi-pencil"></i>
                    </div>
                  </div>
                </div!-->
              </div>
            </div>
          </fieldset>

          <!-- Configuración -->
          <fieldset class="edit-container bordered">
            <div class="permission-group-container">
              <label class="title-small">{{ 'sidebar.configuration' | translate }}</label>
              <div class="permission-grid">
                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.applications' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_applications"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="edit_applications"></mat-checkbox>
                      <i class="bi bi-pencil"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="delete_applications"></mat-checkbox>
                      <i class="bi bi-trash"></i>
                    </div>
                  </div>
                </div>

                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.parameters' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_parameters"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="edit_parameters"></mat-checkbox>
                      <i class="bi bi-pencil"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="delete_parameters"></mat-checkbox>
                      <i class="bi bi-trash"></i>
                    </div>
                  </div>
                </div>

                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.profiles' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_profiles"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="edit_profiles"></mat-checkbox>
                      <i class="bi bi-pencil"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="delete_profiles"></mat-checkbox>
                      <i class="bi bi-trash"></i>
                    </div>
                  </div>
                </div>

                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.tags' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_tags"></mat-checkbox>
                      <i class="bi bi-eye"></i> <!-- Icono de ver -->
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="edit_tags"></mat-checkbox>
                      <i class="bi bi-pencil"></i> <!-- Icono de editar -->
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="delete_tags"></mat-checkbox>
                      <i class="bi bi-trash"></i> <!-- Icono de eliminar -->
                    </div>
                  </div>
                </div>

                <!-- [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad -->
                <!--div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.custom_alerts' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_custom_alerts"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="edit_custom_alerts"></mat-checkbox>
                      <i class="bi bi-pencil"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="delete_custom_alerts"></mat-checkbox>
                      <i class="bi bi-trash"></i>
                    </div>
                  </div>
                </div!-->
              </div>
            </div>
          </fieldset>

          <!-- Mantenimiento -->
          <fieldset class="edit-container bordered">
            <div class="permission-group-container">
              <label class="title-small">{{ 'sidebar.maintenance' | translate }}</label>
              <div class="permission-grid">
                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.view_file' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_file"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="delete_file"></mat-checkbox>
                      <i class="bi bi-trash"></i> <!-- Icono de eliminar -->
                    </div>
                  </div>
                </div>

                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.send_file' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="send_file"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                  </div>
                </div>

                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.get_file' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="get_file"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                  </div>
                </div>

                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.commands' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="commands"></mat-checkbox>
                      <i class="bi bi-eye"></i> <!-- Icono de ver -->
                    </div>
                  </div>
                </div>

                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.pending_tasks' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="pending_tasks"></mat-checkbox>
                      <i class="bi bi-eye"></i> <!-- Icono de ver -->
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="edit_pending_tasks"></mat-checkbox>
                      <i class="bi bi-pencil"></i> <!-- Icono de editar -->
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="delete_pending_tasks"></mat-checkbox>
                      <i class="bi bi-trash"></i> <!-- Icono de eliminar -->
                    </div>
                  </div>
                </div>

                <div class="permission-group horizontal">
                  <label>{{ 'roles.permissions.history' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="history"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <!-- Sección de Administrador -->
          <fieldset class="edit-container bordered">
            <div class="permission-group-container">
              <label class="title-small">{{ 'sidebar.admin' | translate }}</label>
              <div class="permission-grid">
                <div class="permission-group">
                  <label>{{ 'roles.permissions.users' | translate }}</label>
                  <div class="permission-icons-row">
                    <div class="permission-item">
                      <mat-checkbox formControlName="view_users"></mat-checkbox>
                      <i class="bi bi-eye"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="edit_users"></mat-checkbox>
                      <i class="bi bi-pencil"></i>
                    </div>
                    <div class="permission-item">
                      <mat-checkbox formControlName="delete_users"></mat-checkbox>
                      <i class="bi bi-trash"></i>
                    </div>
                  </div>
                </div>
                <!-- Sección de Clientes -->
                <div class="permission-group-container">
                  <div class="permission-group horizontal">
                    <label>{{ 'roles.permissions.clients' | translate }}</label>
                    <div class="permission-icons-row">
                      <div class="permission-item">
                        <mat-checkbox formControlName="view_clients"></mat-checkbox>
                        <i class="bi bi-eye"></i>
                      </div>
                      <div class="permission-item">
                        <mat-checkbox formControlName="edit_clients"></mat-checkbox>
                        <i class="bi bi-pencil"></i>
                      </div>
                      <div class="permission-item">
                        <mat-checkbox formControlName="delete_clients"></mat-checkbox>
                        <i class="bi bi-trash"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Sección de Subclientes -->
                <div class="permission-group-container">
                  <div class="permission-group horizontal">
                    <label>{{ 'roles.permissions.subclients' | translate }}</label>
                    <div class="permission-icons-row">
                      <div class="permission-item">
                        <mat-checkbox formControlName="view_subclients"></mat-checkbox>
                        <i class="bi bi-eye"></i>
                      </div>
                      <div class="permission-item">
                        <mat-checkbox formControlName="edit_subclients"></mat-checkbox>
                        <i class="bi bi-pencil"></i>
                      </div>
                      <div class="permission-item">
                        <mat-checkbox formControlName="delete_subclients"></mat-checkbox>
                        <i class="bi bi-trash"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Sección de Roles -->
                <div class="permission-group-container">
                  <div class="permission-group horizontal">
                    <label>{{ 'roles.permissions.rols' | translate }}</label>
                    <div class="permission-icons-row">
                      <div class="permission-item">
                        <mat-checkbox formControlName="view_rols"></mat-checkbox>
                        <i class="bi bi-eye"></i>
                      </div>
                      <div class="permission-item">
                        <mat-checkbox formControlName="edit_rols"></mat-checkbox>
                        <i class="bi bi-pencil"></i>
                      </div>
                      <div class="permission-item">
                        <mat-checkbox formControlName="delete_rols"></mat-checkbox>
                        <i class="bi bi-trash"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <!-- Botones de acción para este paso -->
          <div class="button-group">
            <button class="ct-button ct-button--secondary" matStepperPrevious>{{ 'common.back' | translate }}</button>
            <button class="ct-button ct-button--primary" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
          </div>
        </form>
      </mat-step>
    </mat-stepper>
  </form>
</section>
</mat-dialog-content>