import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableModule, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginatorIntl, MatLegacyPaginatorModule, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { Rol } from 'src/app/models/rol.model';
import { environment } from 'src/environments/environment';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { RolService } from './rol.service'; 
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TableEmptyStateComponent } from 'src/app/components/table-empty-state/table-empty-state.component';
import { FormNewRolComponent } from './form-new-rol/form-new-rol.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FormEditRolComponent } from './form-edit-rol/form-edit-rol.component';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { HierarchyLevelNamePipe } from '../../comun/pipes/hierarcy-level-name.pipe';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';

enum Columns {
  name = 'name',
  description = 'description',
  idOperator='idOperator',
  view = 'view',
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatLegacyTableModule,
    MatLegacyPaginatorModule,
    MatSortModule,
    MatTableExporterModule,
    TranslateModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyTooltipModule,
    TableEmptyStateComponent,
    HierarchyLevelNamePipe
  ],
  selector: 'app-rol',
  templateUrl: './rol.component.html',
  styleUrls: ['./rol.component.css'],
  providers: [{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class RolComponent implements OnInit {
  displayedColumns = Object.keys(Columns);
  dataSource = new MatTableDataSource<Rol>();
  exportFileName: string | undefined;
  private dialogRef!: MatDialogRef<FormNewRolComponent>;
  private dialogRefEdit!: MatDialogRef<FormEditRolComponent>;

  roles = []; // Array de roles cargados en el componente de tabla

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  direccion: string | null = "ASC";
  pageSizeOptions = environment.paginacion;
  sortId: string | null = Columns.name;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  filterList: FilterableValue[] = [];

  constructor(
    public service: RolService, // Servicio para manejar los roles
    public dialog: MatDialog,
    public translate: TranslateService,
    public rol:RolHandlerService,
    public hierarchy:HierarchyHandlerService
  ) {}

  async lanzarLLamada() {
    const paginatorState = this.getPaginatorState();
    const filterAndPagBean = new FilterAndPagBean(
        this.direccion,
        this.sortId,
        this.filterList,
        paginatorState.startPage,
        paginatorState.endPage,
        paginatorState.pageIndex
    );

    const value = await this.service.find(filterAndPagBean);
    console.log('Datos recibidos en lanzarLLamada:', value); // Para inspeccionar los datos

    const pagCount = await this.service.countTotal(filterAndPagBean);

    if (value && value.data) {
        this.dataSource.data = value.data; // Asegúrate de que esto esté asignado correctamente
        this.pageLength = pagCount ? pagCount.data?.total ?? 0 : 0; 
    } else {
        this.dataSource.data = []; 
        this.pageLength = 0; 
    }

}


  private getPaginatorState() {
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    return { startPage, endPage, pageIndex };
  }

  async ngOnInit(): Promise<void> {
    this.exportFileName = this.translate.instant('export.fileRole');
    const canSeeOperator = this.hierarchy.canByLevel(this.hierarchy.level.LEVEL_1);

    // Definimos las columnas de manera condicional
    this.displayedColumns = [
      Columns.name,
      Columns.description,
      ...(canSeeOperator ? [ Columns.idOperator ] : []),
      Columns.view
    ];

    // Cargar datos al iniciar
    this.lanzarLLamada();
  }

   onNew() {
    this.dialogRef = this.dialog.open(FormNewRolComponent, {
      width: '800px', panelClass: 'custom-modalbox-big',
      data: {/*vacio al ser nuevo*/ }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

   onEdit(row: Rol) {
    this.dialogRefEdit = this.dialog.open(FormEditRolComponent, {
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel' ,
      width: '700px',
      data: { 
        rol: row,       
        rolId: row.id 
      }
    });
  
    this.dialogRefEdit.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM received from dialog window');
        this.lanzarLLamada();  
      } else if (result === 0) {
        console.log('CANCEL received from dialog window');
      }
    });
  }
  
  refrescar() {
    this.lanzarLLamada();
  }

  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_", ".");
    this.sortId = active;
    if (sortState.direction) {
      this.direccion = sortState.direction.toUpperCase();
    } else {
      this.direccion = null;
      this.sortId = null;
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
}

