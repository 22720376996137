import { Inject, Injectable,Injector } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';
import { RestService } from '../services/merchant/Rest.service';
import { authSpaConfig } from './auth-spa.config';
import { AUTH_ENTORNO } from 'src/environments/environment';
import { AUTHENTICACION } from '../util/constants';
import { Users } from '../models/users.model';

@Injectable({providedIn: 'root'})
export class AuthService {
    
  //userProfile: object | undefined;
  
  private currentUserSubject!: BehaviorSubject<Users>;

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
  
  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
  
  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));
  public setAuthenticationState(isAuthenticated: boolean): void {
    this.isAuthenticatedSubject$.next(isAuthenticated);
  }
  
  private navigateToLoginPage() {
    console.debug('####################### redirect to login', );
    this.logout();
    
    this.router.navigateByUrl('/welcome');// /should-login
  }
  
  public configure() {
    this.oauthService.configure(authSpaConfig);
    this.oauthService.setupAutomaticSilentRefresh();
  }

  private get restService() {
    return this.injector.get(RestService);
  }

  constructor(
    private oauthService: OAuthService,
    @Inject(Injector) private readonly injector: Injector,
    private router: Router) {
      this.currentUserSubject = new BehaviorSubject<Users>(new Users);

      this.configure();

      // Useful for debugging:
      this.oauthService.events.subscribe(event => {
        if (event instanceof OAuthErrorEvent) {
          console.error('OAuthErrorEvent Object:', event);
        } else {
          console.warn('OAuthEvent Object:', event);
        }
      });



  
      // This is tricky, as it might cause race conditions (where access_token is set in another
      // tab before everything is said and done there.
      window.addEventListener('storage', (event) => {
        this.oauthService.stopAutomaticRefresh();
        
        // The `key` is `null` if the event was caused by `.clear()`
        if (event.key !== 'access_token' && event.key !== null) {
          return;
        }
  
        console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
  
        if (!this.oauthService.hasValidAccessToken()) {
          this.navigateToLoginPage();
        }
       
      });
  
      this.oauthService.events
        .subscribe(_ => {
          this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
        });
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
  
      this.oauthService.events
        .pipe(filter(e => ['token_received'].includes(e.type)))
        .subscribe(e => this.oauthService.loadUserProfile());

      this.oauthService.events
        .pipe(filter(e => ['token_timeout'].includes(e.type)))
        .subscribe(e => {
          console.debug('token about to expire');
          alert('token about to expire')
        });
        
      this.oauthService.events
        .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
        .subscribe(e => {alert('session timeout');this.navigateToLoginPage()});
  
      console.log("performing silent refresh");
      this.oauthService.setupAutomaticSilentRefresh();
      console.log("silent refresh done!!");

    }


public guardarToken(token:string){
  this.oauthService.stopAutomaticRefresh();
  sessionStorage.setItem('access_token', token)
  //sessionStorage.setItem('refresh_token', token)
  sessionStorage.setItem('id_token', token)
  this.oauthService.setStorage(sessionStorage) 
}

public currentUserValue(): Users {
  return this.currentUserSubject.value;
}

public setCurrentUserValue(user: Users) {
  this.currentUserSubject.next(user);
}




  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      console.log('Encountered hash fragment, plotting as table...');
      console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return this.oauthService.loadDiscoveryDocument()

      // For demo purposes, we pretend the previous call was very slow
      //.then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1500)))

      // 1. HASH LOGIN:
      // Try to log in via hash fragment after redirect back
      // from IdServer from initImplicitFlow:
      .then(() => this.oauthService.tryLogin())
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve().then((t)=>{
            console.log(t);
            let expirationAccessToken = this.oauthService.getAccessTokenExpiration();
            let timeout = expirationAccessToken - new Date().valueOf();
            console.log('Expiration token: ' + expirationAccessToken);
            console.log('Timeout: ' + timeout);
            this.expirationCounter(timeout);
          }); 
        }
        return Promise.resolve();
      })

      .then(() => {
        this.isDoneLoadingSubject$.next(true);

        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
          this.router.navigateByUrl(stateUrl);
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  
  public login(targetUrl?: string) {

    if(AUTH_ENTORNO==AUTHENTICACION.TMS_SERVER){
      // para hacer nosotros el LOGIN y veirifacrlo contra el TMS
      this.router.navigate(["/pages-login"]);
    }else{
      //El login lo hace el AUTH y nos redirigue el
      this.oauthService.initLoginFlow(targetUrl || this.router.url);//|| this.router.url
    }

  }

  

  tokenSubscription = new Subscription()

  expirationCounter(timeout: number) {
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null)
    .pipe(delay(timeout))
    .subscribe((expired) => {
      console.debug('logout on token timeout');
      console.log('EXPIRED!!');

      this.logoutAndClear();
      this.router.navigate(["/welcome"]);
    });
  }

  public clearStorage() { 
    localStorage.clear(); 
  }
  
  public logout() { 
    this.tokenSubscription.unsubscribe();
    this.oauthService.logOut();
    this.currentUserSubject.next(new Users);

  }
  public logoutAndClear() {
    this.setAuthenticationState(false);
    this.tokenSubscription.unsubscribe();
    this.clearStorage();
    sessionStorage.clear();
  }

  async logoutAuthService(_body ?: any){
    let value = await this.restService.commonRestCallAuth(_body, 'logout',true,true)   
      .catch((err: any) => {
        console.error(err);
        return null
      })

    return value;   
  }
  
  public refresh() { this.oauthService.silentRefresh(); }
  public hasValidToken() { return this.oauthService.hasValidAccessToken(); }
  

  

  public get getUserClaims() : object {    
    return this.oauthService.getIdentityClaims();
  }
  
  public get domain() {
    let name="";
    let userSession: Users
    userSession=this.currentUserValue();
    if(!(userSession?.name)){
      name=this.getUserClaims!['domain' as keyof typeof this.getUserClaims] !== undefined ? this.getUserClaims['domain' as keyof typeof this.getUserClaims]:'unknown';
    }
    return name
  }

  public get username() {
    let name="";

    let userSession: Users
    userSession=this.currentUserValue();

    if(userSession?.name){
      name=userSession.name;
    }else{
      name=this.getUserClaims['preferred_username' as keyof typeof this.getUserClaims] !== undefined ? this.getUserClaims['preferred_username' as keyof typeof this.getUserClaims]:'unknown';
    }
    return name;
  }

  public get fullName() {    
    let name="";

    let userSession: Users
    userSession=this.currentUserValue();
    
    if(userSession?.name){
      name=userSession.name;
    }else{
      const o = this.getUserClaims;
      name = o['name' as keyof typeof o ] !== undefined ? o['name' as keyof typeof o]:'unknown';
    }
    return name;
  }

  public get timeZone(): string {
    const userSession = this.currentUserValue();
    return userSession.entorno?.timeZone ?? "Europe/Madrid";
  }

  public get language(): string {
    const userSession = this.currentUserValue();
    return userSession.lang ?? "es";
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() { return this.oauthService.getAccessToken(); }
  public get refreshToken() { return this.oauthService.getRefreshToken(); }
  public get identityClaims() { return this.oauthService.getIdentityClaims(); }
  public get idToken() { return this.oauthService.getIdToken(); }
  public get logoutUrl() { return this.oauthService.logoutUrl; }

  public hasValidIdToken() : boolean {
    return this.oauthService.hasValidIdToken();
  }
  public hasValidAccessToken() : boolean {
    return this.oauthService.hasValidAccessToken();
  }

  trimTrailingEquals(input: string) {
    let end = input.length;
    while (end > 0 && input[end - 1] === '=') {
        end--;
    }
    return input.substring(0, end);
}

  private chars: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  private atob(input: string) {
  if (typeof input !== 'string' || input.trim() === '') {
    throw new Error("Input must be a non-empty string.");
  }
  const str = this.trimTrailingEquals(input);  
  if (str.length % 4 == 1) {
        throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    let output = '';
    let bs = 0;
    let bc = 0;
    for (let idx = 0; idx < str.length; idx++) {
        let buffer = this.chars.indexOf(str.charAt(idx));
        if (buffer === -1) break;

        bs = (bs * 64) + buffer;
        bc++;
        
        if (bc % 4 === 0) {
            output += String.fromCharCode((bs >> 16) & 255, (bs >> 8) & 255, bs & 255);
            bs = 0;
        }
    }
    if (bc % 4) {
        bs <<= 6 * (4 - bc % 4);
        if (bc % 4 === 3) {
          output += String.fromCharCode((bs >> 16) & 255, (bs >> 8) & 255);
      } else if (bc % 4 === 2) {
          output += String.fromCharCode((bs >> 16) & 255);
      } else {
          output += '';
      }
      
    }

    return output;
}

  parseJwt(token: string) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(this.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  public getUserAccessToken() : any {
    let tk: string = this.oauthService.getAccessToken();
    return this.parseJwt(tk);
  }

  public getAccessToken() : any {
    return this.oauthService.getAccessToken();
  }




}
