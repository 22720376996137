import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import { FilterableValue, FilterAndPagBean, OPERATION_FILTER } from 'src/app/models/FilterAndPagBean';
import { Tag } from 'src/app/pages/views/config/tags/tag.model';
import { TagsService } from 'src/app/pages/views/config/tags/tags.service';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';

@Component({
  selector: 'app-tags-selector',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacySelectModule, TranslateModule],
  templateUrl: './tags-selector.component.html',
  styleUrls: ['./tags-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TagsSelectorComponent), multi: true
    }
  ]
})
export class TagsSelectorComponent implements ControlValueAccessor, OnInit {
  @Input() hierarchy:(AbstractControl|null) = null;
  tagsInput = new FormControl<Tag[]>([]);

  disabled = false;
  tagsList: Tag[] = [];
  constructor(private service:TagsService, private hirarchyHandler:HierarchyHandlerService){
    this.tagsInput.valueChanges.subscribe((tags)=>{ if(!this.disabled) this.onChange(tags) });
  }

  async ngOnInit(): Promise<void> {
    await this.loadTagList();
    this.hierarchy?.valueChanges.subscribe(async(value)=>{
      let filters: FilterableValue[] = [];
      this.tagsList = [];
      if(value.idOperator) {
        const inputToFilter = { idOperator: value.idOperator }; 
        filters = [...this.hirarchyHandler.getNullableHierarchyFilters(inputToFilter)];
        await this.loadTagList(filters);
      };

      if(value.idClient) {
        const inputToFilter = { idOperator: value.idOperator, idClient:value.idClient }; 
        filters = [...this.hirarchyHandler.getNullableHierarchyFilters(inputToFilter)];
        await this.loadTagList(filters);
      };

      if(value.idSubClient) {
        filters = [...this.hirarchyHandler.getNullableHierarchyFilters(value)];
        await this.loadTagList(filters);
      };
    });  
  }

  private async loadTagList(filters:FilterableValue[] = []){
    const _request = new FilterAndPagBean(null,null,filters,null,null,null);
    const response = await this.service.find(_request)
    if(response && response.data) this.tagsList = [...this.tagsList, ...response.data];
  }

  // Function to call when the value changes.
  onChange = (tags:(Tag[] | null)) => {};

  // Function to call when the input is touched.
  onTouched = () => {};

  
  writeValue(value: Tag[]): void {
    this.tagsInput.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;    
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if(isDisabled){
      this.tagsInput.disable()
    }else{
      this.tagsInput.enable()
    }
  } 
    

  public compareTagById(a:Tag, b:Tag){
    return a?.id === b?.id;
  }
}
