import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FilterAndPagBean, FilterableValue, OPERATION_FILTER } from 'src/app/models/FilterAndPagBean';
import { Pending, PENDING_TASKS_TYPES } from 'src/app/models/pending.model';
import { environment } from 'src/environments/environment';
import { PendingService } from './pending.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonService } from 'src/app/services/common.service';
import { NumberInput } from '@angular/cdk/coercion';
import { NotifierService } from 'angular-notifier';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { TabsComponent } from '../../views/monitoring/tabs/tabs.component';
import { MonitoringService } from '../../views/monitoring/monitoring.service';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { PENDING_ERRORS, PENDING_STATUS } from 'src/app/util/constants';
import { EventsService } from 'src/app/services/events.service';


enum BaseColumns {
  vm_serialNumber = 'vm.serialNumber',
  vm_manufacID = 'vm.manufacID',
  vm_modelNumber = 'vm.modelNumber',
  idOperator = 'idOperator',
  typeSend = 'typeSend',
  sendJson = 'sendJson',
  dateCreate = 'dateCreate',
  //retries = 'retries',
  codError = 'codError',
  //setMachine = 'setMachine',
  //dateIni = 'dateIni',
  numBlocks = 'numBlocks',
  status = 'status',
  view = 'view',
}

const { idOperator, ...Columns } = BaseColumns;

@Component({
  selector: 'app-pending-tasks',
  templateUrl: './pending-tasks.component.html',
  styleUrls: ['./pending-tasks.component.css'],
  providers: [{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class PendingTasksComponent implements OnInit, AfterViewInit {

  displayedColumns = Object.keys(Columns);

  dataSource = new MatTableDataSource<Pending>();
  canOpenModal = true;
  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId: string | null = Columns.dateCreate + "";
  direccion: string | null = "DESC";
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  exportFileName: string | undefined;

  TYPE_OPTIONS: FilterOption[] = Object.values(PENDING_TASKS_TYPES).map((type) => ({
    value: type, label: this.translate.instant('pendingTasksTypes.' + type)
  }))

  ERROR_OPTIONS: FilterOption[] = Object.keys(PENDING_ERRORS).filter((type)=> (type != 'default')).map((type) => ({
    value: type, label: this.translate.instant('pendingErrors.' + PENDING_ERRORS[type])
  }))

  STATUS_OPTIONS: FilterOption[] = PENDING_STATUS.map(item => ({value:item, label: this.translate.instant(`pendingTasks.${item}`)}));

  FILTER_COLUMNS: { field: string, type: FieldType, options?: FilterOption[] }[] = [
    { field: 'typeSend', type: 'string', options: this.TYPE_OPTIONS },
    { field: 'dateCreate', type: 'date' },
    //{ field: 'retries', type: 'number' },
    { field: 'codError', type:'number', options: this.ERROR_OPTIONS },
  ]

  filterColumns: FilterField[] = this.FILTER_COLUMNS.map((column) => {
    const fielterField = new FilterField(column.field, `pendingTasks.${column.field}`, column.type, column.options);
    return fielterField;
  })
  
  filterList: FilterableValue[] = [];
  
  inactiveTerminals = 0;
  defaultRetriesToBlock = 4;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { terminalId: number },
    private notifier: NotifierService,
    private servicios: PendingService,
    private translate: TranslateService,
    private monitoringService: MonitoringService,
    private eventsHandler:EventsService,
    public dialog: MatDialog,
    public commonService: CommonService,
    public rol: RolHandlerService,
  ) {
    this.canOpenModal = !data || Object.keys(data).length === 0;
  }

  async lanzarLLamada() {
    let idVM = this.data.terminalId;

    const paginatorState = this.getPaginatorState();
    const request = new FilterAndPagBean(
      this.direccion,
      this.sortId,
      this.filterList,
      paginatorState.startPage,
      paginatorState.endPage,
      paginatorState.pageIndex
    );

    let response = await this.servicios.find(idVM, request);
    let pagCount = await this.servicios.countTotal(idVM, request);
    if (response) {
      this.dataSource = new MatTableDataSource<Pending>(response.data.map((item:Pending)=> new Pending(item)));
      if (pagCount) this.pageLength = pagCount.data?.total ?? 0;
    }
  }

  private getPaginatorState() {
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    return { startPage, endPage, pageIndex };
  }

  private async loadMachines(){
    const request = new UsersConsoleRequest;
    request.filterAndPagBean = new FilterAndPagBean(null,null,[],null,null,null);
    const response = await this.monitoringService.find(request);
    if(response && response.data){
      const machinesOptions = response.data.map((machine:VendingMachineAtento)=> new FilterOption(machine.serialNumber as string, machine.id as number)); 
      this.filterColumns.unshift(new FilterField('idVM','common.terminalSN', 'long', machinesOptions));
    }
  }

  ngOnInit(): void {
    if (this.rol.isAdmin() && !this.data.terminalId) {
      this.displayedColumns = Object.keys(BaseColumns);
    }

    if (this.data.terminalId) {
      this.displayedColumns = this.displayedColumns.filter(col => (col !== BaseColumns.vm_serialNumber));
    }

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.exportFileName = this.translate.instant('export.filePendingTask');
    this.loadMachines();
    this.lanzarLLamada();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_", ".")
    this.sortId = active;
    if (sortState.direction) {
      this.direccion = sortState.direction.toUpperCase()
    } else {
      this.direccion = null
      this.sortId = null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  onFilterApply(appliedFilters: AppliedFilter[]) {
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter) => {
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
    if(this.data.terminalId) {
      this.eventsHandler.updateTerminalDetail(this.data.terminalId);
    }
  }

  async onDelete(row: Pending) {
    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.task; 
    dialogData.action = ElementAction.delete;
    dialogData.class = DialogClass.error;
    dialogData.icon = 'trash-01';

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        let result = await this.servicios.delete(row.id)
        if (result!.status >= 0) {
          this.notifier.notify('success', this.translate.instant('success.taskDelete'));
          this.lanzarLLamada();
        }
      }
    });
  }

  async onReset(row: Pending) {
    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.task; 
    dialogData.action = ElementAction.resetTask;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        let result = await this.servicios.resetPendingMachine(row.id)

        if (result!.status >= 0) {
          this.notifier.notify('success', this.translate.instant('success.pendingResetSuccess'));
          this.lanzarLLamada();
        }

      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

  onView(row: Pending): void {
    if(!row.idVM) return;
    this.dialog.open(TabsComponent, {
      width: '60%',
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel',
      data: { terminalId: row.idVM }
    });
  }

  getNotExportColumns() {
    const actionsColIdx = (this.displayedColumns.length - 1);
    return [actionsColIdx];
  }


  getTooltip(element:Pending):string{
    if(element.isCommand){
      return `${this.translate.instant('pendingTasks.command')}: ${element.commandType}\n`+
      `${this.translate.instant('pendingTasks.packageName')}: ${element.packegeName}`;
    }

    return `${this.translate.instant('pendingTasks.fileName')}: ${element.fileName}\n`+
      `${this.translate.instant('pendingTasks.packageName')}: ${element.packegeName}`;
  }

}
