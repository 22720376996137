<div mat-dialog-title class="ct-dialog-header">
    <span class="header-large">{{ 'incidences.title' | translate }}</span>

    <button class="ct-button ct-button--tertiary" (click)="onClose()">
        <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
    </button>
</div>


<form [formGroup]="registerForm">
    <mat-dialog-content class="ct-dialog-content">
        <section class="section">
                <div class="details-container">
                    <div class="form-group">
                        <filterable-select formControlName="type" [options]="incidenceTypes" 
                        [title]="'incidences.type' | translate" [required]="true"></filterable-select>
                    </div>

                    <div class="form-group">
                        <filterable-select formControlName="operator" [options]="operatorList" 
                        [title]="'incidences.operator' | translate" displayField="name" valueField="id" [required]="true"></filterable-select> 
                    </div>
                    
                    <div class="form-group">
                        <filterable-select formControlName="numTer" [options]="terminals" 
                        [title]="'incidences.serialNumber' | translate" displayField="serialNumber" valueField="id" [required]="true"></filterable-select> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'incidences.description' | translate }}<span class="obligatorio"></span></mat-label>
                                <textarea formControlName="commet" rows="7" matInput maxlength="400" placeholder=""cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10" required></textarea>
                                <mat-error *ngIf="registerForm.get('commet')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                                <mat-hint align="end">{{ registerForm.get('commet')?.value?.length || 0 }} / 400</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
        </section>
    </mat-dialog-content>

    <!-- Botones para navegación -->
    <mat-dialog-actions align="end" class="botonera">
        <button class="ct-button ct-button--secondary" (click)="onClose()">{{ 'common.cancel' | translate }}</button>
        <button class="ct-button ct-button--primary" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
    </mat-dialog-actions>
</form>