<div mat-dialog-title class="ct-dialog-header">
    <span class="header-large" >{{ 'operators.newOperator' | translate }}</span>
    
    <button class="ct-button ct-button--tertiary" (click)="onClose()">
      <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
    </button>
</div>


<form [formGroup]="registerForm">
  <mat-dialog-content class="ct-dialog-content">
    <section class="section">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'clients.name' | translate }}<span class="obligatorio"></span></mat-label>
                <input matInput class="form-field" formControlName="nombre" type="text" required>
                <mat-error *ngIf="registerForm.get('nombre')?.hasError('required')" >{{ 'error.required.field' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'clients.cod' | translate }}<span class="obligatorio"></span></mat-label>
                <input matInput class="form-field" formControlName="cod" type="text">
              </mat-form-field>
            </div>
          </div>
        </div>
    </section>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="button-group">
    <button class="ct-button ct-button--secondary" (click)="onClose()">{{ 'common.cancel' | translate }}</button>
    <button class="ct-button ct-button--primary" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
  </mat-dialog-actions>
</form>