
  <div class="edit-panel">
    <div class="header">
      <div class="title">{{ 'incidences.title' | translate }}</div>
      <div class="action-buttons">
        <ng-container *ngIf="client.canByInput(client.functIds.edit_incidences) && !readOnly">
          <button class="ct-button ct-button--primary" (click)="onFormSubmit()" [disabled]="registerForm.pristine || registerForm.invalid">
            {{ 'common.submit' | translate }}
          </button>
        </ng-container>
        
        <button class="ct-button ct-button--tertiary" (click)="onClose()">
          <mat-icon  class="ct-icon" svgIcon="x"></mat-icon>
        </button>
      </div>
    </div>

    <form [formGroup]="registerForm">
      <fieldset class="edit-container bordered">
        <legend class="details-header">
          <label class="title-small">{{ 'incidences.details' | translate }}</label>
        </legend>
        <div class="edit-content">
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <filterable-select formControlName="type" [options]="incidenceTypes" 
                    [title]="'incidences.type' | translate" [required]="true"></filterable-select>
              </div>
            </div>
  
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <filterable-select formControlName="operator" [options]="operatorList" 
                [title]="'incidences.operator' | translate" displayField="name" valueField="id" [required]="true"></filterable-select>
              </div>
            </div>
          </div>
  
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <filterable-select formControlName="numTer" [options]="terminals" 
                    [title]="'incidences.serialNumber' | translate" displayField="serialNumber" valueField="id" [required]="true"></filterable-select> 
              </div>
            </div>
          </div>
  
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'incidences.description' | translate }}<span class="obligatorio"></span></mat-label>
                  <textarea formControlName="commet" rows="7" matInput maxlength="400" placeholder="" 
                    cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10" required></textarea>
                  <mat-error *ngIf="registerForm.get('commet')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                  <mat-hint align="end">{{ registerForm.get('commet')?.value?.length || 0 }} / 400</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
  
          <!-- Campo de resolución visible solo si es una incidencia cerrada -->
          <div class="row" *ngIf="isCloseIncidence">
            <div class="col-12">
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'incidences.resolution' | translate }}<span class="obligatorio"></span></mat-label>
                  <textarea formControlName="resolution" rows="7" matInput maxlength="400" placeholder="" 
                    cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10" required></textarea>
                  <mat-error *ngIf="registerForm.get('resolution')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                  <mat-hint align="end">{{ registerForm.get('resolution')?.value?.length || 0 }} / 400</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  