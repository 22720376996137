
<button class="ct-button ct-button--secondary" (click)="openWindows()">
    <mat-icon class="ct-icon" svgIcon="search-md"></mat-icon>
    {{ 'vendingMachine.selector' | translate }}
</button>

<div class="tablaPersonal">
    <table  aria-describedby="" mat-table [dataSource]="dataSource" >

        <!-- serial number Column -->
        <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
                <div> {{ 'vendingMachine.serialNumber' | translate }} </div>
            </th>
            <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{element.serialNumber}} </td>
        </ng-container>

        <!-- manufacID Column -->
        <ng-container matColumnDef="manufacID">
            <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
                <div> {{ 'vendingMachine.manufacID' | translate }} </div>
            </th>
            <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{element.manufacID}} </td>
        </ng-container>

        <!-- model number Column -->
        <ng-container matColumnDef="modelNumber">
            <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
                <div> {{ 'vendingMachine.modelNumber' | translate }} </div>
            </th>
            <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{element.modelNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="text-end">
                <button class="ct-button ct-button--tertiary" (click)="onDelete(element)">
                    <mat-icon class="ct-icon red" svgIcon="trash-01"></mat-icon>
                </button>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
   
    </table>

    <!-- Empty state -->
    <ng-container *ngIf="!dataSource.data.length">
        <app-table-empty-state [border]="false" [icon]="false"></app-table-empty-state>
    </ng-container>
</div>