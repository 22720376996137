<div class="filter-pill">
    <ul class="filter-pill__list" *ngIf="filter">
        <li class="filter-pill__item">{{ filter.fieldLabel | translate }}</li>
        <li class="filter-pill__item">{{ filter.operationLabel | translate }}</li>
        <li class="filter-pill__item">{{ filter.valueLabel }}</li>
        <li class="filter-pill__button">
            <button class="ct-button ct-button-icon" (click)="remove.emit()">
                <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
            </button>
        </li>
    </ul>
</div>
