import { PENDING_ERRORS } from "../util/constants";
import { FilterAndPagBean } from "./FilterAndPagBean";
import { VendingMachineAtento } from "./vendingMachineAtento.model";

export class Pending {

    id!: number;
    dateCreate: Date | undefined;
    idOperator: number | undefined;
	idClient: number | undefined;
	idEntorno: number | undefined;
    sendJson: string | undefined;
    retries: number | undefined;

	filterAndPagBean: FilterAndPagBean | undefined;

	idVM: number | undefined;
	typeSend: string | undefined;
	idUser: number | undefined;
	codError: number | undefined;
	idFile: number | undefined;
	idOriginal: number | undefined;
	dateIni: string | undefined;
	horaDuration: string | undefined;
	setMachine:boolean = false;

	lastBlock: number | undefined;
	numBlocks: number | undefined;
	dateLastBlock: Date | undefined;
	
	vm: VendingMachineAtento | undefined;
	nameOperator: string | undefined;
	nameClient: string | undefined;
	nameSubClient: string | undefined;

	
	constructor(values: Object = {}) {
		Object.assign(this, values);
	}

	get packegeName():string{
		if(!this.sendJson) return '';
		const parsedJson = JSON.parse(this.sendJson);
		const configData = Array.isArray(parsedJson) ? parsedJson[0] : parsedJson;
		const packageName = configData?.packageName ?? configData?.pakage; 
		return packageName;
	}

	
	get fileName():string{
		if(!this.sendJson) return '';
		const parsedJson = JSON.parse(this.sendJson);
		const configData = Array.isArray(parsedJson) ? parsedJson[0] : parsedJson;
		return configData.fileName;
	}

	get commandType(): string {
		if(!this.sendJson) return '';
		const parsedJson = JSON.parse(this.sendJson);
		const configData = Array.isArray(parsedJson) ? parsedJson[0] : parsedJson;
		return configData.command;
	}

	get isCommand():boolean{
		return this.typeSend === PENDING_TASKS_TYPES.CONFIG;
	}

	get percentage():number{
		let val = 0
		if (this.lastBlock && this.numBlocks) {
		  val = this.lastBlock * 100 / this.numBlocks
		}
		return Math.floor(val);
	  }


	get canReset():boolean{
		return (!!this.retries && this.retries > 3);
	}

	get statusClass():string{
		if(!this.setMachine && !this.canReset) return 'status-icon blue';
		if(!this.setMachine && this.canReset) return 'status-icon red';
		return 'status-icon green';
	}
	
	get statusLabel():string{
		if(!this.setMachine && !this.canReset) return 'pendingTasks.pending';
		if(!this.setMachine && this.canReset) return 'pendingTasks.block';
		return 'pendingTasks.downloaded';
	}

	get errorLabel(){
		if(!this.codError) return '';
		const label = PENDING_ERRORS[this.codError] ?? PENDING_ERRORS['default'];
		return `pendingErrors.${label}`;
	}
}


export enum PENDING_TASKS_TYPES {
	FILE_APP = "1",
	DISCOUNT = "2",
	RESET = "3",
	AUDITPERIOD = "4",
	GETDEX = "5",
	PROFILE = "6",
	CONFIG = "7",
	VEND = "8",
	GETFILE = "9",
	FILE_PARAM = "10",
	FILE_WEB = "11",
	CONFIG_PINPAD = "25",
	CAMPANA_PINPAD = "50"
  }
  
  export type PendingTasksTypes = typeof PENDING_TASKS_TYPES;