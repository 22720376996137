import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginatorIntl, MatLegacyPaginatorModule, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { Client, SubClient, SubClientCompleto, SubClientListItem } from 'src/app/models/client.model';
import { environment } from 'src/environments/environment';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { UtilService } from 'src/app/services/util.service';
import { ConfirmDialogActions } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { NotifierService } from 'angular-notifier';
import { Operator } from 'src/app/models/operator.model';
import { SubClientService } from './sub-client.service';
import { FormSubClientComponent } from './form-sub-client/form-sub-client.component';
import { CommonModule } from '@angular/common';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TableEmptyStateComponent } from 'src/app/components/table-empty-state/table-empty-state.component';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { FormEditSubClientComponent } from './form-edit-sub-client/form-edit-sub-client.component';
import { MatTableModule } from '@angular/material/table';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';

enum Columns {
  name = 'name',
  idOperator = 'idOperator',
  idClient = 'idClient',
  cod = 'cod',
  localidad = 'localidad',
  personContact = 'personContact',
  phone = 'phone',
  email = 'email',
  web = 'web',
  cif = 'cif',
  view = 'view',
}

const FILTER_COLUMNS:{ field:string, type: FieldType }[] = [
  { field:'name', type:'string'},
  { field:'cod', type:'string' },
  { field:'localidad', type:'string'},
  { field:'personContact', type:'string' },
]

@Component({
  standalone:true,
  imports:[CommonModule, 
    MatTableModule, 
    MatLegacyPaginatorModule, 
    MatSortModule, 
    MatTableExporterModule, 
    TranslateModule, 
    FilterComponent,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyTooltipModule,
    TableEmptyStateComponent
  ],
  selector: 'app-sub-client',
  templateUrl: './sub-client.component.html',
  styleUrls: ['./sub-client.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class SubClientComponent implements OnInit {
  displayedColumns =  Object.keys(Columns);
  dataSource = new MatTableDataSource<SubClientListItem>();
  exportFileName: string | undefined;

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  direccion: string | null = "ASC";
  pageSizeOptions = environment.paginacion;
  sortId:string | null = Columns.name;
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  // Filters config
  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `clients.${column.field}`, column.type);
    return fielterField;
  })
  filterList: FilterableValue[] = [];

  operators:Operator[] = [];
  clients:Client[] = [];

  constructor(
    private service: SubClientService,
    public dialog: MatDialog,
    private utilService: UtilService,
    private translate:TranslateService,
    public rol:RolHandlerService,
    public hierarchy:HierarchyHandlerService
  ) {}


  async lanzarLLamada() {
    const paginatorState = this.getPaginatorState();
    const filterAndPagBean =  new FilterAndPagBean(
      this.direccion, 
      this.sortId, 
      this.filterList, 
      paginatorState.startPage,
      paginatorState.endPage,
      paginatorState.pageIndex
    );
    
    let value = await this.service.find(filterAndPagBean);
    let pagCount = await this.service.countTotal(filterAndPagBean);
    if(value){
      this.dataSource = new MatTableDataSource<SubClientListItem>(this.mapClientsWithOperators(value.data));
      if(pagCount) this.pageLength = pagCount.data?.total ?? 0;
    }
  }

  mapClientsWithOperators(subClients:SubClientCompleto[]):SubClientListItem[]{
    return subClients.map((subCl)=>{
      const clientName = subCl.client?.name;
      const operatorName = subCl.client?.operator?.name;
      return { ...subCl, operatorName: operatorName, clientName:clientName } as SubClientListItem;
    })
  }

  private getPaginatorState(){
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;

    return { startPage, endPage, pageIndex };
  }
  
  async ngOnInit(): Promise<void> {
    this.exportFileName = this.translate.instant('export.fileSubClient');
    this.displayedColumns = this.hierarchy.filterColumns(Object.keys(Columns));
    this.hierarchy.hasData.subscribe(hasData=>{
      if(hasData){
        if(this.hierarchy.canByLevel(this.hierarchy.level.LEVEL_1)){
          this.filterColumns.push(this.hierarchy.hierarchyFilter('LEVEL_1', 'idOperator'))
        }
        if(this.hierarchy.canByLevel(this.hierarchy.level.LEVEL_2)){
          this.filterColumns.push(this.hierarchy.hierarchyFilter('LEVEL_2', 'client.id'))
        }
      }
    });
    // Cargar datos al iniciar
    this.lanzarLLamada();
  }

  onNew(){
    const dialogRef = this.dialog.open(FormSubClientComponent, {
      width: '700px', panelClass: 'custom-modalbox-big',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === ConfirmDialogActions.CONFIRM) this.lanzarLLamada();
    });
  }

  onEdit(row:SubClient){
    const dialogRef = this.dialog.open(FormEditSubClientComponent, {
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel',
      data: { clientId : row.id }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === ConfirmDialogActions.CONFIRM) this.lanzarLLamada();
    });
  }

  refrescar(){
    this.lanzarLLamada();
  }
  
  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_",".")
    this.sortId=active;
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  getNotExportColumns(){
    const actionsColIdx = (this.displayedColumns.length-1);
    return [actionsColIdx];
  }

}
