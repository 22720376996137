<main id="main" class="main">
    <div class="pagetitle">
        <span class="header-large"> {{ 'parameters.parameters' | translate }} </span>
        <div>
            <button class="ct-button ct-button--secondary" (click)="refrescar()">
                <mat-icon class="ct-icon" svgIcon="refresh-ccw-01"></mat-icon>
                <span class="hide-on-mobile"> {{'common.refresh' | translate }}</span>
            </button>
            
            <ng-container *ngIf="rol.canByInput(rol.functIds.edit_parameters)">
                <button class="ct-button ct-button--primary" (click)="onNew()">
                    <mat-icon class="ct-icon" svgIcon="plus"></mat-icon>
                    <span class="hide-on-mobile"> {{ 'parameters.new' | translate }}</span>
                </button>
            </ng-container>
        </div>
    </div>

    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>


    <div class="mat-elevation-z2 tablaPersonal">
        <table mat-table [dataSource]="dataSource" aria-describedby="" matSort
            (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter" [hiddenColumns]="getNotExportColumns()">

            <!-- Column Definitions -->
      
            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'parameters.name' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Operator Column -->
            <ng-container matColumnDef="idOperator">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'hierarchy.level_1' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.idOperator | levelName:'LEVEL_1'}} </td>
            </ng-container>

            <!-- Columna Cliente -->
            <ng-container matColumnDef="idClient">
                <th mat-header-cell *matHeaderCellDef> 
                    <div mat-sort-header>{{ 'hierarchy.level_2' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.idClient | levelName:'LEVEL_2' }}</td>
            </ng-container>

            <!-- Columna Subcliente -->
            <ng-container matColumnDef="idSubClient">
                <th mat-header-cell *matHeaderCellDef> 
                    <div mat-sort-header>{{ 'hierarchy.level_3' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.idSubClient | levelName:'LEVEL_3' }}</td>
            </ng-container>

            <!-- dateCreate Column -->
            <ng-container matColumnDef="dateCreate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'parameters.dateCreate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.dateCreate | dateOnTimeZone:true }}</td>
            </ng-container>

            <!-- dateUpdate Column -->
            <ng-container matColumnDef="dateUpdate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'parameters.dateUpdate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.dateUpdate | dateOnTimeZone:true }}</td>
            </ng-container>

            <!-- Version Column -->
            <ng-container matColumnDef="version">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'parameters.version' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.version}} </td>
            </ng-container>

            <!-- Tags Column -->
            <ng-container matColumnDef="tags">
                <th mat-header-cell *matHeaderCellDef>{{ 'parameters.tags' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <app-tags-display [tags]="element.listEtiquetas" [limit]="2"></app-tags-display>
                </td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef style="width:30%">
                    <div mat-sort-header>{{ 'parameters.description' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" style="width:30%"> {{element.description}} </td>
            </ng-container>

            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
                <td mat-cell *matCellDef="let element" class="button" style="width: 60px;">
                    <ng-container *ngIf="rol.canByInput(rol.functIds.edit_parameters)">
                        <button matTooltip="{{ 'common.view' | translate }}" (click)="onEdit(element)" mat-icon-button
                            class="iconoTabla">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                    </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- Empty state -->
        <ng-container *ngIf="!dataSource.data.length">
            <app-table-empty-state></app-table-empty-state>
        </ng-container>

        <!-- Botón para exportar -->
        <button id="export" class="icoDownload" mat-icon-button
            (click)="exporter.exportTable('xlsx', { fileName: exportFileName })">
            <i class="bi bi-file-earmark-arrow-down"></i>
        </button>
        <!-- Paginador -->
        <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>

    </div>
</main>