import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotifierService } from 'angular-notifier';
import { Profile } from 'src/app/models/profiles.model';
import { PerfilesService } from '../perfiles.service';
import { AppsService } from '../../aplications/aplications.service';
import { Aplications } from 'src/app/models/aplications.model';
import { Parameters } from 'src/app/models/parameters.model';
import { ParamService } from '../../param/param.service';
import { PerfilApp } from 'src/app/models/perfilApp.model';
import { EditAppsComponent } from '../edit-apps/edit-apps.component';
import { EditMachinesComponent } from '../edit-machines/edit-machines.component';
import { ListRelatedMachinesComponent } from '../list-related-machines/list-related-machines.component';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { EditProfileAppComponent } from '../edit-profile-app/edit-profile-app.component';
import { DatesHandlerService } from 'src/app/services/dates-handler.service';
import { FormCreateActions } from 'src/app/util/constants';

@Component({
  selector: 'app-edit-perfiles',
  templateUrl: './edit-perfiles.component.html',
  styleUrls: ['./edit-perfiles.component.css']
})
export class EditPerfilesComponent implements OnInit {
  isAdmin: boolean = true;
  isLinear = true;
  selectedFile: File | null = null;
  resultListApp: PerfilApp[] = [];
  showDropZone = false;
  profile: Profile = new Profile;

  uploadProgress = 0;
  isDetailsOpen = true;
  isDateFieldsOpen = true;
  

  // Form groups
  form!: FormGroup;
  appsFormGroup!: FormGroup;

  isOpen: { [key: string]: boolean } = {
    details: true,
    hierarchy:true,
    dateFields: true,
    terminals: true,
    apps: true,
    distance: true,
  };

  // Variables for apps and parameters
  availableApps: Aplications[] = [];
  parameters: Parameters[] = [];
  hasEditedRelateds:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    public dialogRef: MatDialogRef<EditPerfilesComponent>,
    public dialog: MatDialog,
    public rol: RolHandlerService,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private translate: TranslateService,
    private perfilesService: PerfilesService,
    private appsService: AppsService,
    private paramService: ParamService,
    private profileService: PerfilesService,
    private datesHandler:DatesHandlerService,
  ) { 
    // Intercept cases of modal closure, and send if the profile has changed.
    this.dialogRef.beforeClosed().subscribe((value)=>{
      if(!value) {
        const dialogResponse = this.hasEditedRelateds ? ConfirmDialogActions.CONFIRM : ConfirmDialogActions.CANCEL;
        this.dialogRef.close(dialogResponse); 
      } 
    });
  }

  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      name: new FormControl("", Validators.compose([Validators.required, Validators.maxLength(40)])),
      description: new FormControl("", Validators.compose([Validators.required, Validators.maxLength(200)])),
      manufacID: new FormControl({ value:"" , disabled:true }, Validators.required),
      hierarchy:new FormControl({ value: { idOperator:null, idClient:null, idSubClient:null }, disabled:true }),
      dateCreate: new FormControl({ value: "", disabled: true }),
      dateUpdate: new FormControl({ value: "", disabled: true }),
      
      // [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad 
      enableLocation: new FormControl({value:false, disabled:true}),
      distanceLocation: new FormControl({value:0, disabled:true}, Validators.compose([Validators.required,  Validators.min(0), Validators.max(20038)])),
      distanceUnit: new FormControl({value:"km", disabled:true},Validators.required )
    });

    this.form.get('enableLocation')?.valueChanges.subscribe((value)=>{
      if(!value){
        this.form.get('distanceLocation')?.disable();
        this.form.get('distanceUnit')?.disable();
      } else {
        this.form.get('distanceLocation')?.enable();
        this.form.get('distanceUnit')?.enable();
      }
    });

    this.loadAvailableApps();
    this.loadParameters();
    this.loadProfile();
  }

  private async loadProfile() {
    const response = await this.profileService.get(this.data.id);
    if (response && response.data) {
      this.profile = response.data as Profile;
      this.form.get("name")?.setValue(this.profile.name);
      this.form.get("description")?.setValue(this.profile.description);
      this.form.get("manufacID")?.setValue(this.profile.manufacID);
      this.form.get("dateCreate")?.setValue(this.datesHandler.formatDateFromUTCDate(this.profile.dateCreate?.toString(), true));
      this.form.get("dateUpdate")?.setValue(this.datesHandler.formatDateFromUTCDate(this.profile.dateUpdate?.toString(), true));
      this.form.get("enableLocation")?.setValue(this.profile.enableLocation ?? true);
      this.form.get("distanceLocation")?.setValue(this.profile.distanceLocation ?? 0);
      this.form.get("distanceUnit")?.setValue(this.profile.distanceUnit ?? "m");
      this.form.get('hierarchy')?.setValue({ idOperator:this.profile.idOperator, idClient:this.profile.idClient, idSubClient:this.profile.idSubClient })
      
      const profileAppsList = response.data.listApp?.map((item:PerfilApp)=>{
        const perfilApp = new PerfilApp;
        perfilApp.mapFromServer(item);
        return perfilApp;
      });
      profileAppsList.sort((a:PerfilApp,b:PerfilApp)=> a.orden - b.orden);
      
      this.profile.listApp = profileAppsList; 

      this.form.markAsPristine();
    } else {
      this.notifier.notify('error', 'Error al obtener perfil');
      this.onCancel();
    }
  }

  private async loadAvailableApps(): Promise<void> {
    const result = await this.appsService.find({} as any);
    if (result?.status === 0) {
      this.availableApps = result.data;
    }
  }

  private async loadParameters(): Promise<void> {
    const result = await this.paramService.find({} as any);
    if (result?.status === 0) {
      this.parameters = result.data;
    }
  }

  public openEditTerminalsScreen() {
    const dialogEditProfile: MatDialogRef<ListRelatedMachinesComponent> = this.dialog.open(ListRelatedMachinesComponent, {
      width: '700px',
      panelClass: 'custom-modalbox-big',
      data: { profile: this.profile }
    });

    dialogEditProfile.afterClosed().subscribe((response) => {
      if (response?.result === 1) {
        this.hasEditedRelateds = true;
        this.loadProfile();
      }
    });
  }

  public openAddTerminalsScreen() {
    const dialogEditProfile: MatDialogRef<EditMachinesComponent> = this.dialog.open(EditMachinesComponent, {
      width: '700px',
      panelClass: 'custom-modalbox-big',
      data: { profile: this.profile }
    });

    dialogEditProfile.afterClosed().subscribe((response) => {
      if (response?.result === 1) {
        this.hasEditedRelateds = true;
        this.loadProfile();
      }
    });
  }

  public updateRelatedAppScreen(perfilApp:PerfilApp) {
    const dialogEditProfile: MatDialogRef<EditProfileAppComponent> = this.dialog.open(EditProfileAppComponent, {
      width: '700px',
      panelClass: 'custom-modalbox-big',
      data: { profile: this.profile, selected: perfilApp }
    });

    dialogEditProfile.afterClosed().subscribe((response) => {
      if (response?.result === 1) {
        this.hasEditedRelateds = true;
        this.loadProfile();
      }
    });
  }

  public addRelatedAppsScreen() {
    console.log('addRelatedAppsScreen',this.profile);
    const dialogEditProfile: MatDialogRef<EditAppsComponent> = this.dialog.open(EditAppsComponent, {
      width: '700px',
      panelClass: 'custom-modalbox-big',
      data: { profile: this.profile }
    });

    dialogEditProfile.afterClosed().subscribe((response) => {
      if (response?.result === 1) {
        this.hasEditedRelateds = true;
        this.loadProfile();
      }
    });
  }

  public sortedRelatedApps(relatedApps:PerfilApp[]){
    console.log('sortedRelatedApps');
    this.profile.listApp = relatedApps;
  }

  async saveRelatedApps(){
    const response = await this.profileService.ordenarApps(this.profile);
    if(response!.status >= 0 ) {
      this.notifier.notify('success', this.translate.instant('success.profileUpdated'));
      this.loadProfile();
    };
  }

  async addRelatedComandScreen() {   
    alert("falta desarrollar");
  }

  onClone() {   
    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.profile; 
    dialogData.action = ElementAction.clone;
    dialogData.class = DialogClass.info;  
    dialogData.icon = 'save-01';

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
       this.cloneProfile();
      }
    });
  }

  private async cloneProfile(){
    const cloneProfile = new Profile(this.profile);
    cloneProfile.listVendingMachine = [];
    cloneProfile.name = `${this.profile.name} (${this.translate.instant('common.copy')})`;
    delete cloneProfile.id;

    const response = await this.profileService.addProfile(cloneProfile);
    if(response && response.status === 0){
      this.notifier.notify('success', this.translate.instant('profiles.profileCreated'));
      this.dialogRef.close(FormCreateActions.SAVED);
    }
  }
  

  async deleteApp(perfilAppId: number) {
    if(!perfilAppId) return;
    const profile = { ...this.profile };
    const listApp = profile.listApp ?? [];
    profile.listApp = listApp.filter((item)=> item.id !== perfilAppId);

    const response = await this.profileService.saveApps(profile);
    if (response?.status === 0) {
      this.loadProfile();
      this.hasEditedRelateds = true;
      this.notifier.notify('success', this.translate.instant('success.profileUpdated'));
    }
  }

  toggleDropZone(): void {
    this.showDropZone = !this.showDropZone;
  }

  toggleSection(section: string): void {
    this.isOpen[section] = !this.isOpen[section];
  }

  onSave(): void {
    if (!this.profile) return;

    // Validación del formulario
    if (!this.form.valid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }

    const formData = this.form.value;
    const updatedApp = new Profile;
    updatedApp.id = this.profile.id;
    updatedApp.name = formData.name;
    updatedApp.description = formData.description;
    updatedApp.enableLocation = formData.enableLocation;
    updatedApp.distanceLocation = formData.distanceLocation;
    updatedApp.distanceUnit = formData.distanceUnit
    updatedApp.cod = this.profile.cod;

    updatedApp.idOperator = this.profile.idOperator;
    updatedApp.idClient = this.profile.idClient;
    updatedApp.idSubClient = this.profile.idSubClient;
    updatedApp.manufacID = this.profile.manufacID;
   
    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.profile; 
    dialogData.action = ElementAction.udpate;
    dialogData.class = DialogClass.info;  
    dialogData.icon = 'save-01';

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const response = await this.perfilesService.updateProfile(updatedApp);
        if(response?.status === 0){
          this.dialogRef.close(ConfirmDialogActions.CONFIRM);
          this.notifier.notify('success', this.translate.instant('success.profileUpdated'));
        }
      }
    });
  }

  onDelete(): void {
    if (!this.profile || !this.profile.id) return;

    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.profile; 
    dialogData.action = ElementAction.delete;
    dialogData.class = DialogClass.error;  
    dialogData.icon = 'trash-01';

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const response = await this.profileService.delete(this.profile.id as number, this.profile.name);
        if(response?.status === 0){
          this.dialogRef.close(ConfirmDialogActions.CONFIRM);
          this.notifier.notify('success', this.translate.instant('success.profileDeleted'));
        }
       }
    });
  }

  onCancel(): void {
    this.dialogRef.close(ConfirmDialogActions.CANCEL);
  }


}
