<mat-form-field appearance="outline" floatLabel="always" class="ct-select-container w-100">
  <mat-label class="ct-select-label">{{ title }}</mat-label>
  <mat-select class="ct-select" [formControl]="selectControl" (selectionChange)="onSelectorChange()" panelClass="ct-select-dropdown" (openedChange)="onOpenChange($event)">
    <mat-label *ngIf="options.length">
      <input type="search" #SearchInput [formControl]="filterControl" placeholder="{{'common.search' | translate}}" >
    </mat-label>
    
    <mat-option *ngIf="options.length && !required">{{ 'common.none' | translate }}</mat-option>
    <mat-option *ngFor="let option of filteredOptions" [value]="option[valueField]">{{ option[displayField] }}</mat-option>
        
    <mat-option *ngIf="!filteredOptions.length && options.length" class="ct-select-dropdown__empty" disabled>
      <span>{{ 'common.searchReturnNoData' | translate }}</span>
    </mat-option>

    <mat-option *ngIf="!options.length" class="ct-select-dropdown__empty" disabled>
      <span>{{ 'common.noData' | translate }}</span>
    </mat-option>
  </mat-select>
  
  <mat-error *ngIf="selectControl?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
</mat-form-field>