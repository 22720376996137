import { Component,ViewChild, OnInit, AfterViewInit } from '@angular/core';
import {ChartComponent, ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels, ApexTitleSubtitle,
  ApexStroke, ApexGrid, ApexNonAxisChartSeries,ApexResponsive, ApexPlotOptions, ApexYAxis, ApexFill, ApexLegend} from "ng-apexcharts";
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { StadisticService } from '../stadistic.service';
import { StadisticBean } from 'src/app/models/stadisticBean.model';
import moment, { locales } from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { StadisticReportTMS } from 'src/app/models/StadisticReportTMS.model';
import { COLOR_TIPE_CARD, COMM_REPORT, CONSUME, TICKET, TIPE_CARD } from 'src/app/util/StadisticUtil';
import { ChartOptions } from 'chart.js';
import { DataPeriod } from 'src/app/models/dataPeriod.model';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { FilterableValue } from 'src/app/models/FilterAndPagBean';
import { TranslateService } from '@ngx-translate/core';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';

export type chartOptions_reader = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  stroke: ApexStroke;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  colors: string[];
  fill: ApexFill;
  legend: ApexLegend;
};

export type chartOptions_aveTime = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    dataLabels: ApexDataLabels;
    grid: ApexGrid;
    stroke: ApexStroke;
    title: ApexTitleSubtitle;
};


export type chartOptions_transaction = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  colors: string[];
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};
export type chartOptions_consuption = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  colors: string[];
  legend: ApexLegend;
  fill: ApexFill;
};
export type chartOptions_hostComm = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  colors: string[];
  legend: ApexLegend;
  fill: ApexFill;
};
export type chartOptions_ticketPinting = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};
export type chartOptions_accepted = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  colors: string[];
  responsive: ApexResponsive[];
  labels: any;
};

const FILTER_COLUMNS:{ field:string, type: FieldType }[] = [
  { field:'commerce', type:'string' },
  { field:'date', type:'date' },
]

@Component({
  selector: 'app-stadistic-functionally',
  templateUrl: './stadistic-functionally.component.html',
  styleUrls: ['./stadistic-functionally.component.css']
})
export class StadisticFunctionallyComponent implements OnInit, AfterViewInit {
  @ViewChild("reader")  reader!: ChartComponent; 
  @ViewChild("ticketPinting")  ticketPinting!: ChartComponent; 
  @ViewChild("hostComm")  hostComm!: ChartComponent; 
  @ViewChild("consuption")  consuption!: ChartComponent; 
  @ViewChild("aveTime")  aveTime!: ChartComponent; 
  @ViewChild("accepted")  accepted!: ChartComponent; 
  @ViewChild("transaction")  transaction!: ChartComponent; 
  public chartOptions_reader: Partial<ChartOptions> | any = {series: [],chart: {type: 'bar'}};
  public chartOptions_ticketPinting: Partial<ChartOptions> | any= {series: [],chart: {type: 'bar'}};
  public chartOptions_hostComm: Partial<ChartOptions> | any= {series: [],chart: {type: 'bar'}};
  public chartOptions_accepted: Partial<ChartOptions> | any= {series: [],chart: {type: 'bar'}};
  public chartOptions_transaction: Partial<ChartOptions> | any = {series: [],chart: {type: 'bar'}};
  
  public chart_consuption_list: DataPeriod[] = [];
  
  ///filtros
  filterColumns = FILTER_COLUMNS.map((filter)=>{
    return new FilterField(filter.field, `stadisticsFunctionally.${filter.field}`, filter.type);
  })
  filterList: FilterableValue[] = [];

  isAdmin:boolean = true;
  hashLecturas = new Map()
  hashComunication = new Map()
  hashTiempoMedio = new Map()
  hashTicket = new Map()
  hashOperationOK = new Map()
  hashOperation = new Map()

  constructor(private utilServicios: UtilService,  
    private terminalService: MonitoringService, 
    private authService:AuthService,
    private stadisticService :StadisticService,
    private translate:TranslateService,
    private hierarchy:HierarchyHandlerService
  ) {}

  async ngOnInit(): Promise<void> {
    //Terminal
    let vmsList = await this.terminalService.find(new UsersConsoleRequest);
    const vmOptions = vmsList?.data?.map((vm:VendingMachineAtento)=>{ return { label:vm.serialNumber ?? '', value:vm.id }});
    this.filterColumns.push(new FilterField('idVendingMachine', 'stadisticsFunctionally.idVendingMachine', 'number', vmOptions));
    this.hierarchy.getFilterFieldsAsync(this.filterColumns);
    this.isAdmin = await this.utilServicios.isAdmin();
    if(this.isAdmin) await this.cargar();
  }

  ngAfterViewInit(): void {
    this.lanzarLLamada();
  }

  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  async lanzarLLamada(){
    const request = new StadisticBean;
    const selectedGro = this.getFilterableValue('idGroup');
    if (selectedGro) request.idGroup = selectedGro.value as number;
    
    const selectedOpe = this.getFilterableValue('idOperator');
    if (selectedOpe) request.idOperator = selectedOpe.value as number;
    
    const selectedClient = this.getFilterableValue('idClient');
    if (selectedClient) request.idClient = selectedClient.value as number;
    
    const selectedSubClient = this.getFilterableValue('idSubClient');
    if (selectedSubClient) request.idSubClient = selectedSubClient.value as number;

    const selectedTerminal = this.getFilterableValue('idVendingMachine');
    if (selectedTerminal) request.idVendingMachine = selectedTerminal.value as number;

    const commerceFilter = this.getFilterableValue('commerce');
    if (commerceFilter) request.commerce = commerceFilter.value as string;

    request.typeReport=50//valor fijo para graficas del TMS
    let userSession = this.authService.currentUserValue();
    request.idEntorno = userSession?.entorno?.id;

    this.setDateFilter(request);

    let response = await this.stadisticService.find(request);
    if(response?.data){
      this.procesarDatos(response.data, request.dateIni, request.dateEnd)
    }
  }

  setDateFilter(request:StadisticBean){
    this.filterList.forEach((filter)=>{
      if(filter.column === 'date'){
        if(filter.operation === 'LESS_THAN_OR_EQUAL') request.dateEnd = new Date(filter.value as number);
        if(filter.operation === 'GREATER_THAN_OR_EQUAL') request.dateIni = new Date(filter.value as number);
      }
    });
    request.dateIni = request.dateIni ?? (request.dateEnd ? moment(request.dateEnd).startOf("month") : moment(new Date()).date(1));
    request.dateEnd = request.dateEnd ?? moment(request.dateIni).endOf("month").endOf("day").toDate();
  }

  async procesarDatos(stadisticResult:StadisticReportTMS, dateIni:Date, dateEnd:Date){   
    this.chart_consuption_list = stadisticResult.listData;
    
    let fecha1 = moment(dateIni);
    let fecha2 = moment(dateEnd);  
    let diff = fecha2.diff(fecha1, 'days');

    let ticketNO=0;
    let ticketSMS=0;
    let ticketMail=0;
    let ticketPrint=0;
    
    let opChip=0;
    let opCless=0;
    let opClick=0;
    let opBand=0;
    

    this.hashLecturas = new Map()
    this.hashComunication = new Map()
    this.hashTiempoMedio = new Map()
    this.hashTicket = new Map()
    this.hashOperationOK = new Map()
    this.hashOperation = new Map()

    for (let i = 0; i <= diff; i++) {
      let fechaTemp = moment(dateIni);
        fechaTemp.add(i, 'days');

        let punto = fechaTemp.format('DD-MM-YYYY');
        let valueLectura = new Map()
        valueLectura.set(TIPE_CARD.TIPE_BAND_OK, 0);
        valueLectura.set(TIPE_CARD.TIPE_CHIP_OK, 0);
        valueLectura.set(TIPE_CARD.TIPE_CLESS_OK, 0);
        valueLectura.set(TIPE_CARD.TIPE_BAND_KO, 0);
        valueLectura.set(TIPE_CARD.TIPE_CHIP_KO, 0);
        valueLectura.set(TIPE_CARD.TIPE_CLESS_KO, 0);
        this.hashLecturas.set(punto,valueLectura)

        let valueOperation = new Map()
        valueOperation.set(TIPE_CARD.TIPE_BAND_OK, 0);
        valueOperation.set(TIPE_CARD.TIPE_CHIP_OK, 0);
        valueOperation.set(TIPE_CARD.TIPE_CLESS_OK, 0);
        valueOperation.set(TIPE_CARD.TIPE_BAND_KO, 0);
        valueOperation.set(TIPE_CARD.TIPE_CHIP_KO, 0);
        valueOperation.set(TIPE_CARD.TIPE_CLESS_KO, 0);
        valueOperation.set(TIPE_CARD.TIPE_KEY_OK, 0);
        valueOperation.set(TIPE_CARD.TIPE_KEY_OK, 0);
        this.hashOperation.set(punto,valueOperation)

        let valueComunicaciones = new Map()
        valueComunicaciones.set(COMM_REPORT.COMM_OK, null);
        valueComunicaciones.set(COMM_REPORT.COMM_KO, null);
        valueComunicaciones.set(COMM_REPORT.SEC_COMM_OK, null);
        valueComunicaciones.set(COMM_REPORT.SEC_COMM_KO, null);
        this.hashComunication.set(punto,valueComunicaciones)



        this.hashTiempoMedio.set(punto,0);
    }

    if(stadisticResult){
      let txData=0, rxData=0, dataTotal=0
      if(stadisticResult.ListInfoByDate){
        for (const entry of stadisticResult.ListInfoByDate) {
          ticketNO+=entry.ticketNo;
          ticketSMS+=entry.ticketSms;
          ticketMail+=entry.ticketMail;
          ticketPrint+=entry.ticketPrint;
          
          let key = entry.period.toString().substring(0,10)
          
  /////////////////////////////////////////////////////////////////////

          let valueLectura = new Map()
          let valueLecturaTEMP = this.hashLecturas.get(key)
              
          valueLectura.set(TIPE_CARD.TIPE_BAND_OK, entry.readBandaOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_BAND_OK));
          valueLectura.set(TIPE_CARD.TIPE_BAND_KO, entry.readBandaKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_BAND_KO));
          valueLectura.set(TIPE_CARD.TIPE_CHIP_OK, entry.readChipOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_CHIP_OK));
          valueLectura.set(TIPE_CARD.TIPE_CHIP_KO, entry.readChipKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_CHIP_KO));
          valueLectura.set(TIPE_CARD.TIPE_CLESS_OK, entry.readClessKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_CLESS_OK));
          valueLectura.set(TIPE_CARD.TIPE_CLESS_KO, entry.readClessOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_CLESS_KO));			
                

          this.hashLecturas.set(key, valueLectura);

  /////////////////////////////////////////////////////////////////////
          let valueComunicaciones = new Map()
          let valueComunicacionesTEMP = this.hashComunication.get(key)
          

          valueComunicaciones.set(COMM_REPORT.COMM_OK, entry.hostCommOk + valueComunicacionesTEMP.get(COMM_REPORT.COMM_OK));
          valueComunicaciones.set(COMM_REPORT.COMM_KO, entry.hostCommKo + valueComunicacionesTEMP.get(COMM_REPORT.COMM_KO));
          valueComunicaciones.set(COMM_REPORT.SEC_COMM_OK, entry.hostCommSecOk + valueComunicacionesTEMP.get(COMM_REPORT.SEC_COMM_OK));
          valueComunicaciones.set(COMM_REPORT.SEC_COMM_KO, entry.hostCommSecKo + valueComunicacionesTEMP.get(COMM_REPORT.SEC_COMM_KO));

          this.hashComunication.set(key, valueComunicaciones);


  /////////////////////////////////////////////////////////////////////

          let avgTEMP = this.hashTiempoMedio.get(key)
          let avgNow = entry.averageOp
          if(!avgTEMP){ avgTEMP = 0 }
          if(!avgNow){ avgNow = 0 }
          let media=avgTEMP 
          let valTemp=0
          if(avgNow>0){ valTemp=valTemp+avgNow }
          if(avgTEMP>0){ valTemp=valTemp+avgTEMP }
          if(avgTEMP>0 && avgNow>0){
            media= (valTemp)/2
            media = Number(media.toFixed(2))
          }else{
            media =valTemp
          }
          console.log("key="+key+"->"+entry.period+" avgTEMP="+avgTEMP+" avgNow="+avgNow+" media="+media+"")
          this.hashTiempoMedio.set(key,media);

        }

        this.hashTicket.set(TICKET.NO, ticketNO);
        this.hashTicket.set(TICKET.SMS, ticketSMS);
        this.hashTicket.set(TICKET.MAIL, ticketMail);
        this.hashTicket.set(TICKET.PRINT, ticketPrint);
      }


      if(stadisticResult.ListInfoByHour){
        for (const entry of stadisticResult.ListInfoByHour) {
          
            let key = entry.period.toString().substring(0,10)

            opChip+=entry.opChipOk;
            opBand+=entry.opBandaOk;
            opCless+=entry.opClessOk;
            opClick+=entry.opKeyOk;    
            
            
            let valueLectura = new Map()
            let valueLecturaTEMP = this.hashOperation.get(key)
                
            valueLectura.set(TIPE_CARD.TIPE_BAND_OK, entry.opBandaOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_BAND_OK));
            valueLectura.set(TIPE_CARD.TIPE_BAND_KO, entry.opBandaKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_BAND_KO));
            valueLectura.set(TIPE_CARD.TIPE_CHIP_OK, entry.opChipOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_CHIP_OK));
            valueLectura.set(TIPE_CARD.TIPE_CHIP_KO, entry.opChipKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_CHIP_KO));
            valueLectura.set(TIPE_CARD.TIPE_CLESS_OK, entry.opClessOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_CLESS_OK));
            valueLectura.set(TIPE_CARD.TIPE_CLESS_KO, entry.opClessKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_CLESS_KO));			
            valueLectura.set(TIPE_CARD.TIPE_KEY_OK, entry.opKeyOk + valueLecturaTEMP.get(TIPE_CARD.TIPE_KEY_OK));
            valueLectura.set(TIPE_CARD.TIPE_KEY_KO, entry.opKeyKo + valueLecturaTEMP.get(TIPE_CARD.TIPE_KEY_KO));			
                      
            this.hashOperation.set(key, valueLectura);
        }

        this.hashOperationOK.set(TIPE_CARD.TIPE_CHIP_OK, opChip);
        this.hashOperationOK.set(TIPE_CARD.TIPE_BAND_OK, opBand);
        this.hashOperationOK.set(TIPE_CARD.TIPE_CLESS_OK, opCless);
        this.hashOperationOK.set(TIPE_CARD.TIPE_KEY_OK, opClick);  
      }

      this.constructorTicketPrinting();
      this.constructorReader();
      this.constructorHostComm();
      this.constructorAccepted();
      this.constructorTrasanction();
    }

  }


  public filteredList1:any
  async cargar(){   
    //operadores
    this.utilServicios.findOperators().subscribe((operators:Operator[]) => {
      const operationsOptions = operators.map((op:Operator)=>{ return { label:op.name, value:(op.id as number) }});
      this.filterColumns.push(new FilterField('idOperator', 'stadisticsFunctionally.idOperator', 'number', operationsOptions));
    });
  }

  redimensionar(id:string) {
    let big = document.getElementById(id);
    if(big){
      let hasClase2 = big.classList.contains( 'graficaBig' );
    
        if(hasClase2){// si esta expandido lo contraemos

          for (let el of document.querySelectorAll<HTMLElement>('.grafica') ) {
            el.style.display = 'block';
            el.classList.remove("graficaBig");
          }

          for (let el of document.querySelectorAll<HTMLElement>('.expand') ) {
            el.style.display = 'block';
          }
          for (let el of document.querySelectorAll<HTMLElement>('.contract') ) {
            el.style.display = 'none';
          }

        }else{
            //agrandamos la grafica
            for (let el of document.querySelectorAll<HTMLElement>('.grafica') ) {
              el.style.display = 'none';
              el.classList.remove("graficaBig");
            }
            for (let el of document.querySelectorAll<HTMLElement>('.expand') ) {
              el.style.display = 'none';
            }
            for (let el of document.querySelectorAll<HTMLElement>('.contract') ) {
              el.style.display = 'block';
            }
        
            big.style.display = 'block';
            big.classList.add("graficaBig");            
        }
    }

    if(id == "_ticketPinting"){
      this.constructorTicketPrinting() 
    }else if(id == "_reader"){
      this.constructorReader() 
    }else if(id == "_hostComm"){
      this.constructorHostComm()
    }else if(id == "_accepted"){
      this.constructorAccepted()
    }else if(id == "_transaction"){
      this.constructorTrasanction()
    }
  }
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.lanzarLLamada();
  }

  constructorTrasanction(){
    let seriesTemp_TIPE_BAND_OK:any=[]
    let seriesTemp_TIPE_BAND_KO:any=[]
    let seriesTemp_TIPE_CHIP_OK:any=[]
    let seriesTemp_TIPE_CHIP_KO:any=[]
    let seriesTemp_TIPE_CLESS_OK:any=[]
    let seriesTemp_TIPE_CLESS_KO:any=[]
    let seriesTemp_TIPE_KEY_OK:any=[]
    let seriesTemp_TIPE_KEY_KO:any=[]

    for (let [_, valueLectura] of this.hashOperation) {
      
      let temp = valueLectura

        seriesTemp_TIPE_BAND_OK.push(temp.get(TIPE_CARD.TIPE_BAND_OK));
        seriesTemp_TIPE_BAND_KO.push(temp.get(TIPE_CARD.TIPE_BAND_KO));
        seriesTemp_TIPE_CHIP_OK.push(temp.get(TIPE_CARD.TIPE_CHIP_OK));
        seriesTemp_TIPE_CHIP_KO.push(temp.get(TIPE_CARD.TIPE_CHIP_KO));
        seriesTemp_TIPE_CLESS_OK.push(temp.get(TIPE_CARD.TIPE_CLESS_OK));
        seriesTemp_TIPE_CLESS_KO.push(temp.get(TIPE_CARD.TIPE_CLESS_KO));
        seriesTemp_TIPE_KEY_OK.push(temp.get(TIPE_CARD.TIPE_KEY_OK));
        seriesTemp_TIPE_KEY_KO.push(temp.get(TIPE_CARD.TIPE_KEY_KO));

    }

    this.chartOptions_transaction = {
      series: [
        {
          name: this.translate.instant(`stadisticsFunctionally.transaction.${TIPE_CARD.TIPE_BAND_OK}`),
          data: seriesTemp_TIPE_BAND_OK
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.transaction.${TIPE_CARD.TIPE_BAND_KO}`),
          data: seriesTemp_TIPE_BAND_KO
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.transaction.${TIPE_CARD.TIPE_CHIP_OK}`),
          data: seriesTemp_TIPE_CHIP_OK
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.transaction.${TIPE_CARD.TIPE_CHIP_KO}`),
          data: seriesTemp_TIPE_CHIP_KO
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.transaction.${TIPE_CARD.TIPE_CLESS_OK}`),
          data: seriesTemp_TIPE_CLESS_OK
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.transaction.${TIPE_CARD.TIPE_CLESS_KO}`),
          data: seriesTemp_TIPE_CLESS_KO
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.transaction.${TIPE_CARD.TIPE_KEY_OK}`),
          data: seriesTemp_TIPE_KEY_OK
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.transaction.${TIPE_CARD.TIPE_KEY_KO}`),
          data: seriesTemp_TIPE_KEY_KO
        },
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        locales:[this.chartLocale],
        defaultLocale:this.defaultLocale
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      }, 
      colors: [COLOR_TIPE_CARD.TIPE_BAND_OK,COLOR_TIPE_CARD.TIPE_BAND_KO,COLOR_TIPE_CARD.TIPE_CHIP_OK,COLOR_TIPE_CARD.TIPE_CHIP_KO,COLOR_TIPE_CARD.TIPE_CLESS_OK,COLOR_TIPE_CARD.TIPE_CLESS_KO,COLOR_TIPE_CARD.TIPE_KEY_OK,COLOR_TIPE_CARD.TIPE_KEY_KO],
          
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      }, 
      yaxis: {
        labels: {
          formatter: (val:any) => {
            return val;
          }
        }
      },
      xaxis: {
        categories: Array.from(this.hashOperation.keys())
      }
    };
  }

  constructorReader() {

    let seriesTemp_TIPE_BAND_OK:any=[]
    let seriesTemp_TIPE_BAND_KO:any=[]
    let seriesTemp_TIPE_CHIP_OK:any=[]
    let seriesTemp_TIPE_CHIP_KO:any=[]
    let seriesTemp_TIPE_CLESS_OK:any=[]
    let seriesTemp_TIPE_CLESS_KO:any=[]

    for (let [_, valueLectura] of this.hashLecturas) {
      
      let temp = valueLectura

        seriesTemp_TIPE_BAND_OK.push(temp.get(TIPE_CARD.TIPE_BAND_OK));
        seriesTemp_TIPE_BAND_KO.push(temp.get(TIPE_CARD.TIPE_BAND_KO));
        seriesTemp_TIPE_CHIP_OK.push(temp.get(TIPE_CARD.TIPE_CHIP_OK));
        seriesTemp_TIPE_CHIP_KO.push(temp.get(TIPE_CARD.TIPE_CHIP_KO));
        seriesTemp_TIPE_CLESS_OK.push(temp.get(TIPE_CARD.TIPE_CLESS_OK));
        seriesTemp_TIPE_CLESS_KO.push(temp.get(TIPE_CARD.TIPE_CLESS_KO));

    }

   

    this.chartOptions_reader = {
      series: [
        {
          name: this.translate.instant(`stadisticsFunctionally.readers.${TIPE_CARD.TIPE_BAND_OK}`),
          group: "1",
          color:"rgb(0, 143, 251)",
          data: seriesTemp_TIPE_BAND_OK
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.readers.${TIPE_CARD.TIPE_BAND_KO}`),
          group: "1",
          color: COLOR_TIPE_CARD.TIPE_BAND_KO,
          data: seriesTemp_TIPE_BAND_KO
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.readers.${TIPE_CARD.TIPE_CHIP_OK}`),
          group: "2",
          color: COLOR_TIPE_CARD.TIPE_CHIP_OK,
          data: seriesTemp_TIPE_CHIP_OK
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.readers.${TIPE_CARD.TIPE_CHIP_KO}`),
          group: "2",
          color: COLOR_TIPE_CARD.TIPE_CHIP_KO,
          data: seriesTemp_TIPE_CHIP_KO
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.readers.${TIPE_CARD.TIPE_CLESS_OK}`),
          group: "3",
          color: COLOR_TIPE_CARD.TIPE_CLESS_OK,
          data: seriesTemp_TIPE_CLESS_OK
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.readers.${TIPE_CARD.TIPE_CLESS_KO}`),
          group: "3",
          color: COLOR_TIPE_CARD.TIPE_CLESS_KO,
          data: seriesTemp_TIPE_CLESS_KO
        },
      ],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        locales:[this.chartLocale],
        defaultLocale:this.defaultLocale
      },
      stroke: {
        width: 2,
            },
      dataLabels: {
        enabled:false
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        categories: Array.from(this.hashLecturas.keys())
      },
      fill: {
        opacity: 1
      },
      colors: [COLOR_TIPE_CARD.TIPE_BAND_OK,COLOR_TIPE_CARD.TIPE_BAND_KO,COLOR_TIPE_CARD.TIPE_CHIP_OK,COLOR_TIPE_CARD.TIPE_CHIP_KO,COLOR_TIPE_CARD.TIPE_CLESS_OK,COLOR_TIPE_CARD.TIPE_CLESS_KO],
      yaxis: {
        labels: {
          formatter: (val:any) => {
            if(val === Infinity) return this.translate.instant('common.infinity');
            return val;
          }
        }
      },
      legend: {
        position: "top",
        horizontalAlign: "left"
      }
    };
  }

  constructorHostComm(){

    let seriesTemp_COMM_OK:any=[]
    let seriesTemp_COMM_KO:any=[]
    let seriesTemp_SEC_COMM_OK:any=[]
    let seriesTemp_SEC_COMM_KO:any=[]

    for (let [key, valueComunicaciones] of this.hashComunication) {
      let temp = new Map<String, Number>()
        temp = valueComunicaciones
        seriesTemp_COMM_OK.push(temp.get(COMM_REPORT.COMM_OK));
        seriesTemp_COMM_KO.push(temp.get(COMM_REPORT.COMM_KO));
        seriesTemp_SEC_COMM_OK.push(temp.get(COMM_REPORT.SEC_COMM_OK));
        seriesTemp_SEC_COMM_KO.push(temp.get(COMM_REPORT.SEC_COMM_KO));
    }
    


    this.chartOptions_hostComm = {
      series: [
        {
          name: this.translate.instant(`stadisticsFunctionally.communications.${COMM_REPORT.COMM_OK}`),
          data: seriesTemp_COMM_OK
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.communications.${COMM_REPORT.SEC_COMM_OK}`),
          data: seriesTemp_SEC_COMM_OK
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.communications.${COMM_REPORT.COMM_KO}`),
          data: seriesTemp_COMM_KO
        },
        {
          name: this.translate.instant(`stadisticsFunctionally.communications.${COMM_REPORT.SEC_COMM_KO}`),
          data: seriesTemp_SEC_COMM_KO
        }
      ],
      chart: {
        type: "area",
        height: 350,
        stacked: true,    
        zoom: {
          enabled: false
        },
        locales:[this.chartLocale],
        defaultLocale:this.defaultLocale
      },
      xaxis: {
        categories: Array.from(this.hashComunication.keys())
      },
     // colors: ["#008FFB", "#00E396", "#CED4DC"],
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8
        }
      },
      legend: {
        position: "top",
        horizontalAlign: "left"
      }     
    };
  }

  constructorTicketPrinting() {
    this.chartOptions_ticketPinting = {
      series: Array.from(this.hashTicket.values()),
      chart: {
        height: 380,
        type: "pie",
        locales:[this.chartLocale],
        defaultLocale:this.defaultLocale
      },
      labels: Array.from(this.hashTicket.keys()).map((key)=> this.translate.instant(`stadisticsFunctionally.printedTickets.${key}`)),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  constructorAccepted() {
    this.chartOptions_accepted = {
      series: Array.from(this.hashOperationOK.values()),
      chart: {
        height: 380,
        type: "pie",
        locales:[this.chartLocale],
        defaultLocale:this.defaultLocale
      },
      labels: Array.from(this.hashOperationOK.keys()).map((key)=> this.translate.instant(`stadisticsFunctionally.readers.${key}`)),
      colors: [COLOR_TIPE_CARD.TIPE_CHIP_OK,COLOR_TIPE_CARD.TIPE_BAND_OK,COLOR_TIPE_CARD.TIPE_CLESS_OK,COLOR_TIPE_CARD.TIPE_KEY_OK],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }


  get defaultLocale(){
    return 'es'
  }

  get chartLocale(){
    return  {
        name: 'es',
        options: {
          toolbar: {
            donwload:this.translate.instant('common.downloadSVG'),
            exportToSVG: this.translate.instant('common.downloadSVG'),
            exportToPNG: this.translate.instant('common.downloadPNG'),
            exportToCSV: this.translate.instant('common.downloadCSV'),
          }
        }
    };
  }

}




