<div mat-dialog-title class="ct-dialog-header">
    <span class="header-large">{{ 'profiles.terminals' | translate }}</span>
    
    <button class="ct-button ct-button--tertiary" (click)="onClose()">
        <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
    </button>  
</div>


<mat-dialog-content class="ct-dialog-content">
    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>
    
    <div class="table-containter">
        <div class="related-machines">
            <div id="tableDescription" style="display:none;">{{ 'vendingMachine.tableDescription' | translate }}</div>
            <table class="table related-machines-table" aria-labelledby="tableDescription">
                <thead class="related-machines-thead">
                    <tr>
                        <th scope="col" class="expanded-column">{{ 'vendingMachine.serialNumber' | translate }}</th>
                        <th scope="col">{{ 'vendingMachine.manufacID' | translate }}</th> 
                        <th scope="col">{{ 'vendingMachine.modelNumber' | translate }}</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody class="related-machines-tbody">
                    <tr *ngFor="let vm of machines">
                        <td class="related-machines-cells expanded-column">{{ vm.serialNumber }}</td>
                        <td class="related-machines-cells">{{ vm.manufacID }}</td> 
                        <td class="related-machines-cells">{{ vm.modelNumber }}</td>
                        <td class="related-machines-cells">
                            <ng-container *ngIf="rol.canByInput(rol.functIds.delete_profiles)">
                                <button class="ct-button ct-button--tertiary" (click)="delete(vm)">
                                    <mat-icon class="ct-icon red" svgIcon="trash-01"></mat-icon>
                                </button>  
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <!-- Empty state -->
            <ng-container *ngIf="!machines.length">
                <app-table-empty-state [icon]="false"></app-table-empty-state>
            </ng-container>

            <!-- Paginador -->
            <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>
    </div>
</mat-dialog-content>


<mat-dialog-actions align="end" class="botonera">
    <button class="ct-button ct-button--secondary" (click)="onClose()">
        {{ 'common.cancel' | translate }}
    </button>

    <button class="ct-button ct-button--primary" (click)="submitChanges()">
        {{ 'common.submit' | translate }}
    </button>
</mat-dialog-actions>