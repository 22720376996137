import { FilterAndPagBean } from "./FilterAndPagBean";

export class StadisticBean {
	idVendingMachine: number | undefined;
	provincia: string | undefined;
	dateIni!:Date;
	dateEnd!:Date;
	typeReport: number | undefined;
	idOperator: number | undefined;
	idClient: number | undefined;
	idSubClient?: number;
	commerce: string | undefined;
	idGroup!: number;
	lastId: number | undefined;
	idCategory: number | undefined;
	idSubcategory: number | undefined;
	customId: string | undefined;
	statusVend: number | undefined;
	idEntorno: number | undefined;
	resolutionHour!: boolean;
	
	filterAndPagBean!:FilterAndPagBean;

}

export class StadisticBeanVM extends StadisticBean {
	idVM: number | undefined;
}


