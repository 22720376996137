import { Injectable } from '@angular/core';
import JSZip from 'jszip';
import { ApkInfo } from '../models/apkInfo.model';
import { AppsService } from '../pages/views/config/aplications/aplications.service';

@Injectable({
  providedIn: 'root',
})
export class ApkUtilsService {
  constructor(private readonly service: AppsService) {}

 
  async processApk(file: File): Promise<ApkInfo> {
    try {
      const zip = new JSZip();
      const content = await zip.loadAsync(file);

      const manifestFile = content.file('AndroidManifest.xml');
      if (!manifestFile) {
        throw new Error('No se encontró AndroidManifest.xml en el APK.');
      }

      const manifestData = await manifestFile.async('uint8array');

      const manifestBlob = new Blob([manifestData], { type: 'application/xml' });
      const manifestAsFile = new File([manifestBlob], 'AndroidManifest.xml', {
        type: 'application/xml',
      });

      const response = await this.service.getInfoApk(manifestAsFile);

      return response?.data as ApkInfo;
    } catch (error) {
      console.error('Error al procesar el APK:', error);
      throw error;
    }
  }
}
