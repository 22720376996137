import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { NotifierService } from 'angular-notifier';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Aplications } from 'src/app/models/aplications.model';
import { AppsService } from '../aplications.service';
import { FormCreateActions } from 'src/app/util/constants';
import { FirmwareUpload } from 'src/app/models/firmwareUpload.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { Tag } from '../../tags/tag.model';
import { Factories, TerminalModel } from 'src/app/models/factories.model';
import { FactoriesService } from 'src/app/services/factories.service';
import { CommonModule } from '@angular/common';
import { FilterableSelectComponent } from 'src/app/components/filterableSelect/filterableSelect.component';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { TagsSelectorComponent } from 'src/app/components/tags-selector/tags-selector.component';
import { HierarchySelectorComponent } from 'src/app/components/hierarchy-selector/hierarchy-selector.component';
import { MatStepperModule } from '@angular/material/stepper';
import { InputFilesComponent } from 'src/app/components/input-files/input-files.component';
import { FileDisplayComponent } from 'src/app/components/file-display/file-display.component';
import { MatIconModule } from '@angular/material/icon';
import { ApkInfo } from 'src/app/models/apkInfo.model';
import { extractVersion, isApkFile } from 'src/app/util/util';
import { ApkUtilsService } from 'src/app/util/apkUtilsService';

@Component({
  standalone:true,
  imports:[
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyInputModule,
    MatLegacyFormFieldModule,
    MatIconModule,
    MatLegacyDialogModule,
    MatStepperModule,
    TagsSelectorComponent,
    HierarchySelectorComponent,
    FilterableSelectComponent,
    InputFilesComponent,
    FileDisplayComponent
  ],
  selector: 'app-new-app',
  templateUrl: './new-app.component.html',
  styleUrls: ['./new-app.component.css']
})
export class NewAppComponent implements OnInit {
  isLinear = true;
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  selectedFile: File | null = null; isEdit: boolean = false;
  idUser: number | undefined = undefined;
  uploadProgress = 0;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  isCloseParam: boolean = false;
  dialogNewParam: any;
  fileUploadError: boolean = false;
  apkInfo: ApkInfo = {
    packageName: '',
    version: '',
  };


  factories: Factories = new Factories;
  terminalModels: TerminalModel[] = [];
  
  @ViewChild('manuFacSelect') manuFacSelect?:FilterableSelectComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogNewApp: MatDialogRef<NewAppComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private service: AppsService,
    private translate: TranslateService,
    private factoriesService: FactoriesService,
    private apkUtilService: ApkUtilsService,
    public hierarchy: HierarchyHandlerService,
  ) { }

  private async loadFactoriesData() {
    const response = await this.factoriesService.getFactoriesList();
    if (response && response.data) {
      this.factories.list = response.data;
     }
  }

  async ngOnInit(): Promise<void> {
    this.loadFactoriesData();
    this.firstFormGroup = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(45)])],
      description: ['', Validators.compose([Validators.maxLength(200)])],
      tags: [[]],
      hierarchy: [{ idOperator: this.hierarchy.level_1, idClient: this.hierarchy.level_2, idSubClient: this.hierarchy.level_3 }],
      manufacID: [null, Validators.required],
      modelNumber: [null]
    });

    this.secondFormGroup = this.formBuilder.group({
      uploadFiles: [null, Validators.required], // Requerir archivo
      //fileBase64: ['', Validators.required],
      packageName: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.maxLength(100)])],
      version: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.maxLength(45)])]
    });

    this.firstFormGroup.get('manufacID')?.valueChanges.subscribe((value)=>{
      this.firstFormGroup.patchValue({ 'modelNumber':null })
      if(value){
        this.terminalModels = this.factories ? this.factories.getModelList(value) : [];
      } else {
        this.terminalModels = [];
      }
    });
  }

  validateInput(){
    this.manuFacSelect?.updateValidity();
  }

  private async mapFileToForm(file: File) {

    if (file.size === 0) {
      this.notifier.notify('warning', this.translate.instant('error.fileEmpty'));
      return;
    }

    this.selectedFile = file;
    let isApk = isApkFile(file);
    if (isApk) {
      this.apkInfo = await this.apkUtilService.processApk(file);
    } else {
      this.apkInfo.version = extractVersion(file.name)
    }

    this.secondFormGroup.patchValue({
      uploadFiles: file,
      version: this.apkInfo.version,
      packageName: this.apkInfo.packageName
    });

    if (isApk) {
      this.secondFormGroup.get('version')?.disable();
      this.secondFormGroup.get('packageName')?.disable();
    } else {
      this.secondFormGroup.get('version')?.enable();
      this.secondFormGroup.get('packageName')?.enable();
    }

    this.secondFormGroup.get('uploadFiles')?.markAsTouched();
  }

  async onFileSelected(files: File[]): Promise<void> {
    if (files.length > 0) {
      const file = files[0];
      this.mapFileToForm(file);
    }
  }


  deleteFile(): void {
    this.selectedFile = null; // Eliminar archivo seleccionado
    this.secondFormGroup.get('uploadFiles')?.reset();
    this.secondFormGroup.get('version')?.reset();
    this.secondFormGroup.get('version')?.disable();
    this.secondFormGroup.get('packageName')?.reset();
    this.secondFormGroup.get('packageName')?.disable();
    this.apkInfo = {
      packageName: '',
      version: '',
    };

    this.fileUploadError = true; // Mostrar error si no hay archivo
  }

  async onFormSubmit(): Promise<void> {
    if (this.firstFormGroup.invalid || this.secondFormGroup.invalid || !this.selectedFile) {
      this.fileUploadError = !this.selectedFile; // Si no hay archivo, mostrar el error
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }

    const formData = {
      ...this.firstFormGroup.getRawValue(),
      ...this.secondFormGroup.value,
      selectedFile: this.selectedFile?.name,
      fileBase64: this.secondFormGroup.get('fileBase64')?.value,
      version: this.secondFormGroup.get('version')?.value,
      packageName: this.secondFormGroup.get('packageName')?.value
    };

    let objNew: FirmwareUpload = new FirmwareUpload();
    let lstAppNew: Aplications[] = [];
    let appNew: Aplications = new Aplications();

    appNew.name = formData.name;
    appNew.description = formData.description;
    appNew.fileName = formData.selectedFile;
    appNew.pakage = formData.packageName;
    appNew.version = formData.version;
    appNew.base64 = formData.fileBase64;
    appNew.listEtiquetas = formData.tags.length ? formData.tags.map((tag: Tag) => ({ id: tag.id })) : [];

    appNew.manufacID = formData.manufacID;
    appNew.modelNumber = formData.modelNumber;

    const { idOperator, idClient, idSubClient } = formData.hierarchy;
    objNew.idOperator = idOperator;
    objNew.idClient = idClient;
    objNew.idSubClient = idSubClient;

    lstAppNew[0] = appNew;
    objNew.fileList = lstAppNew;


    const dialogData = new ConfirmDialogData();

    dialogData.element = Element.application;
    dialogData.action = ElementAction.create;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const response = await this.service.addUpdateWithFile(objNew, formData.uploadFiles);
        if (response && response.status >= 0) {
          this.dialogNewApp.close(FormCreateActions.SAVED);
          if (response.status === 0) this.notifier.notify('success', this.translate.instant('success.appCreated'));
          if (response.status === 1) this.notifier.notify('success', this.translate.instant('success.versionSaved'));
        }
      }
    });
  }

  onClose(): void {
    this.dialogNewApp.close(FormCreateActions.EXIT);
  }

}
