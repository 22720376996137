import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ComponentsStateService } from '../services/components-state.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  
  constructor(private router: Router, 
    private authService: AuthService, 
    private components:ComponentsStateService) {}
  
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 || err.status === 400) {
          this.authService.logoutAndClear();
          this.router.navigateByUrl('/pages-login');
          this.components.clearData()
        }
        
        return throwError(()=> err);
    }));
  }
}
