
<div mat-dialog-title class="ct-dialog-header">
    <span class="header-large">{{ 'vendingMachine.selector' | translate }}</span>
    
    <button class="ct-button ct-button--tertiary" (click)="onClose()">
        <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
    </button>   
</div>

<mat-dialog-content class="ct-dialog-content">
    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>
        
    <app-custos-dual-list [sort]="keepSorted" [source]="source" [key]="key" [display]="display" [filter]="filter"
    [destination]="confirmed" height="265px" [format]="format" [disabled]="disabled"></app-custos-dual-list>
</mat-dialog-content>

<mat-dialog-actions align="end" class="botonera">
    <button  class="ct-button ct-button--secondary" (click)="onClose()">{{ 'common.cancel' | translate }}</button>
    <button  class="ct-button ct-button--primary" (click)="onFormSubmit()">{{ 'common.confirm' | translate }}</button> 
</mat-dialog-actions>
    
      