import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { TerminalService } from './info-terminal/terminal.service';
import { EventsService } from 'src/app/services/events.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit, OnDestroy {
  selectedTabIndex: number = 0; // o el índice que necesites
  terminal: VendingMachineAtento = new VendingMachineAtento;
  eventsSubscription?: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { terminalId:number }, 
    private dialogRef: MatLegacyDialogRef<TabsComponent>,
    private service:TerminalService, 
    private eventsHandler:EventsService,
    public rol:RolHandlerService,
    public translate: TranslateService,
  ){}

  ngOnInit(): void {    
    this.selectedTabIndex = 0; 
    this.loadTerminal()

    this.eventsSubscription = this.eventsHandler.terminalDetailChange$.subscribe((terminalId:(number|null))=>{
      if(this.data?.terminalId === terminalId) this.loadTerminal();
    });
  }

  ngOnDestroy(): void {
    this.eventsSubscription?.unsubscribe();
  }

  changeTab(event: any) {
    console.log('Tab seleccionado:', event);
  }

  closeScreen(): void {
    this.dialogRef.close();
  }

  private async loadTerminal(){
    const { terminalId } = this.data;
    if(!terminalId) return;

    const response =  await this.service.findById(terminalId);
    if(response && response.data){
      this.terminal = new VendingMachineAtento(response.data);
    }
  }
}
