import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { TranslateModule } from "@ngx-translate/core";
import { Parameters } from "src/app/models/parameters.model";
import { PerfilApp } from "src/app/models/perfilApp.model";
import { Profile } from "src/app/models/profiles.model";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from "src/app/components/confirm-dialog/confirm-dialog.model";
import { PerfilesService } from "../perfiles.service";
import { HierarchyInput } from "src/app/components/hierarchy-selector/hierarchy-selector.component";
import { AppsSelectorComponent } from "../apps-selector/apps-selector.component";
import { MatIconModule } from "@angular/material/icon";

@Component({
  standalone:true,
  imports:[CommonModule, 
    TranslateModule, 
    MatIconModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    MatLegacyDialogModule,
    AppsSelectorComponent
  ],
  selector: 'app-edit-apps',
  templateUrl: './edit-apps.component.html',
  styleUrls: ['./edit-apps.component.css']
})
export class EditAppsComponent {
  public resultListApp:PerfilApp[] = [];
  public parameters: Parameters[] = [];
  public appsBBDD:any[] = [];
  public versionBBDD:any[] = [];
  public hierarchy: (HierarchyInput | null) = null; 
  public selectedPerfilApp: (PerfilApp | null) = null;
  public profile?:Profile;

  constructor(public dialogRef: MatDialogRef<EditAppsComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { profile:Profile }, 
    private dialog:MatLegacyDialog,
    private profileService:PerfilesService
  ){
    this.profile = {...this.data.profile};
    this.hierarchy = { 
      idOperator: this.profile?.idOperator, 
      idClient: this.profile?.idClient, 
      idSubClient: this.profile?.idSubClient 
    };
  }

  public onClose(){
    this.dialogRef.close();
  }

  public submitChanges(){
    const dialogData = new ConfirmDialogData();
    dialogData.enableCancel = false;
    dialogData.element = Element.profile; 
    dialogData.action = ElementAction.udpate;
    dialogData.class = DialogClass.info;  
    dialogData.icon = 'save-01';

    const confirmSave = this.dialog.open(ConfirmDialogComponent, {
      width: '35%', panelClass: 'custom-modalbox',
      data:dialogData
    });

    confirmSave.afterClosed().subscribe((response:ConfirmDialogActions) => {
      if (response === ConfirmDialogActions.CONFIRM){
       this.addPerfilApps();
      }
    });
  }

  private mapToPerfilAppFromSelected(resultListApp:PerfilApp[]): PerfilApp[]{
    return resultListApp.map((app)=> app.mapToServer());
  }

  public async addPerfilApps(){
    const profile = { ...this.data.profile };
    profile.listApp = [ ...profile.listApp ?? [], ...this.mapToPerfilAppFromSelected(this.resultListApp) ]
    profile.listApp.forEach((item,index)=> item.orden = index);
    const response = await this.profileService.saveApps(profile);
    if(response!.status >= 0 ) this.dialogRef.close({ result:1 });
  }

}
