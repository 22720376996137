import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TriggerComponent } from './trigger/trigger.component';
import { FormComponent } from './form/form.component';
import { DisplayComponent } from './display/display.component';
import { AppliedFilter, FilterField, FilterFormData, FilterOperator, OPERATORS } from 'src/app/models/filter.model';
import { Dialog } from '@angular/cdk/dialog';
import { Overlay, PositionStrategy } from '@angular/cdk/overlay';


@Component({
  standalone:true,
  imports:[
    CommonModule, 
    TriggerComponent,
    DisplayComponent
  ],
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent{
  @Input() fields:FilterField[] = [];
  @Input() multiple:boolean = true;
  @Output() onApply = new EventEmitter();

  private readonly offsetMargin = 10;
  private operators:FilterOperator[] = OPERATORS;
  public appliedFilters: AppliedFilter[] = [];
  
  @ViewChild('filter',{static:false}) filterElem: ElementRef | undefined;

  constructor(private dialog:Dialog, private overlay:Overlay){}

  public triggerDropDown(trigger:ElementRef){
    const positionStrategy = this.getPositionStrategy(trigger);
    const dialogRef = this.dialog.open(FormComponent,{
        data: new FilterFormData(this.fields,this.operators),
        id:"filter-dialog",
        panelClass:"filter-dialog",
        hasBackdrop: true,
        backdropClass:"backdrop-clean", 
        width: "350px",
        maxHeight: this.getMaxHeight(trigger),
        positionStrategy: positionStrategy
      })

    dialogRef.closed.subscribe((filter:any)=>{
      if(filter) this.addFilter(filter as AppliedFilter);
    })
  }

  public addFilter(filter:AppliedFilter){
    if(this.multiple) {
      this.appliedFilters.push(filter);
    } else {
      this.appliedFilters = [filter];
    }
    this.onApply.emit(this.appliedFilters);
  }

  public removeFilter(index:number){
    this.appliedFilters.splice(index, 1);
    this.onApply.emit(this.appliedFilters);
  }

  private getPositionStrategy(trigger:ElementRef):PositionStrategy{
    const triggerPosition = trigger.nativeElement.getBoundingClientRect();
    return this.overlay.position()
      .global()
      .start(`${(triggerPosition.left)}px`)
      .top(`${triggerPosition.top + triggerPosition.height + this.offsetMargin}px`);
  }

  private getMaxHeight(trigger:ElementRef){
    const windowHeight = window.innerHeight;
    const triggerPosition = trigger.nativeElement.getBoundingClientRect();
    const topPosition = triggerPosition.top + triggerPosition.height + this.offsetMargin
    return `${(windowHeight - topPosition)}px`; 

  }
}
