export interface TerminalModel {
    id:number;
    name:string;
    idFabricante:number;
}

export interface Factory {
    id:number;
    name:string;
    listModelos: TerminalModel[]
}

export class Factories {
    _list:Factory[] = [];

    constructor(){
    }

    public set list(list:Factory[]){
        this._list = list;
    }

    public get list(): Factory[] {
        return this._list;
    }

    public getModelList(factoryName:string): TerminalModel[] {
        const factory = this._list.find((item)=> (item.name === factoryName.toUpperCase()));
        return factory ? factory.listModelos : [];
    }

    private isAValidName(name:string, compareName:string){
        return (name.toUpperCase() === compareName.toUpperCase());
    }

    private isValidModelName(factory:Factory, modelName:string){
        return factory.listModelos.some((item)=> this.isAValidName(modelName, item.name));
    }

    public isAValidFactoryAndModel(factoryName:string, modelName:string){
        return this._list.some((item)=> (this.isAValidName(factoryName, item.name) && this.isValidModelName(item, modelName)));
    }

    public getDataForExampleFile(): string {
        let data:string = '';
        this._list.forEach((factory, facIdx)=>{
            factory.listModelos.forEach((model,modIdx)=>{
              const exampleSerialNumber = `xxx-${'00'+facIdx}-${'00'+modIdx}`
              const exampleRow = [exampleSerialNumber, factory.name, model.name];
              data = data.concat("\r\n"+exampleRow.toString().replace(/,/g,';'));
            })
        });
        return data;
    }

}