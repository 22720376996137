<main id="main" class="main">
  <div class="pagetitle">
    <span class="header-large">{{ 'sensors.title' | translate }}</span>
  </div>

  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div class="row w-100">
    <div class="col">
      <span class="header-medium">{{ 'sensors.consum' | translate }}</span>
      <div id="consumeChart" *ngIf="consumeList.length">
        <app-consume-chart [list]="consumeList"></app-consume-chart>
      </div>
      <div class="charts-empty-state" *ngIf="!consumeList.length">
        <p>{{ 'emptyStates.charts' | translate }}</p>
      </div>
    </div>

    <div class="col">
      <span class="header-medium">{{ 'sensors.coverage' | translate }}</span>
      <div id="covarageChart" *ngIf="coverageList.length">
        <app-coverage-chart [list]="coverageList"></app-coverage-chart>
      </div>
      <div class="charts-empty-state" *ngIf="!coverageList.length">
        <p>{{ 'emptyStates.charts' | translate }}</p>
      </div>
    </div>
  </div>
</main>