import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { CommonService } from 'src/app/services/common.service';
import { Operator } from 'src/app/models/operator.model';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TranslateService } from '@ngx-translate/core';
import { TxtJerarquiaService } from './txtJerarquia.service';

enum Columns {
  name = 'name',
  es = 'es',
  en = 'en',
  view = 'view'
}
@Component({
  selector: 'app-texto-jerarquia',
  templateUrl: './texto-jerarquia.component.html',
  styleUrls: ['./texto-jerarquia.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]

})
export class TextoJerarquiaComponent implements OnInit, AfterViewInit{
  displayedColumns =  Object.keys(Columns);
  dataSource = new MatTableDataSource<Operator>();  
  exportFileName: string | undefined;

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId:string | null = Columns.name+"" ;
  direccion: string | null = "ASC";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  filterList: FilterableValue[] = [];

  constructor(public dialog: MatDialog, 
    public servicios:TxtJerarquiaService,
    public commonService : CommonService,
    public translate:TranslateService
  ) {     
  }

  async ngOnInit(): Promise<void> {    
    this.lanzarLLamada();
    this.exportFileName = this.translate.instant('export.fileOperator');

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async lanzarLLamada(){
   /* const request = this.getFilterAndPagBean();
    const value = await this.servicios.find(request);
    if(value){
      this.dataSource = new MatTableDataSource<Operator>(value.data);
      this.pageLength = value.data.length ?? 0;
    }*/
  }
  private getFilterAndPagBean():FilterAndPagBean{
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;
    return new FilterAndPagBean(
        this.direccion, 
        this.sortId, 
        this.filterList, 
        startPage,
        endPage,
        pageIndex
      );
  }


 onEdit(row:Operator){
    /*const dialogRef = this.dialog.open(FormEditOperatorComponent, {
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel' ,
      data: { operatorId : row.id }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });*/
  }
  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_",".")
    this.sortId=active;
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  getNotExportColumns(){
    const actionsColIdx = (this.displayedColumns.length-1);
    return [actionsColIdx];
  }
}
