<div mat-dialog-title class="ct-dialog-header">
  <span class="header-large">{{ 'subClient.new' | translate }}</span>
  
  <button class="ct-button ct-button--tertiary" (click)="onClose()">
    <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
  </button>
</div>

<form [formGroup]="registerForm">
  <mat-dialog-content class="ct-dialog-content">
    <section class="section">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'subClient.name' | translate }}<span class="obligatorio"></span></mat-label>
                <input matInput class="form-field" formControlName="name" type="text" required>
                <mat-error *ngIf="registerForm.get('name')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                <mat-error *ngIf="registerForm.get('name')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'subClient.cod' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="cod" type="text">
                <mat-error *ngIf="registerForm.get('cod')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- Dropdown para seleccionar operadores, visible solo para ADMIN -->
        <div class="row" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_1)">
          <div class="col-12">
            <div class="form-group">
              <filterable-select #OperatorSelect formControlName="idOperator" [options]="operators" 
                [title]="'subClient.operator' | translate" displayField="name" valueField="id" [required]="true"></filterable-select> 
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <filterable-select #ClientSelect formControlName="idClient" [options]="clients" 
                [title]="'subClient.client' | translate" displayField="name" valueField="id" [required]="true"></filterable-select> 
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'subClient.cif' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="cif" type="text">
                <mat-error *ngIf="registerForm.get('cif')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'subClient.localidad' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="localidad" type="text">
                <mat-error *ngIf="registerForm.get('localidad')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'subClient.codPostal' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="codPostal" type="text">
                <mat-error *ngIf="registerForm.get('codPostal')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'subClient.web' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="web" type="text">
                <mat-error *ngIf="registerForm.get('web')?.hasError('pattern')">{{ 'error.pattern' | translate }}</mat-error>
                <mat-error *ngIf="registerForm.get('web')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'subClient.email' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="email" type="email">
                <mat-error *ngIf="registerForm.get('email')?.hasError('pattern')">{{ 'error.email' | translate }}</mat-error>
                <mat-error *ngIf="registerForm.get('email')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'subClient.phone' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="phone" type="phone">
                <mat-icon matSuffix matTooltip="{{ 'info.phoneValidation' | translate }}" class="custom-icon-color">info</mat-icon>
                <mat-error *ngIf="registerForm.get('phone')?.hasError('pattern')">{{ 'error.pattern' | translate }}</mat-error>
                <mat-error *ngIf="registerForm.get('phone')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'subClient.personContact' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="personContact" type="text">
                <mat-error *ngIf="registerForm.get('personContact')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
    </section>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="botonera">
    <button class="ct-button ct-button--secondary" (click)="onClose()">{{ 'common.cancel' | translate }}</button>
    <ng-container *ngIf="rol.canByInput(rol.functIds.edit_subclients)">
      <button class="ct-button ct-button--primary" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
    </ng-container>
  </mat-dialog-actions>
</form>