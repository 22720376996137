import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApexOptions, NgApexchartsModule } from 'ng-apexcharts';
import { ChartType, StadisticDetail } from 'src/app/models/StadisticDetails.model';
import { chartUtilConstructor } from 'src/app/util/chartsutils';

@Component({
  standalone: true,
  imports: [CommonModule, NgApexchartsModule, TranslateModule],
  selector: 'app-stadistic-details-item',
  templateUrl: './stadistic-details-item.component.html',
  styleUrls: ['./stadistic-details-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush

})

export class StadisticDetailsItemComponent {
  @Input() packageName!: string;
  @Input() data!: StadisticDetail[];

  groupedDataByPackageAndType: Record<
    string,
    Record<string, { data: StadisticDetail[]; chartOptions: ApexOptions }>
  > = {};
  chartOptions: Record<string, Record<string, ApexOptions>> = {};


  constructor(private translate:TranslateService,
  ){

  }

  ngOnInit(): void {
    this.processData();
  }

  processData(): void {
    this.groupedDataByPackageAndType = this.data.reduce(
      (acc: Record<string, Record<string, { data: StadisticDetail[]; chartOptions: ApexOptions }>>, item: StadisticDetail) => {
        const packageName = this.packageName;
        if (!acc[packageName]) {
          acc[packageName] = {};
        }
        const type = item.type;
        if (!acc[packageName][type]) {
          acc[packageName][type] = { data: [], chartOptions: {} };
        }
        acc[packageName][type].data.push(item);
        return acc;
      },
      {}
    );
    this.parseGroupedData();
  }



  parseGroupedData(): void {
    Object.keys(this.groupedDataByPackageAndType).forEach((packageName) => {
      Object.keys(this.groupedDataByPackageAndType[packageName]).forEach((type) => {
        const parsedItems = this.groupedDataByPackageAndType[packageName][type].data.map((item) => {
          return {
            ...item,
            parsedData: JSON.parse(item.data),
          };
        });
        const options = this.createChartOptions(parsedItems, type, this.groupedDataByPackageAndType[packageName][type]?.data[0]?.chartType || ChartType.LINE );
        this.groupedDataByPackageAndType[packageName][type].chartOptions = options;
      });
    });

    console.log('Updated groupedDataByPackageAndType:', this.groupedDataByPackageAndType);
  }



  createChartOptions(dataItems: any[], title: string, type: string): ApexOptions {



    let options =  {
      chart: {
        type: (type === 'bar-horizontal' || type === 'bar-vertical') ? 'bar' : type.toLowerCase(),
        height: 350,
      },
      xaxis: {
        type: 'datetime',
        title: {
          text:this.translate.instant(`stadisticsDetails.date`)
        },
      },
      yaxis: {
        title: {
          text: this.translate.instant(`stadisticsDetails.quantity`) 
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
        },
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
        text: `${title}`,
        align: 'center',
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      fill: {
        type: 'solid'
      },
    } as ApexOptions;

    return chartUtilConstructor(type, dataItems, options);
  }

  redimensionar(id: string) {
    let big = document.getElementById(id);
    if (big) {
      let hasClase2 = big.classList.contains('graficaBig');

      if (hasClase2) {// si esta expandido lo contraemos

        for (let el of document.querySelectorAll<HTMLElement>('.grafica')) {
          el.style.display = 'block';
          el.classList.remove("graficaBig");
        }

        for (let el of document.querySelectorAll<HTMLElement>('.expand')) {
          el.style.display = 'block';
        }
        for (let el of document.querySelectorAll<HTMLElement>('.contract')) {
          el.style.display = 'none';
        }

      } else {
        //agrandamos la grafica
        for (let el of document.querySelectorAll<HTMLElement>('.grafica')) {
          el.style.display = 'none';
          el.classList.remove("graficaBig");
        }
        for (let el of document.querySelectorAll<HTMLElement>('.expand')) {
          el.style.display = 'none';
        }
        for (let el of document.querySelectorAll<HTMLElement>('.contract')) {
          el.style.display = 'block';
        }

        big.style.display = 'block';
        big.classList.add("graficaBig");
      }
    }

    if (id == "_reader") {
      // this.constructorReader() 
    }
  }



  getKeys(obj: Record<string, any>): string[] {
    return Object.keys(obj);
  }

//   line
// area
// bar: solo vertical
// pie: no 
// donut: no
// radialBar : no
// scatter : no
// bubble : no
// heatmap : no
// candlestick : no
// boxPlot: no
// radar: no
// polarArea: no
// rangeBar: no
// rangeArea: no
// treemap: no


}
