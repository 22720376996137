import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

@Component({
  selector: 'app-tabs-mantenance',
  templateUrl: './tabs-mantenance.component.html',
  styleUrls: ['./tabs-mantenance.component.css']
})
export class TabsMantenanceComponent implements OnInit {
  terminal?:VendingMachineAtento;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { terminalId:number },
    public monitoringService:MonitoringService,
    public rol:RolHandlerService
  ) {}

  tabIndex = 0 ;

  changeTab(event: { index: number; }){
    console.log(event.index)
    this.tabIndex = event.index;      
  }


  ngOnInit(): void {   
    this.getMachine(this.data.terminalId).then(response => {
      if(response) this.terminal = response.data;
    })
  }

  async getMachine(idVM:number) {
    return await this.monitoringService.getMachine(idVM);
  }

}

