import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NotifierService } from 'angular-notifier';
import { environment } from 'src/environments/environment';
import { FileDescription, FirmwareUpload } from 'src/app/models/firmwareUpload.model';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { UtilService } from 'src/app/services/util.service';
import { DATE_FORMATS, STORAGE_TYPES, SEND_FILE_TYPE,  } from 'src/app/util/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { convertFileToBase64, getDurationOnMinutes, parseDateToFormat } from 'src/app/util/util';
import { EventsService } from 'src/app/services/events.service';


@Component({
  selector: 'app-send-file',
  templateUrl: './send-file.component.html',
  styleUrls: ['./send-file.component.css'],
})
export class SendFileComponent implements OnInit {
  @Input('data') vendingMachine!:VendingMachineAtento;
  hasDefaultVendingMachine:boolean = false;
  selectedVendingMachines = new MatTableDataSource<VendingMachineAtento>();
  storageTypes = STORAGE_TYPES.map((type)=> ({value:type, label:this.translate.instant(`maintenace.storageType.${type}`) }));
  fileForm: FormGroup;
  sendFileTypes = [
    { value: SEND_FILE_TYPE.UNDEFINED, label: this.translate.instant('maintenace.fileTypes.undefined') },
    { value: SEND_FILE_TYPE.APLICATION, label: this.translate.instant('maintenace.fileTypes.aplication') },
    { value: SEND_FILE_TYPE.PARAMETER, label: this.translate.instant('maintenace.fileTypes.parameter') }
  ]

  fileList:FileDescription[] = [];

  constructor(
    private utilService:UtilService, 
    private notifier: NotifierService, 
    private formBuilder:FormBuilder,
    private translate: TranslateService,
    private dialog:MatLegacyDialog,
    private eventsHandler:EventsService,
    public rol:RolHandlerService
  ) {
    this.fileForm = this.formBuilder.group({
      initTime: [null],
      endTime: [null],
      typeFile:[SEND_FILE_TYPE.UNDEFINED, Validators.required],
      storageType: ['', Validators.required], 
      packageName: ['', Validators.compose([Validators.required,Validators.maxLength(100)])], 
      path: ['', Validators.compose([Validators.required,Validators.maxLength(100)])] 
    }); 

    this.fileForm.get('typeFile')?.valueChanges.subscribe((value)=>{
      if(value === SEND_FILE_TYPE.APLICATION){
        this.fileForm.get('storageType')?.disable();
        this.fileForm.get('packageName')?.disable();
        this.fileForm.get('path')?.disable();
        return;
      }

      if(value === SEND_FILE_TYPE.PARAMETER){
        this.fileForm.get('packageName')?.enable();
        this.fileForm.get('storageType')?.disable();
        this.fileForm.get('path')?.disable();
        return;
      }

      this.fileForm.get('storageType')?.enable();
      this.fileForm.get('packageName')?.enable();
      this.fileForm.get('path')?.enable();
    });
    
  }

  public ngOnInit(): void {
    if(this.vendingMachine){
      this.selectedVendingMachines.data.push(this.vendingMachine);
      this.hasDefaultVendingMachine = true
    }
  }

  async onFilesSelected(files:File[]){
    if(!files.length) return;
    await Promise.all(
      files.map( async (file) => {
        convertFileToBase64(file).then((value)=>{
          const fileDescription = new FileDescription;
          fileDescription.base64 = value as string;
          fileDescription.name = file.name;
          this.fileList.push(fileDescription);
        });
      })
    );
  }

  removeFile(idx:number){
    this.fileList.splice(idx, 1);
  }


  async onFormSubmit(): Promise<void> {
    if (!this.selectedVendingMachines.data.length) {
      this.notifier.notify('warning', this.translate.instant('warning.terminalIsMissing'));
      return;
    }

    if (this.fileList === undefined || this.fileList.length == 0) {
      this.notifier.notify('warning', this.translate.instant('warning.fileUploadValidation'));
      return;
    }
    
    if(this.fileForm.invalid){
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
    const fileUpload: FirmwareUpload = new FirmwareUpload();
  
    fileUpload.machines = this.selectedVendingMachines.data.map((vm) => {
      const vendingMachine: VendingMachineAtento = new VendingMachineAtento();
      vendingMachine.id = vm.id;
      return vendingMachine;
    });

    const { packageName, path, storageType, initTime, endTime, typeFile } = this.fileForm.value;
    fileUpload.fileList = this.fileList.map((file:FileDescription)=>{
      const fileToUpload = new FileDescription;
      fileToUpload.base64 = file.base64;
      fileToUpload.name = file.name;
      fileToUpload.pakage = packageName;
      fileToUpload.path = path;
      fileToUpload.storageType = storageType;
      fileToUpload.typeFile = typeFile;
      return fileToUpload;
    });
    if(initTime){
      fileUpload.horaIni = parseDateToFormat(initTime, DATE_FORMATS.terminal);
      fileUpload.duracion = getDurationOnMinutes(initTime, endTime).toString();
    }
    
    // Continuar con el resto de la lógica de envío del archivo
    fileUpload.cod = "1"; // Establecer el código en 1 para el archivo
    fileUpload.destination = environment.pathTempfile;

    this.sendFile(fileUpload);
  }
  

  sendFile(fileUpload:FirmwareUpload){
    const dialogData = new ConfirmDialogData(); 
    dialogData.element = Element.file;
    dialogData.action = ElementAction.send;
    dialogData.class = DialogClass.warning;
    dialogData.extraInfo = this.translate.instant("maintenace.extraInfo.sendFile");
    dialogData.icon = 'alert-triangle';
   
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    dialog.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {        
        const response = await this.utilService.sendFile(fileUpload);
        this.resetFormState();
        if (response!.status >= 0) {
          this.notifier.notify('success', this.translate.instant('success.fileSendedCreated'));
          this.sendEventToTerminal();
        }
      }
    });
  }

  private sendEventToTerminal(){
    if(this.vendingMachine?.id) {
      this.eventsHandler.updateTerminalDetail(this.vendingMachine.id);
    }
  }

  private resetFormState(){
    this.fileList = [];
    this.resetFormControlStates();
    if(!this.hasDefaultVendingMachine){
      this.selectedVendingMachines = new MatTableDataSource<VendingMachineAtento>();
    }
  }

  private resetFormControlStates(){
    this.fileForm.reset();
    setTimeout(()=>{
      Object.keys(this.fileForm.controls).forEach((key) => {
        const control = this.fileForm.controls[key];
        control.setErrors(null);
      });
    })
  }

}
