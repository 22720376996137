import { NgModule,CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { CommonModule, registerLocaleData } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/legacy-form-field';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NotifierModule } from 'angular-notifier';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { UsersProfileComponent } from './pages/users-profile/users-profile.component';
import { PagesRegisterComponent } from './pages/pages-register/pages-register.component';
import { PagesLoginComponent } from './pages/pages-login/pages-login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialExampleModule } from './material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AlertsComponent as AlertasComponent } from './pages/views/support/alerts/alerts.component';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { UsersComponent } from './pages/admin/users/users.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxNumericRangeFormFieldModule } from './components/numeric-range/ngx-numeric-range-form-field.module';

import { NgApexchartsModule } from 'ng-apexcharts';
import { AuthModule } from './auth/auth.module';
import { TopbarComponent } from './pages/welcome/topbar/topbar.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import {  AuthTmsRestUrl, ConsoleRest, NOTIFIER_OPTIONS } from './util/constants';
import { AUTH_TMS_URL, AUTH_URL, CONSOLE_REST_URL} from './auth/app.oauth-tokens';
import { UnauthorizedInterceptor } from './auth/unauthorized.interceptor';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { FormUsersComponent } from './pages/admin/users/form-users/form-users.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MonitoringComponent } from './pages/views/monitoring/monitoring.component';
import { InfoTerminalComponent } from './pages/views/monitoring/tabs/info-terminal/info-terminal.component';
import { TabsComponent } from './pages/views/monitoring/tabs/tabs.component';
import { PendingTasksComponent } from './pages/comun/pending-tasks/pending-tasks.component';
import { OperatorComponent } from './pages/admin/operator/operator.component';
import { ClientComponent } from './pages/admin/client/client.component';
import { FormNewClientComponent } from './pages/admin/client/form-new-client/form-new-client.component';
import { CustomAlertComponent } from './pages/views/config/custom-alert/custom-alert.component';
import { FormNewCustomAlertComponent } from './pages/views/config/custom-alert/form-new-custom-alert/form-new-custom-alert.component';
import { StadisticFunctionallyComponent } from './pages/views/stadistic/stadistic-functionally/stadistic-functionally.component';
import { StadisticStateComponent } from './pages/views/stadistic/stadistic-state/stadistic-state.component';
import { SelectMachineDetailComponent } from './pages/comun/select-machine-detail/select-machine-detail.component';
import { SelectMachineWindowComponent } from './pages/comun/select-machine-window/select-machine-window.component';
import { CustosDualListComponent } from './pages/comun/custos-dual-list/custos-dual-list.component';
import { DiasDesdeFechaPipe } from './pages/comun/pipes/dias-desde-fecha.pipe';
import { HierarchyLevelNamePipe } from './pages/comun/pipes/hierarcy-level-name.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VentasComponent } from './pages/views/monitoring/tabs/ventas/ventas.component';
import { GetFileComponent } from './pages/views/maintenance/get-file/get-file.component';
import { TabsMantenanceComponent } from './pages/views/maintenance/tabs-mantenance/tabs-mantenance.component';
import { SensoresComponent } from './pages/views/monitoring/tabs/sensores/sensores.component';
import { HistoryUserComponent } from './pages/views/maintenance/history-user/history-user.component';
import { MatTableModule } from '@angular/material/table'; 
import { ParamComponent } from './pages/views/config/param/param.component';
import { NewAppComponent } from './pages/views/config/aplications/new-app/new-app.component';
import { NewParamComponent } from './pages/views/config/param/new-param/new-param.component';
import { NewPerfilesComponent } from './pages/views/config/profile/new-perfiles/new-perfiles.component';
import { AplicationsComponent } from './pages/views/config/aplications/aplications.component';
import { PerfilesComponent } from './pages/views/config/profile/perfiles.component';
import { EditAppComponent } from './pages/views/config/aplications/edit-app/edit-app.component';
import { EditParamComponent } from './pages/views/config/param/edit-param/edit-param.component';
import { EditPerfilesComponent } from './pages/views/config/profile/edit-perfiles/edit-perfiles.component';
import { FechaPersonalPipe } from './pages/comun/pipes/custos-fecha.pipe';
import localeEs from '@angular/common/locales/es';
import { EnrollmentComponent } from './pages/views/config/profile/enrollment/enrollment.component';
import { AddTerminalComponent } from './pages/views/monitoring/add-terminal/add-terminal.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MatDialogModule } from '@angular/material/dialog';
import { FilterComponent } from './components/filter/filter.component';
import { OperationPipe } from './pages/comun/pipes/operation.pipe';
import { FormNewOperatorComponent } from './pages/admin/operator/form-new-operator/form-new-operator.component';
import { ListFilesComponent } from './pages/views/maintenance/list-files/list-files.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ListRelatedAppsComponent } from './pages/views/config/profile/list-related-apps/list-related-apps.component';
import { DateOnTimeZonePipe } from './pages/comun/pipes/date-on-timezone.pipe';
import { CoverageChartComponent } from './components/coverage-chart/coverage-chart.component';
import { ConsumeChartComponent } from './components/consume-chart/consume-chart.component';
import { MonitoringMapComponent } from './pages/views/monitoring/monitoring-map/monitoring-map.component';
import { SubClientComponent } from './pages/admin/sub-client/sub-client.component';
import { CommandsComponent } from './pages/views/maintenance/commands/commands.component';
import { TableEmptyStateComponent } from './components/table-empty-state/table-empty-state.component';
import { RolComponent } from './pages/admin/rol/rol.component';
import { FormNewRolComponent } from './pages/admin/rol/form-new-rol/form-new-rol.component';
import { FormEditRolComponent } from './pages/admin/rol/form-edit-rol/form-edit-rol.component';
import { InstalledAppsComponent } from './pages/views/monitoring/tabs/installed-apps/installed-apps.component';
import { FormEditClientComponent } from './pages/admin/client/form-edit-client/form-edit-client.component';
import { FormEditOperatorComponent } from './pages/admin/operator/form-edit-operator/form-edit-operator.component';
import { FormEditUsersComponent } from './pages/admin/users/form-edit-users/form-edit-users.component';
import { TagsSelectorComponent } from './components/tags-selector/tags-selector.component';
import { TagsDisplayComponent } from './components/tags-display/tags-display.component';
import { CoverageIconComponent } from './components/coverage-icon/coverage-icon.component';
import { HierarchySelectorComponent } from './components/hierarchy-selector/hierarchy-selector.component';
import { InputFilesComponent } from './components/input-files/input-files.component';
import { SendFileComponent } from './pages/views/maintenance/send-file/send-file.component';
import { MapComponent } from './components/map/map/map.component';
import { FileDisplayComponent } from './components/file-display/file-display.component';
import { DateFromTerminalPipe } from './pages/comun/pipes/date-from-terminal.pipe';
import { IncidencesComponent } from './pages/views/support/incidences/incidences.component';
import { FilterableSelectComponent } from './components/filterableSelect/filterableSelect.component';
import { StadisticDetailsComponent } from './pages/views/stadistic/stadistic-details/stadistic-details.component';
import { StadisticDetailsItemComponent } from './pages/views/stadistic/stadistic-details/components/stadistic-details-item/stadistic-details-item.component';
import { ComponentsStateService } from './services/components-state.service';
import { TxtJerarquiaService } from './pages/views/personalizacion/texto-jerarquia/txtJerarquia.service';
import { TextoGraficasComponent } from './pages/views/personalizacion/texto-graficas/texto-graficas.component';
import { TextoJerarquiaComponent } from './pages/views/personalizacion/texto-jerarquia/texto-jerarquia.component';
import { FormEditTxtJerComponent } from './pages/views/personalizacion/texto-jerarquia/form-edit-txt-jer/form-edit-txt-jer.component';
import { FormEditTxtGrafComponent } from './pages/views/personalizacion/texto-graficas/form-edit-txt-graf/form-edit-txt-graf.component';

registerLocaleData(localeEs)

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AplicationsComponent,
    FooterComponent,
    TopbarComponent,
    SidebarComponent,
    MonitoringComponent,
    InfoTerminalComponent,
    FormEditClientComponent,
    FormNewRolComponent,
    FormEditRolComponent,
    FormEditUsersComponent,
    TabsComponent,
    FormEditOperatorComponent,
    PendingTasksComponent,
    OperatorComponent,
    ClientComponent,
    FormNewClientComponent,
    FormNewOperatorComponent,
    SensoresComponent,
    HistoryUserComponent,
    AlertasComponent,
    VentasComponent,
    GetFileComponent,
    TextoJerarquiaComponent,
    FormEditTxtJerComponent,
    FormEditTxtGrafComponent,
    TextoGraficasComponent,
    PerfilesComponent,
    UsersComponent,
    CustomAlertComponent,
    FormNewCustomAlertComponent,
    StadisticFunctionallyComponent,
    StadisticStateComponent,
    EditAppComponent,
    EditParamComponent,
    EditPerfilesComponent,
    FormUsersComponent,  
    WelcomeComponent,
    TabsMantenanceComponent,
    ConfirmDialogComponent,
    UsersProfileComponent,
    PagesRegisterComponent,
    PagesLoginComponent,
    DiasDesdeFechaPipe,
    FechaPersonalPipe,
    ParamComponent,
    NewParamComponent,
    EnrollmentComponent,
    OperationPipe,
    SendFileComponent,
    [AppComponent]
  ],
  imports: [MatTableModule,    
    BrowserModule,
    NgApexchartsModule,
    QRCodeModule,
    BrowserModule,
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule, 
    MatListModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatNativeDateModule,
    MatTableExporterModule,
    MaterialExampleModule,
    NgxNumericRangeFormFieldModule,
    NgxSpinnerModule,
    TranslateModule,
    ToastrModule.forRoot(),
    FontAwesomeModule,
    NgApexchartsModule,
    AuthModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    NotifierModule.withConfig(NOTIFIER_OPTIONS),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    //standalone components
    FilterComponent,
    ListFilesComponent,
    ListRelatedAppsComponent,
    RolComponent,
    DateOnTimeZonePipe,
    StadisticDetailsItemComponent,
    CoverageChartComponent,
    ConsumeChartComponent,
    MonitoringMapComponent,
    SubClientComponent,
    CommandsComponent,
    CustosDualListComponent,
    SelectMachineWindowComponent,
    SelectMachineDetailComponent,
    TableEmptyStateComponent,
    NewPerfilesComponent,
    InstalledAppsComponent,
    TagsSelectorComponent,
    TagsDisplayComponent,
    CoverageIconComponent,
    HierarchySelectorComponent,
    HierarchyLevelNamePipe,
    InputFilesComponent,
    AddTerminalComponent,
    MapComponent,
    FileDisplayComponent,
    DateOnTimeZonePipe,
    DateFromTerminalPipe,
    NewAppComponent,
    IncidencesComponent,
    FilterableSelectComponent,
    StadisticDetailsComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es'},
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    { provide: AUTH_URL, useValue: AuthTmsRestUrl },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function(router: Router, authService: AuthService, componentsState:ComponentsStateService) {
        return new UnauthorizedInterceptor(router, authService, componentsState);
      },
      multi: true,
      deps: [Router, AuthService, ComponentsStateService]
  },
    { provide: CONSOLE_REST_URL, useValue: ConsoleRest },
    { provide: AUTH_TMS_URL, useValue: AuthTmsRestUrl },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
}
