import { inject, Pipe, PipeTransform } from '@angular/core';
import { DatesHandlerService } from 'src/app/services/dates-handler.service';

@Pipe({
  name: 'diasDesdeFecha'
})
export class DiasDesdeFechaPipe implements PipeTransform {
  private dateHandler = inject(DatesHandlerService);
  /**
   * @param value formato mm-dd-yyyy HH:MM:SS
   * @returns 
   */
  transform(value: string): unknown {
    if(!value) return "";
    return this.dateHandler.getDateToNow(value);
  }

}
