import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { Users } from 'src/app/models/users.model';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class UsersService {
  
  constructor(
    private merchantRest : RestService, private authService:AuthService,
    public commonService: CommonService) {
    }
    

  async find(_body:UsersConsoleRequest ){
    let userSession = this.authService.currentUserValue();
    if(userSession){
      _body.idEntorno=userSession.entorno?.id
      _body.idOperator=userSession.operator?.id
      _body.idClient=userSession.client?.id
      _body.idSubClient=userSession.subClient?.id
    }
    
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListUser/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }
  
  
  async countTotal(_body:UsersConsoleRequest){
    _body.filterAndPagBean= new FilterAndPagBean(_body.filterAndPagBean?.orderSentido,_body.filterAndPagBean?.order,_body.filterAndPagBean?.filterGeneric,null,null,null);
    let userSession = this.authService.currentUserValue();
    if(userSession){
      _body.idEntorno=userSession.entorno?.id
      _body.idOperator=userSession.operator?.id
      _body.idClient=userSession.client?.id
      _body.idSubClient=userSession.subClient?.id
    }
    
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'countListUser/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }
  
  async getItem(id:number){
    let value = await this.merchantRest.commonRestCallConsolaRest({ id },'getUser/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

  async addUpdate(_body:Users){
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'addUser/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

 
  async deleteUser(id:number|undefined){
    let value = await this.merchantRest.commonRestCallConsolaRest({ id },'deleteUser/', true, true)   
      .catch(err => {
        return null
      })
      
    return value;   
  }

  async blockUser(id:number|undefined){
    let value = await this.merchantRest.commonRestCallConsolaRest({ id },'bloqueoUser/', true, true)   
      .catch(err => {
        return null
      })
      
    return value;   
  }

  async unblockUser(id:number|undefined){
    let value = await this.merchantRest.commonRestCallConsolaRest({ id },'desbloqueoUser/', true, true)   
      .catch(err => {
        return null
      })
      
    return value;   
  }
 
  async resetUserPass(_body:Users){
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'resetUserPass/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

}