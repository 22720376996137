<main id="main" class="main">
    <div class="my-2 d-flex justify-content-between">
        <span class="header-large">{{ 'listFiles.title' | translate }}</span>   
        
        <button class="ct-button ct-button--secondary" (click)="refrescar()">
            <mat-icon class="ct-icon" svgIcon="refresh-ccw-01"></mat-icon> 
            <span class="hide-on-mobile">{{'common.refresh' | translate }}</span>
        </button>
    </div>
    
    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

    <div id="tableDescription" hidden>{{ 'listFiles.tableDescription' | translate }}</div>

    <div class="mat-elevation-z2 tablaPersonal">
        <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" 
            matSort (matSortChange)="announceSortChange($event)" 
            matTableExporter #exporter="matTableExporter" [hiddenColumns]="getNotExportColumns()">
            
            <!-- name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'listFiles.name' | translate }}</div></th>       
                <td mat-cell *matCellDef="let element" title="{{element.name}}"> {{element.name}} </td>
            </ng-container> 

             <!-- name Column -->
             <ng-container matColumnDef="pakage">
                <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'listFiles.pakage' | translate }}</div></th>       
                <td mat-cell *matCellDef="let element" title="{{element.pakage}}"> {{element.pakage}} </td>
            </ng-container> 

             <!-- name Column -->
             <ng-container matColumnDef="mode">
                <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'listFiles.mode' | translate }}</div></th>       
                <td mat-cell *matCellDef="let element" title="{{element.mode}}"> {{element.mode}} </td>
            </ng-container> 
            
            <!-- dateCreate Column -->
            <ng-container matColumnDef="dateCreate">
                <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'common.date' | translate }}</div></th>       
                <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
            </ng-container>
            
            <!-- action Column -->
            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef style="width: 90px;"></th>
                <td mat-cell *matCellDef="let element" class="button" style="width: 90px;">   
                    <div>
                        <button matTooltip="{{ 'tooltips.download' | translate }}" (click)="onDownload(element)" class="ct-button ct-button--icon">
                            <mat-icon>file_download</mat-icon>
                        </button>
                        
                        <ng-container *ngIf="rol.canByInput(rol.functIds.delete_file)">
                            <button  matTooltip="{{ 'tooltips.delete' | translate }}" (click)="onDelete(element)" class="ct-button ct-button--icon">
                                <mat-icon class="ct-icon red" svgIcon="trash-01"></mat-icon>
                             </button>
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- Empty state -->
        <ng-container *ngIf="!dataSource.data.length">
            <app-table-empty-state></app-table-empty-state>
        </ng-container>

        <button id="export" class="icoDownload" mat-icon-button 
            (click)="exporter.exportTable('xlsx', { fileName: exportFileName })">
            <i class="bi bi-file-earmark-arrow-down"></i>
        </button>

        <mat-paginator [length]="pageLength"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent()"
            showFirstLastButtons 
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</main>