
import { ConsoleRequest } from "./console.request.model";
import { PerfilApp } from "./perfilApp.model";
import { VendingMachineAtento } from "./vendingMachineAtento.model";

export class Profile {
	id?:number;
  name!:string; 
	dateCreate?: Date;
	dateUpdate?: Date;
	description!: string;
  cod!: string;
  numMaquinas!:number;
  enableLocation!:boolean;
  distanceLocation!:number;
  distanceUnit!:string;
  listVendingMachine?:VendingMachineAtento[];
  listApp:PerfilApp[] = [];
  idOperator?:number;
  idClient?:number;
  idSubClient?:number;
	manufacID!: string;

 constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class ListMachinesRequest extends ConsoleRequest {
  id?:number;  
}

export class ListParamExtraRequest extends ConsoleRequest {
  idPerfil?:number;
  idApp?:number;  
}
