<header id="header" class="header fixed-top d-flex align-items-center">
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center justify-content-between">
            <a routerLink="/welcome" class="logo d-flex align-items-center">
               <span class="img"></span> 
               <span class="d-none d-lg-block"><b>MDM</b> {{version}}</span> 
            </a> 
         </div>
    </div>
    <nav class="header-nav ms-auto">
      <ul class="botonera">
         <!--li class="nav-item dropdown d-none"> 
            <a class="ct-button ct-button--secondary" routerLinkActive="active-link" routerLink="signup">{{ 'login.SignUp' | translate }}</a>
         </li!-->
         <li class="nav-item dropdown"> 
            <button class="ct-button ct-button--primary"  routerLinkActive="active-link" routerLink="#" (click)="login()">{{ 'login.login' | translate }}</button>
         </li>
      </ul>
    </nav>
 </header>