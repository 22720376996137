import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common'
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import packageJson from '../../../../package.json';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { ComponentsStateService } from 'src/app/services/components-state.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent{
  public version: string = packageJson.version;

  constructor(@Inject(DOCUMENT) private document: Document,
    private _router: Router,
    private authService: AuthService,
    private components:ComponentsStateService,
    public hierarchy:HierarchyHandlerService
  ) {}

  @ViewChild('headLogoutForm', { read: ElementRef })
  headLogoutForm!: ElementRef; 


  sidebarToggle() {
    //toggle sidebar function
    this.document.body.classList.toggle('toggle-sidebar');
  }

  logout() { 
    this.authService.logoutAndClear();
    this.components.clearData();
    this._router.navigate(['/']);
  }

  get logoutBaseUrl(){
    return 'redlogout';
  }
  get redirect(){
    return window.location.origin + '/welcome';
  }

  public get username(){
    return this.authService.username;
  }
  public get fullName(){
    return this.authService.fullName;
  } 

  public get operator(){
    const userSession = this.authService.currentUserValue()
    return userSession?.operator;
  }

}
