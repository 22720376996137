import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TerminalsSelectorComponent } from '../../config/profile/terminals-selector/terminals-selector.component';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogModule, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { MonitoringService } from '../monitoring.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { NotifierService } from 'angular-notifier';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone:true,
  imports:[
    CommonModule,
    TranslateModule, 
    TerminalsSelectorComponent,
    MatIconModule,
    MatLegacyDialogModule
  ],
  selector: 'app-add-terminal',
  templateUrl: './add-terminal.component.html',
  styleUrls: ['./add-terminal.component.css']
})
export class AddTerminalComponent implements OnInit {
  terminals:VendingMachineAtento[] = [];
  
  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data:any,
    private dialogRef: MatLegacyDialogRef<AddTerminalComponent>,
    private dialog:MatLegacyDialog,
    private service:MonitoringService,
    private translate:TranslateService,
    private notifier:NotifierService
  ) {}

  ngOnInit(): void {}

  onClose(){
    this.dialogRef.close(ConfirmDialogActions.CANCEL)
  }

  async submitChanges(){
    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.terminal;
    dialogData.action = ElementAction.upload;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'share-01';


    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox', data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
          const response = await this.service.uploadFile(this.terminals);
          if(response && response?.data){
            const total = response.data.total ?? 0;
            this.notifier.notify('success', this.translate.instant('success.uploadedTerminal', { value: total}));
            this.dialogRef.close(ConfirmDialogActions.CONFIRM)
          } 
      }
    });
  }

}
