import { EventEmitter, Injectable } from '@angular/core';
import { NavigationSectionType } from '../util/constants';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class EventsService {    
  navigationChange$ = new BehaviorSubject<(NavigationSectionType|null)>(null);
  terminalDetailChange$ = new BehaviorSubject<(number|null)>(null);

  navigateTo(section:NavigationSectionType){
    this.navigationChange$.next(section);
  }

  updateTerminalDetail(terminalId:number){
    this.terminalDetailChange$.next(terminalId);
  }
}
