import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogModule, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Profile } from 'src/app/models/profiles.model';
import { ParamExtraFiles, PerfilApp } from 'src/app/models/perfilApp.model';
import { PerfilesService } from '../perfiles.service';
import { TranslateModule } from '@ngx-translate/core';
import { AppsService } from '../../aplications/aplications.service';
import { ParamService } from '../../param/param.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Aplications } from 'src/app/models/aplications.model';
import { Parameters } from 'src/app/models/parameters.model';
import { MatIconModule } from '@angular/material/icon';
import { convertFileToBase64, parseAndSetDateStart, parsedDateFromTerminalFormat } from 'src/app/util/util';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { FilterableValue, FilterAndPagBean, OPERATION_FILTER } from 'src/app/models/FilterAndPagBean';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';

@Component({
  selector: 'app-edit-profile-app',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    MatLegacySlideToggleModule,
    MatLegacyDialogModule
  ],
  templateUrl: './edit-profile-app.component.html',
  styleUrls: ['./edit-profile-app.component.css']
})
export class EditProfileAppComponent implements OnInit{
  public versionBBDD:Aplications[] = [];
  public parameters:Parameters[] = [];
  public selectedPerfilApp: (PerfilApp | null) = null;
  public perfilAppForm:FormGroup;

  constructor(public dialogRef: MatLegacyDialogRef<EditProfileAppComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { profile:Profile, selected:PerfilApp }, 
    private dialog:MatLegacyDialog,
    private profileService:PerfilesService,
    private appsService:AppsService,
    private paramsService:ParamService,
    private hierarchy:HierarchyHandlerService
  ){
    this.perfilAppForm = new FormGroup({
      idApp: new FormControl(null),
      idParam: new FormControl(null),
      enableDate: new FormControl(false),
      startDate: new FormControl(null),
      startTime: new FormControl(null),
      fileName: new FormControl(null),
      typeParamExtra: new FormControl(null),
      paramExtra: new FormControl(null),
    });

    this.perfilAppForm.valueChanges.subscribe((value)=>{
      if(value.enableDate){
        this.perfilAppForm.get('startDate')?.disable({emitEvent:false});
        this.perfilAppForm.get('startTime')?.disable({emitEvent:false});
      } else {
        this.perfilAppForm.get('startDate')?.enable({emitEvent:false});
        this.perfilAppForm.get('startTime')?.enable({emitEvent:false}); 
      }
    });
  }

  public async ngOnInit(): Promise<void>{
    this.selectedPerfilApp = new PerfilApp;
    this.selectedPerfilApp.mapToEdit(this.data.selected);

    this.loadVersions();
    this.loadParameters();
    this.updateForm();
  }

  private updateForm(){
    if(!this.selectedPerfilApp) return;

    const { date, hour } = parsedDateFromTerminalFormat(this.selectedPerfilApp.dateStart);
    this.perfilAppForm.patchValue({
      idApp: this.selectedPerfilApp.idApp,
      idParam: this.selectedPerfilApp.idParam,
      enableDate: !this.selectedPerfilApp.dateStart,
      startDate: date,
      startTime: hour,
      fileName: this.selectedPerfilApp.paramExtraFileName,
      paramExtra: this.selectedPerfilApp.paramExtra,
      typeParamExtra: this.selectedPerfilApp.typeParamExtra
    });
  }

  private async loadParameters(): Promise<void> {
    // Aquí, deberías hacer una llamada al backend para obtener los parámetros filtradas por jerarquía
    const profile = this.data.profile;
    const hierarchyInput = { idOperator: profile?.idOperator, idClient: profile?.idClient, idSubClient: profile?.idSubClient };
    
    const filterablesValues = this.hierarchy.getFilterableValues(hierarchyInput);
    const request = new FilterAndPagBean("ASC","name",filterablesValues,0,0,0);
    const result = await this.paramsService.find(request);
    if (result?.status === 0) {
      this.parameters = result.data;
    }
  }

  private async loadVersions():Promise<void>{
    if(!this.selectedPerfilApp) return;
    const filterList = [];
    const factory = this.data.profile.manufacID;
    const { appPakage, idApp } = this.selectedPerfilApp;
    filterList.push(new FilterableValue("pakage", appPakage, "string", OPERATION_FILTER.EQUALS));
    filterList.push(new FilterableValue("manufacID", factory, "string", OPERATION_FILTER.EQUALS));
    const request = new FilterAndPagBean("ASC","version", filterList, 0, 0, 0);
    const result = await this.appsService.getVersions(request, idApp);
    if (result?.status === 0) {
      this.versionBBDD = result.data;
    }
  }


  public onClose(){
    this.dialogRef.close();
  }

  public submitChanges(){
    const dialogData = new ConfirmDialogData();
    dialogData.enableCancel = false;
    dialogData.element = Element.profile; 
    dialogData.action = ElementAction.udpate;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    const confirmSave = this.dialog.open(ConfirmDialogComponent, {
      width: '35%', panelClass: 'custom-modalbox',
      data:dialogData
    });

    confirmSave.afterClosed().subscribe((response:ConfirmDialogActions) => {
      if (response === ConfirmDialogActions.CONFIRM){
        this.updatePerfilApp();
      }
    });
  }

  public async updatePerfilApp(){
    if(!this.selectedPerfilApp) return;
    
    const formValues = this.perfilAppForm.value;
    const editedPerfilApp:PerfilApp = new PerfilApp;
    editedPerfilApp.id = this.selectedPerfilApp.id;
    editedPerfilApp.appPakage = this.selectedPerfilApp.appPakage;
    editedPerfilApp.idApp = Number(formValues.idApp);
    editedPerfilApp.idParam = Number(formValues.idParam); 
    editedPerfilApp.dateStart = parseAndSetDateStart(formValues.startDate, formValues.startTime);
    editedPerfilApp.paramExtra = formValues.paramExtra;
    editedPerfilApp.typeParamExtra = formValues.typeParamExtra;

    const response = await this.profileService.updateApp(editedPerfilApp.mapToServer());
    if(response!.status >= 0 ) this.dialogRef.close({ result:1 });
  }

  removeFileApp(): void {
    this.perfilAppForm.patchValue({
      fileName:null,
      paramExtra:null,
    })
    
  }

  // Drag and drop events
  onFilesDrop(event: any) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      this.onAppFileSelected({ target: { files: [file] } });
    }
  }

  async onAppFileSelected(event: any): Promise<void> {
    const file = event.target.files[0];
    if (file) {
      // Obtener la extensión del archivo y convertir a mayúsculas
      const fileExtension = file.name.split('.').pop()?.toUpperCase() || '';

      const convertedFile = await convertFileToBase64(file);  
      this.perfilAppForm.patchValue({
        fileName:file.name,
        paramExtra:convertedFile as string,
        typeParamExtra: (fileExtension === ParamExtraFiles.CSV || fileExtension === ParamExtraFiles.ZIP) ? fileExtension : null
      });
    }
  }
  
  onDragOver(event: any) {
    event.preventDefault();
  }

  onDragLeave(event: any) {
    event.preventDefault();
  }


  get fileNameInput(){
    return this.perfilAppForm.get('fileName');
  }

  get paramExtraInput(){
    return this.perfilAppForm.get('paramExtra');
  }

}
