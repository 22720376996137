import { AfterViewInit, Component, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-coverage-icon',
  standalone: true,
  imports: [CommonModule, MatLegacyTooltipModule],
  templateUrl: './coverage-icon.component.html',
  styleUrls: ['./coverage-icon.component.css']
})
export class CoverageIconComponent implements AfterViewInit {
  @Input() level:number = 0;
  @Input() type:string = '';
  @Input() isInactive?:boolean = false;

  translate = inject(TranslateService);

  @ViewChild('wifiIcon') private wifiIconTemplate?: TemplateRef<any>;
  @ViewChild('ethIcon') private ethIconTemplate?: TemplateRef<any>;
  @ViewChild('simIcon') private simIconTemplate?: TemplateRef<any>;

  typeIconTemplate: TemplateRef<any> | null = null;
  isActive:boolean = false

  ngAfterViewInit(): void {
    this.typeIconTemplate = this.getTypeIcon();
    this.isActive = this.getIsActive();
  }

  getIsActive():boolean{
    return (!this.isInactive && !!this.level);
  }

  getTypeIcon(): TemplateRef<any> | null{
    if(this.type === 'WIFI') return this.wifiIconTemplate ?? null;
    if(this.type === 'ETH') return this.ethIconTemplate ?? null;
    return this.simIconTemplate ?? null;
  }

  get stateClass(): string {
    if (!this.isActive) {
      return 'no-coverage';
    } else if (this.level >= 75) {
      return 'high-coverage';
    } else if (this.level >= 30) {
      return 'medium-coverage';
    } else {
      return 'low-coverage';
    }
  }

  get icon(){
    if (!this.isActive) {
      return 'no-coverage';
    } else if (this.level >= 75) {
      return 'bi-reception-4';
    } else if (this.level >= 30) {
      return 'bi-reception-3';
    } else {
      return 'bi-reception-2';
    }
  }

  get tooltip(): string {
    return this.isActive ? `${this.type} ${this.level}%` : this.translate.instant('common.noData');
  }
}
