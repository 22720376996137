import { inject, Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { getDateToNow, parseStringToUTCDate } from "../util/util";

@Injectable({
    providedIn:'root'
})
export class DatesHandlerService{
      private authService  = inject(AuthService);
      private timeZone = this.authService.timeZone;
      private lang = this.authService.language;
 
      
    formatDateFromUTCDate(value?:string, uselang:boolean = false):string {
        if(!value) return "";
        const lang = (uselang ? this.lang:"en-US");
        const localDate = parseStringToUTCDate(value).toLocaleString(lang, { timeZone: this.timeZone });  
        return localDate;
    }


    getDateToNow(value?:string): string {
        if(!value) return ""
        const localDate = this.formatDateFromUTCDate(value);
        return getDateToNow(localDate);
    }



}