
<form [formGroup]="hierarchyForm">
    <div class="row">
        <div class="col" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_1)">
            <filterable-select formControlName="idOperator" (change)="updateFormState('idOperator')" [title]="'hierarchy.level_1'| translate" 
            [options]="operatorsList" valueField="id" displayField="name" ></filterable-select> 
        </div>

        <div class="col" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_2)">
            <filterable-select formControlName="idClient" (change)="updateFormState('idClient')" [title]="'hierarchy.level_2'| translate" 
            [options]="clientsList" valueField="id" displayField="name" ></filterable-select> 
        </div>

        <div class="col" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_3)">
            <filterable-select formControlName="idSubClient"  [title]="'hierarchy.level_3'| translate" 
            [options]="subClientsList" valueField="id" displayField="name" ></filterable-select> 
        </div>
    </div>
</form>