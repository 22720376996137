import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Profile } from 'src/app/models/profiles.model';
import { PerfilesService } from '../perfiles.service';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { TerminalsSelectorComponent } from '../terminals-selector/terminals-selector.component';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  standalone:true,
  imports:[ 
    CommonModule, 
    TranslateModule, 
    MatIconModule, 
    MatLegacyDialogModule,
    TerminalsSelectorComponent
  ],
  selector: 'app-edit-machines',
  templateUrl: './edit-machines.component.html',
  styleUrls: ['./edit-machines.component.css']
})
export class EditMachinesComponent implements OnInit{
  machines:VendingMachineAtento[] = [];
  hierarchyForm: FormGroup = new FormGroup({
    idOperator: new FormControl(null),
    idClient: new FormControl(null),
    idSubClient: new FormControl(null),
  });
 
  constructor(private dialog:MatDialog, 
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { profile:Profile }, 
    public dialogRef: MatDialogRef<EditMachinesComponent>,
    private profileService:PerfilesService,
    private notifier:NotifierService,
    private translate:TranslateService
  ){}

  ngOnInit(): void {
    const profile = this.data.profile;
    this.hierarchyForm.patchValue({
      idOperator: profile.idOperator ?? null ,
      idClient: profile.idClient ?? null,
      idSubClient: profile.idSubClient ?? null,
    }); 
  }

  onClose(){
    this.dialogRef.close();
  }

  public async submitChanges(): Promise<void> {
    if (this.machines.length === 0) {
        this.notifier.notify('error', this.translate.instant('warning.terminalIsMissing'));
        return;
    }

    // Confirmar y enviar los datos al backend
    const dialogData = new ConfirmDialogData();
    dialogData.enableCancel = false;
    dialogData.element = Element.profile; 
    dialogData.action = ElementAction.udpate;
    dialogData.class = DialogClass.info;  
    dialogData.icon = 'save-01';

    const confirmSave = this.dialog.open(ConfirmDialogComponent, {
        width: '35%', panelClass: 'custom-modalbox',
        data: dialogData
    });

    confirmSave.afterClosed().subscribe(async (response: ConfirmDialogActions) => {
        if (response === ConfirmDialogActions.CONFIRM) {
            await this.updateProfile();
        }
    });
  }

  private async getRelatedListVendingMachines(): Promise<VendingMachineAtento[]>{
    if(this.data.profile?.id){
      const filterAndPagBean = new FilterAndPagBean(null,null,[],null,null,null);
      const response  = await this.profileService.getVendingMachines(this.data.profile.id, filterAndPagBean);
      if(response && response.data) return response.data;
    }
    return [];
  }

  public async updateProfile() {
    const profile = {...this.data.profile};
    const listVendingMachine = await this.getRelatedListVendingMachines();
    profile.listVendingMachine = [...listVendingMachine, ...this.machines];
    
    const response = await this.profileService.saveTerminals(profile);
    if (response!.status >= 0) this.dialogRef.close({ result: 1 });
  }

  
}
