<div class="ct-dialog-header">
    <ng-container *ngIf="data.icon">
        <div class="dialog-icon {{data.class}}">
            <mat-icon [svgIcon]="data.icon"></mat-icon>
        </div>  
    </ng-container>

    <!-- Botón de cerrar arriba a la derecha -->
    <button class="ct-button ct-button--tertiary" (click)="onCancel()">
        <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
    </button>
</div>

<div class="dialog" style="position: relative;">
    <div class="dialog-body">
        <h1 mat-dialog-title>{{ 'confirmDialog.'+data.action+'.title' | translate:{ element:elementRef }  }}</h1>
        <div mat-dialog-content class="ct-dialog-content">
            <p>{{ 'confirmDialog.'+data.action+'.message' | translate }}</p>
            
            <div class="dialog-extra" *ngIf="data.extraInfo">
                <mat-icon svgIcon="alert-circle"></mat-icon>
                <span>{{ data.extraInfo }}</span>
            </div>
        </div>
    </div>
    
    <div *ngIf="enableButtons" class="dialog-footer">
        <ng-container *ngIf="enableCancel">
            <button class="ct-button ct-button--secondary w-100" (click)="cancel()">
                {{ 'common.cancel' | translate }}
            </button>
        </ng-container>
        <button class="ct-button ct-button--primary w-100" [ngClass]="{ 'red': data.class === 'error' }" (click)="confirm()">
            {{ 'common.confirm' | translate }}
        </button>
    </div>
</div>
