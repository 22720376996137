import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogActions, ConfirmDialogData } from './confirm-dialog.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls:['./confirm-dialog.component.css']
})

export class ConfirmDialogComponent implements OnInit, AfterViewInit {

  enableCancel: boolean=true;
  enableButtons: boolean=true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,    
    private cdr: ChangeDetectorRef,
    private translate:TranslateService
  ){
    if(data.enableCancel) this.enableCancel=data.enableCancel;
    if(data.enableButtons) this.enableButtons=data.enableButtons;    
  }


  ngOnInit() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  
  confirm(): void {
    this.dialogRef.close(ConfirmDialogActions.CONFIRM);
  }

  cancel(): void {
      this.dialogRef.close(ConfirmDialogActions.CANCEL);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  get elementRef() {
    return this.data.element ? this.translate.instant(`elements.${this.data.element}`) : '';
  }
}
