<div mat-dialog-title class="ct-dialog-header">
    <ng-template #defaultTitle>
        <span class="header-large">{{  'profiles.new' | translate }}</span>    
    </ng-template>

    <ng-container *ngIf="nameImput?.value; else defaultTitle">
        <span class="header-large">{{  nameImput?.value }} <ng-container *ngIf="factoryInput?.value">({{factoryInput?.value}})</ng-container></span>    
    </ng-container>

    <button class="ct-button ct-button--tertiary" (click)="onClose()">
        <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
    </button>
</div>


<mat-dialog-content class="ct-dialog-content ct-dialog-content__extended p-0">
    <mat-stepper [linear]="isLinear" #stepper>
        <!-- Details Step -->
        <mat-step [stepControl]="detailsFormGroup">
            <form [formGroup]="detailsFormGroup">
                <ng-template matStepLabel>{{ 'profiles.details' | translate }}</ng-template>
        
                <div class="pagetitle">
                    <h2>{{ 'profiles.details' | translate }}</h2>
                </div>
                <div class="details-container">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'profiles.name' | translate }}</mat-label>
                            <input matInput formControlName="name" placeholder="{{ 'profiles.placeholder.name' | translate }}" required>
                            <mat-error *ngIf="detailsFormGroup.get('name')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                            <mat-error *ngIf="detailsFormGroup.get('name')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                    
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'profiles.description' | translate }}</mat-label>
                            <textarea matInput formControlName="description" rows="4" maxlength="200" cdkTextareaAutosize   cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"
                            placeholder="{{ 'profiles.placeholder.description' | translate }}" required></textarea>
                            <mat-error *ngIf="detailsFormGroup.get('description')?.hasError('required')">
                                {{ 'error.required.field' | translate }}
                            </mat-error>
                            <mat-hint align="end">{{ detailsFormGroup.get('description')?.value?.length || 0 }} / 200</mat-hint>
                        </mat-form-field>
                    </div>
                
                    <div class="form-group">
                        <filterable-select #manuFacSelect formControlName="manufacID" [title]="'common.factory' | translate" 
                        [options]="factories?.list ?? []" valueField="name" displayField="name" 
                        [required]="true"></filterable-select> 
                    </div>
        
                    <div class="form-group">
                        <!-- Hierarchy Form (only in this step) -->
                        <app-hierarchy-selector formControlName="hierarchy"></app-hierarchy-selector>
                    </div>
                </div>

                <div class="button-group">
                    <button class="ct-button ct-button--secondary" (click)="onClose()">{{ 'common.cancel' | translate }}</button>
                    <button class="ct-button ct-button--primary" matStepperNext (click)="validateInput()">{{ 'common.next' | translate }}</button>
                </div>
            </form>
        </mat-step>

        <!-- Terminals Step -->
        <mat-step>
            <ng-template matStepLabel>{{ 'profiles.terminals' | translate }}</ng-template>
            
            <ng-template matStepContent>
                <div class="pagetitle">
                    <h2>{{ 'profiles.terminals' | translate }}</h2>
                </div>

                <div class="terminals-container">
                    <!-- Componente para selector de terminales -->
                    <app-terminals-selector [(machines)]="machines" [hierarchy]="hierarchyInput" [factory]="factoryInput?.value"></app-terminals-selector>
                </div>

                <div class="button-group">
                    <button class="ct-button ct-button--secondary" matStepperPrevious>
                        {{ 'common.back' | translate }}
                    </button>
                    <button class="ct-button ct-button--primary" matStepperNext>
                        {{ 'common.next' | translate }}
                    </button>
                </div>
            </ng-template>
        </mat-step>

        <!-- Apps Step -->
        <mat-step >
            <ng-template matStepLabel>{{ 'profiles.aplications' | translate }}</ng-template>

            <ng-template matStepContent>
                <div class="pagetitle">
                    <h2>{{ 'profiles.aplications' | translate }}</h2>
                </div>
            
                <!-- Componente para selector de aplicaciones -->
                <app-apps-selector [hierarchy]="hierarchyInput?.value" [factory]="factoryInput?.value" [(list)]="resultListApp"></app-apps-selector>

                <div class="button-group">
                    <button class="ct-button ct-button--secondary" matStepperPrevious>
                        {{ 'common.back' | translate }}
                    </button>
                    
                    <!-- [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad -->
                    <!--button class="ct-button ct-button--primary" matStepperNext>
                        {{ 'common.next' | translate }}
                    </button!-->
                    <button class="ct-button ct-button--primary" (click)="onFinalSubmit()" [disabled]="distanceFormGroup.invalid">
                        {{ 'common.submit' | translate }}
                    </button>
                </div>
            </ng-template>
        </mat-step>

        <!-- Distance Step -->
        <!-- [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad -->
        <!--mat-step [stepControl]="distanceFormGroup">
            <form [formGroup]="distanceFormGroup">
                <ng-template matStepLabel>{{ 'profiles.distance' | translate }}</ng-template>
            
                <div class="pagetitle">
                    <h2>{{ 'profiles.distance' | translate }}</h2>
                </div>
                <div class="distance-container">
                    <mat-slide-toggle formControlName="enableLocation" class="toggle-selector">
                        {{ 'profiles.distanceLocation' | translate }}
                    </mat-slide-toggle>
                    <div class="d-flex my-3">
                        <div class="w-50 mx-2">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'profiles.distance' | translate }}</mat-label>
                                <input matInput formControlName="distanceLocation" type="number" min="0" placeholder="{{ 'profiles.placeholder.distance' | translate }}" required>
                                <mat-error *ngIf="distanceFormGroup.get('distanceLocation')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                                <mat-error *ngIf="distanceFormGroup.get('distanceLocation')?.hasError('max')">{{ 'error.maxValue' | translate }}</mat-error>
                                <mat-error *ngIf="distanceFormGroup.get('distanceLocation')?.hasError('min')">{{ 'error.minValue' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
            
                        <div class="w-50 mx-2">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'profiles.units' | translate }}</mat-label>
                                <mat-select formControlName="distanceUnit">
                                    <mat-option value="km">{{'common.kilometers' | translate }}</mat-option>
                                    <mat-option value="m">{{'common.meters' | translate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="button-group">
                    <button class="ct-button ct-button--secondary" matStepperPrevious>
                        {{ 'common.back' | translate }}
                    </button>
                    <button class="ct-button ct-button--primary" (click)="onFinalSubmit()" [disabled]="distanceFormGroup.invalid">
                        {{ 'common.submit' | translate }}
                    </button>
                </div>
            </form>
        </mat-step!-->

        <!-- Icon overrides -->
        <ng-template matStepperIcon="number">
            <mat-icon>radio_button_unchecked</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="checked">
            <mat-icon>check_circle</mat-icon>
        </ng-template>

    </mat-stepper>
</mat-dialog-content>