import { ApexOptions } from "ng-apexcharts";


export function valueOrDefault<T>(value: T | undefined, defaultValue: T) {
  return typeof value === 'undefined' ? defaultValue : value;
}

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export function months(config: any) {
  let cfg = config || {};
  let count = cfg.count || 12;
  let section = cfg.section;
  let values = [];
  let i, value;

  for (i = 0; i < count; ++i) {
    value = MONTHS[Math.ceil(i) % 12];
    values.push(value.substring(0, section));
  }

  return values;
}

const COLORS = [
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba'
];

export function color(index: any) {
  return COLORS[index % COLORS.length];
}

export const CHART_COLORS = {
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)',
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)'
};

export const NAMED_COLORS = [
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.grey,
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.grey,
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
];

export function namedColor(index: any) {
  return NAMED_COLORS[index % NAMED_COLORS.length];
}


export function chartUtilConstructor(chartType: string, dataItems: any[], options: ApexOptions): ApexOptions {
  const chartOptions = { ...options };
  const series: any[] = [];
  let categories: string[] = [];

  switch (chartType) {
    case "line":
    case "area":
    case "bar-vertical":
    case "scatter":
      dataItems.forEach((item) => {
        if (!categories.includes(item.period)) {
          categories.push(item.period); 
        }

        const parsedData = JSON.parse(item.data);

        Object.keys(parsedData).forEach((key) => {
          let seriesItem = series.find((s) => s.name === key);

          if (!seriesItem) {
            seriesItem = { name: key, data: Array(categories.length).fill(0) };
            series.push(seriesItem);
          }

          const index = categories.indexOf(item.period);
          seriesItem.data[index] = parsedData[key]; 
        });
      });

      chartOptions.series = series;
      chartOptions.xaxis = { ...chartOptions.xaxis, categories };
      if (chartType === "bar-vertical") {
        chartOptions.plotOptions = {
          bar: { horizontal: false },
        };
      }
      break;

    case "bar-horizontal":
      
      dataItems.forEach((item) => {
        if (!categories.includes(item.period)) {
          categories.push(item.period); 
        }

        const parsedData = JSON.parse(item.data);

        Object.keys(parsedData).forEach((key) => {
          let seriesItem = series.find((s) => s.name === key);

          if (!seriesItem) {
            seriesItem = { name: key, data: [] };
            series.push(seriesItem);
          }

          seriesItem.data.push(parsedData[key]);
        });
      });

      chartOptions.series = series;
      chartOptions.xaxis = {
        categories, 
        title: { text: 'Cantidad' },
      };
      chartOptions.yaxis = {
        title: { text: 'Periodos' },
      };
      chartOptions.plotOptions = {
        bar: { horizontal: true },
      };
      break;

    case "pie":
    case "donut":
      const aggregatedData: Record<string, number> = {};

      dataItems.forEach((item) => {
        const parsedData = JSON.parse(item.data); 

        Object.keys(parsedData).forEach((key) => {
          if (!aggregatedData[key]) {
            aggregatedData[key] = 0;
          }
          aggregatedData[key] += parsedData[key]; 
        });
      });

      chartOptions.series = Object.values(aggregatedData); 
      chartOptions.labels = Object.keys(aggregatedData); 
      delete chartOptions.xaxis;
      delete chartOptions.yaxis;
      break;
    case "heatmap":
      dataItems.forEach((item) => {
        const parsedData = JSON.parse(item.data);

        Object.keys(parsedData).forEach((key) => {
          let seriesItem = series.find((s) => s.name === key);

          if (!seriesItem) {
            seriesItem = { name: key, data: [] };
            series.push(seriesItem);
          }

          seriesItem.data.push({ x: item.period, y: parsedData[key] }); 
        });
      });

      chartOptions.series = series;
      delete chartOptions.xaxis; 
      delete chartOptions.yaxis; 
      break;

    case "bubble":
      dataItems.forEach((item) => {
        const parsedData = JSON.parse(item.data);

        Object.keys(parsedData).forEach((key) => {
          let seriesItem = series.find((s) => s.name === key);

          if (!seriesItem) {
            seriesItem = { name: key, data: [] };
            series.push(seriesItem);
          }

          seriesItem.data.push({ x: item.period, y: parsedData[key], z: Math.random() * 100 });
        });
      });

      chartOptions.series = series;
      delete chartOptions.xaxis; 
      delete chartOptions.yaxis; 
      break;

    case "radar":
      dataItems.forEach((item) => {
        const parsedData = JSON.parse(item.data);

        Object.keys(parsedData).forEach((key) => {
          let seriesItem = series.find((s) => s.name === item.period);

          if (!seriesItem) {
            seriesItem = { name: item.period, data: [] };
            series.push(seriesItem);
          }

          seriesItem.data.push(parsedData[key]); 
        });

        categories = Object.keys(JSON.parse(dataItems[0].data)); 
      });

      chartOptions.series = series;
      chartOptions.xaxis = undefined; 
      break;

    default:
      console.error('Unsupported chart type: ', chartType);
      break;
  }

  return chartOptions;
}




