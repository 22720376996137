<main id="main" class="main">
  <div class="pagetitle">
    <span class="header-large">{{ 'alerts.title' | translate }}</span>
    <div>
      <button class="ct-button ct-button--secondary" (click)="refrescar()">
        <mat-icon class="ct-icon" svgIcon="refresh-ccw-01"></mat-icon> 
        <span class="hide-on-mobile">{{'common.refresh' | translate }}</span>
      </button>
    </div>
  </div>

  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" hidden> {{'alerts.tableDescription' | translate }} </div>

  <div class="mat-elevation-z2 tablaPersonal">
    <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" matSort
      (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter" [hiddenColumns]="getNotExportColumns()">

      <!-- terminal Column -->
      <ng-container matColumnDef="nameMachineID">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div>{{ 'alerts.nameMachineID' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.nameMachineID}}">
          {{element.nameMachineID}} </td>
      </ng-container>
      
      <!-- cod Column -->
      <ng-container matColumnDef="cod">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'alerts.cod' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.cod}} </td>
      </ng-container>


      <!-- description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div mat-sort-header> {{ 'alerts.description' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.description}}">
          {{element.description}} </td>
      </ng-container>

        <!-- channel Column -->
        <ng-container matColumnDef="channel">
          <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
            <div mat-sort-header> {{ 'alerts.channel' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.channel}}">
            {{element.channel}} </td>
        </ng-container>

      <!-- priority Column -->
      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header> {{ 'alerts.priority' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.priority === 0" i18n>-</span>
          <span *ngIf="element.priority === 1" i18n>{{ 'alert.priorityLow' | translate }}</span>
          <span *ngIf="element.priority === 2" i18n>{{ 'alert.priorityMedium' | translate }}</span>
          <span *ngIf="element.priority === 3" i18n>{{ 'alert.priorityHigh' | translate }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header> {{ 'alerts.status' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.status === 1" class="status-open">
            <span class="status-circle"></span>
            {{ 'alerts.statusOpen' | translate }}
          </span>
          <span *ngIf="element.status === 0" class="status-closed">
            <span class="status-circle"></span>
            {{ 'alerts.statusClosed' | translate }}
          </span>
          <span *ngIf="element.status === undefined" class="status-undefined">
            <span class="status-circle"></span>
            {{ 'alerts.statusUndefined' | translate }}
          </span>
        </td>
      </ng-container>

      <!-- dateCreate Column -->
      <ng-container matColumnDef="dateCreate">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header> {{ 'alerts.dateCreate' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
      </ng-container>

      <!-- operator Column -->
      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef>
          <div> {{ 'alerts.operator' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.idOperator | levelName:'LEVEL_1' }}</td>
      </ng-container>

      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
        <td mat-cell *matCellDef="let element" class="button" style="width: 60px;">
          <ng-container *ngIf="element.nameMachineID && !isOnTerminalContext && rol.canByInput(rol.functIds.view_terminals)">
            <button matTooltip="{{ 'tooltips.viewTerminal' | translate }}" (click)="onView(element)" 
              mat-icon-button class="iconoTabla">
              <mat-icon>phone_android</mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- Empty state -->
    <ng-container *ngIf="!dataSource.data.length">
      <app-table-empty-state></app-table-empty-state>
    </ng-container>

    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', { fileName: exportFileName })">
      <i class="bi bi-file-earmark-arrow-down"></i>
    </button>

    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent()"
      showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</main>