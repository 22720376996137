<main id="main" class="main">
  <div class="pagetitle">
    <span class="header-large">{{ 'incidences.title' | translate }}</span>
    <div>
      <button class="ct-button ct-button--secondary" (click)="refrescar()">
        <mat-icon class="ct-icon" svgIcon="refresh-ccw-01"></mat-icon> 
        <span class="hide-on-mobile">{{'common.refresh' | translate }}</span>
      </button>

      <ng-container *ngIf="rol.canByInput(rol.functIds.edit_incidences)">
        <button class="ct-button ct-button--primary" (click)="onNew()">
          <mat-icon class="ct-icon" svgIcon="plus"></mat-icon> 
          <span class="hide-on-mobile">{{ 'incidences.newIncidence' | translate }}</span>
        </button>
      </ng-container>
    </div>
  </div>

  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" hidden> {{'incidences.tableDescription' | translate }} </div>

  <div class="mat-elevation-z2 tablaPersonal">
    <table mat-table matTableExporter [dataSource]="dataSource" aria-describedby="tableDescription" matSort
      (matSortChange)="announceSortChange($event)" #exporter="matTableExporter" [hiddenColumns]="getNotExportColumns()">

      <!-- customID Column -->
      <ng-container matColumnDef="serialNumber">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div mat-sort-header> {{ 'incidences.serialNumber' | translate }} </div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.serialNumber}}">
          {{element.serialNumber}} </td>
      </ng-container>

      <!-- operator Column -->
      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef>
          <div> {{ 'alerts.operator' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.operator?.name }}</td>
      </ng-container>

      <!-- type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'incidences.type' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.type === 0">{{ 'incidences.types.other' | translate }}</span>
          <span *ngIf="element.type === 1">{{ 'incidences.types.suggestion' | translate }}</span>
          <span *ngIf="element.type === 2">{{ 'incidences.types.telemetryFailure' | translate }}</span>
          <span *ngIf="element.type === 3">{{ 'incidences.types.terminalBreakdown' | translate }}</span>
          <span *ngIf="element.type === 4">{{ 'incidences.types.nonActiveTerminal' | translate }}</span>
        </td>
      </ng-container>
      <!-- dateCreate Column -->
      <ng-container matColumnDef="dateCreate">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'incidences.dateCreate' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
      </ng-container>
      <!-- description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div mat-sort-header> {{ 'incidences.description' | translate }} </div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.description}}">
          {{element.description}} </td>
      </ng-container>
      <!-- userCreate Column -->
      <ng-container matColumnDef="userCreate">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'incidences.userCreate' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.userCreate?.name}}</td>
      </ng-container>
      <!-- resolution Column -->
      <ng-container matColumnDef="resolution">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div mat-sort-header>{{ 'incidences.resolution' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.resolution}}">
          {{element.resolution}} </td>
      </ng-container>
      <!-- dateDelete Column -->
      <ng-container matColumnDef="dateDelete">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'incidences.dateDelete' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.dateDelete | dateOnTimeZone:true }}</td>
      </ng-container>
      <!-- userDelete Column -->
      <ng-container matColumnDef="userDelete">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'incidences.userDelete' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.userDelete?.name}}</td>
      </ng-container>

      
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'monitoring.status.status' | translate }}</div> 
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="status-indicator">
            <i [ngClass]="getStatusIconClass(element)" class="status-icon"></i> 
            <span>{{ getStatusLabel(element) }}</span>
          </div>
        </td>
      </ng-container>
      

      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef style="width:120px;"></th>
        <td mat-cell *matCellDef="let element" class="button" style="width:120px;">
          <div>
            <ng-container *ngIf="!element.dateDelete && rol.canByInput(rol.functIds.edit_incidences)">
              <button matTooltip="{{ 'common.close' | translate }}" (click)="onClose(element)" 
                mat-icon-button class="iconoTabla">
                <mat-icon>settings</mat-icon>
              </button>
            </ng-container>

            <ng-container *ngIf="element.serialNumber && !isOnTerminalContext && rol.canByInput(rol.functIds.view_terminals)">
              <button matTooltip="{{ 'tooltips.viewTerminal' | translate }}" (click)="onView(element)" 
                mat-icon-button class="iconoTabla">
                <mat-icon>phone_android</mat-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="!element.dateDelete && rol.canByInput(rol.functIds.edit_incidences)">
              <button matTooltip="{{ 'common.edit' | translate }}" (click)="onEdit(element)" 
                mat-icon-button class="iconoTabla">
                <mat-icon>chevron_right</mat-icon>
              </button>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- Empty state -->
    <ng-container *ngIf="!dataSource.data.length">
      <app-table-empty-state></app-table-empty-state>
    </ng-container>

    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', { fileName: exportFileName })">
      <i class="bi bi-file-earmark-arrow-down"></i>
    </button>

    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent()"
      showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</main>