<div class="pagetitle p-3">
    <span class="header-large">{{ 'maintenace.title' | translate }}</span>
</div>

<ng-container *ngIf="terminal">
  <mat-tab-group animationDuration="0ms" selectedIndex="0" (selectedTabChange)="changeTab($event)" style="height: calc(100% - 70px);">  
      <mat-tab *ngIf="rol.canByInput(rol.functIds.send_file)">
        <ng-template mat-tab-label>
          <span>{{ 'maintenace.tabs.sendFile' | translate }}</span>
        </ng-template>
          <app-send-file [data]="terminal"></app-send-file>
      </mat-tab>
      <mat-tab *ngIf="rol.canByInput(rol.functIds.get_file)">
        <ng-template mat-tab-label>
          <span>{{ 'maintenace.tabs.getFile' | translate }}</span>
        </ng-template>
          <app-get-file [data]="terminal"></app-get-file>
      </mat-tab>
      <mat-tab *ngIf="rol.canByInput(rol.functIds.commands)">
        <ng-template mat-tab-label>
          <span>{{ 'maintenace.tabs.createCommands' | translate }}</span>
        </ng-template>
          <app-commands [data]="terminal"></app-commands>
      </mat-tab>  
      
      <mat-tab *ngIf="rol.canByInput(rol.functIds.pending_tasks)">
        <ng-template mat-tab-label>
          <span>{{'monitoring.tabs.pendingTasks' | translate }}</span>
        </ng-template>
        <ng-template matTabContent>
          <app-pending-tasks></app-pending-tasks>
        </ng-template>
      </mat-tab>

      <mat-tab *ngIf="rol.canByInput(rol.functIds.view_file)">
        <ng-template mat-tab-label>
          <span>{{ 'maintenace.tabs.listFiles' | translate }}</span>
        </ng-template>
        <ng-template matTabContent>
          <app-list-files [data]="terminal"></app-list-files>
        </ng-template>
      </mat-tab>
    
      <mat-tab *ngIf="rol.canByInput(rol.functIds.history)">
        <ng-template mat-tab-label>
          <span>{{ 'monitoring.tabs.userHistory' | translate }}</span>
        </ng-template>
        <ng-template matTabContent>
          <app-history-user></app-history-user>
        </ng-template>
      </mat-tab>
    

  </mat-tab-group>
</ng-container>
