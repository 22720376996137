import { inject, Pipe, PipeTransform } from '@angular/core';
import { DatesHandlerService } from 'src/app/services/dates-handler.service';

@Pipe({
    standalone:true,
    name: 'dateOnTimeZone',
})
export class DateOnTimeZonePipe implements PipeTransform {
  private dateHandler  = inject(DatesHandlerService);
 
  transform(value?:string, uselang?:boolean): string {
    return this.dateHandler.formatDateFromUTCDate(value, uselang);
  }
}
