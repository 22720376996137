import { Injectable } from '@angular/core';
import { catchError, from, map, Observable, of } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { StadisticBean, StadisticBeanVM } from 'src/app/models/stadisticBean.model';
import { ResponseData } from 'src/app/models/StadisticDetails.model';
import { RestService } from 'src/app/services/merchant/Rest.service';




@Injectable({
  providedIn: 'root',
})
export class StadisticService {

  constructor(private merchantRest : RestService,private authService:AuthService) {}

 
  
  async find(_body: StadisticBean ){

    let userSession = this.authService.currentUserValue();
    _body.idEntorno=userSession!.entorno!.id
          
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'generateReport/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }


  findDetails(_body: StadisticBeanVM): Observable<ResponseData> {
    const userSession = this.authService.currentUserValue();
    _body.idEntorno = userSession?.entorno?.id;

    return from(
      this.merchantRest.commonRestCallConsolaRest(
        _body,
        'getReportDetail/',
        true,
        true
      )
    ).pipe(
      map((response: any) => {
        if (!response) {
          return { status: -1, data: [] } as ResponseData;
        }
        return response as ResponseData; 
      }),
      catchError((err) => {
        console.error('Error in findDetails:', err);
        return of({ status: -1, data: [] });
      })
    );
  }


}