import { Injectable } from '@angular/core';
import { PaginatorIntlService } from './paginator-intl.service';
import { HierarchyOptionsService } from './hierarchy-options.service';
import { UtilService } from './util.service';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root'
})
export class ComponentsStateService {

  constructor(
    private paginatorIntl: PaginatorIntlService,
    private hierarchyOptions: HierarchyOptionsService,
    private utils:UtilService,
    private dialogs:MatLegacyDialog
  ) {}

  update(){
    this.updateLabelsState();
    this.loadHierarchyOptions();
  }

  updateLabelsState(){
    this.paginatorIntl.updateLabelsState();
  }

  
  loadHierarchyOptions(){
    this.hierarchyOptions.load();
  }

  clearData(){
    this.hierarchyOptions.cleanState();
    this.utils.cleanRolsAndOperators();
    this.dialogs.closeAll();
  }
}
