import { Injectable } from '@angular/core';
import { ConsoleRequest } from 'src/app/models/console.request.model';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { PerfilApp } from 'src/app/models/perfilApp.model';
import { ListMachinesRequest, ListParamExtraRequest, Profile } from 'src/app/models/profiles.model';
import { RestService } from 'src/app/services/merchant/Rest.service';

@Injectable({
  providedIn: 'root',
})
export class PerfilesService {

  constructor(private merchantRest: RestService) { }

  async find(filterAndPagBean: FilterAndPagBean) {
    const _body = new ConsoleRequest;
    _body.filterAndPagBean = filterAndPagBean;

    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'getListPerfil/', true, true)
      .catch(err => {
        return null
      })
    return value;
  }

  async countTotal(filterAndPagBean: FilterAndPagBean) {
    const _body = new ConsoleRequest;
    _body.filterAndPagBean = new FilterAndPagBean(filterAndPagBean?.orderSentido, filterAndPagBean?.order, filterAndPagBean?.filterGeneric, null, null, null);

    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'countListPerfil/', true, true)
      .catch(err => {
        return null
      })
    return value;
  }

  async get(_id: number) {
    let value = await this.merchantRest.commonRestCallConsolaRest({ id:_id }, 'getPerfil/', true, true)
      .catch(err => {
        return null;
      });

    return value;
  }
  
  async addProfile(profile: Profile) {
    let response = await this.merchantRest.commonRestCallConsolaRest(profile, 'addPerfil/', true, true)
      .catch(err => {
        return null
      })
    return response;
  }


  async updateProfile(profile: Profile) {
    let response = await this.merchantRest.commonRestCallConsolaRest(profile, 'updatePerfil/', true, true)
      .catch(err => {
        return null
      })
    return response;
  }

  
  async saveTerminals(profile: Profile) {
    let response = await this.merchantRest.commonRestCallConsolaRest(profile, 'addPerfilTerminal/', true, true)
      .catch(err => {
        return null
      })
    return response;
  }

  async saveApps(profile: Profile) {
    let response = await this.merchantRest.commonRestCallConsolaRest(profile, 'addPerfilApp/', true, true)
      .catch(err => {
        return null
      })
    return response;
  }

  async ordenarApps(profile: Profile) {
    let response = await this.merchantRest.commonRestCallConsolaRest(profile, 'ordenarPerfilApp/', true, true)
      .catch(err => {
        return null
      })
    return response;
  }

  async updateApp(perfilApp:PerfilApp){
    let response = await this.merchantRest.commonRestCallConsolaRest(perfilApp, 'updatePerfilApp/', true, true)
      .catch(err => {
        return null
      })
    return response;
  }


  async delete(_id: number,_name:string) {
    let value = await this.merchantRest.commonRestCallConsolaRest({ id:_id, name:_name }, 'deletePerfil/', true, true)
      .catch(err => {
        return null
      })

    return value;
  }

  async getExtraParams(_idPerfil:number, _idApp:number, _filterAndPagBean:FilterAndPagBean ){
    const _body = new ListParamExtraRequest;
    _body.idPerfil = _idPerfil;
    _body.idApp = _idApp;
    _body.filterAndPagBean = _filterAndPagBean;

    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'getListParamExtra/', true, true)
    .catch(err => {
      return null
    })

    return value;
  }

  async getVendingMachines(_idPerfil:number, _filterAndPagBean:FilterAndPagBean ){
    const _body = new ListMachinesRequest;
    _body.id = _idPerfil;
    _body.filterAndPagBean = _filterAndPagBean;

    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'getTerminalsByPerfil/', true, true)
    .catch(err => {
      return null
    })

    return value;
  }
}
