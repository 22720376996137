import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Factories } from 'src/app/models/factories.model';
import { InputFilesComponent } from 'src/app/components/input-files/input-files.component';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { NotifierService } from 'angular-notifier';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FactoriesService } from 'src/app/services/factories.service';
import { SelectMachineWindowComponent } from 'src/app/pages/comun/select-machine-window/select-machine-window.component';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { convertFileToJson, downloadFile } from 'src/app/util/util';
import { TerminalFromFile } from 'src/app/models/terminalFromFile.model';
import { AbstractControl, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { FileDisplayComponent } from 'src/app/components/file-display/file-display.component';
import { FILE_SIZE_LIMIT_COMPARE_VALUES, FILE_SIZE_LIMIT_LABELS } from 'src/app/util/constants';
import { NgxSpinnerService } from 'ngx-spinner';

interface InvalidFactoryItem {
  factory:string;
  model:string;
}

const EXAMPLE_HEADER = ['NUMERO-SERIE','FABRICANTE','MODELO'];

@Component({
  selector: 'app-terminals-selector',
  standalone: true,
  imports: [
    CommonModule, 
    InputFilesComponent, 
    MatLegacyButtonModule,
    MatIconModule,
    MatLegacyTooltipModule, 
    FormsModule, 
    TranslateModule,
    FileDisplayComponent
  ],
  templateUrl: './terminals-selector.component.html',
  styleUrls: ['./terminals-selector.component.css']
})
export class TerminalsSelectorComponent implements OnInit{
  @Input() machines:VendingMachineAtento[] = [];
  @Output() machinesChange = new EventEmitter<VendingMachineAtento[]>();
  @Input() hierarchy?:(AbstractControl<any> | null);
  @Input('factory') factoryName?: string;
  @Input() fixDropZone?:boolean = false;
  @Input() hideButtons?:boolean = false;
  
  showDropZone:boolean = false;
  hasUploadAFile:boolean = false;
  hasSelectedFromPortal:boolean = false;
  
  factories = new Factories;
  invalidRows:string[] = [];
  invalidTerminals:InvalidFactoryItem[] = [];
  invalidFactory = new Set();
  selectedFile:(File | null) = null;

  constructor(
    private dialog:MatLegacyDialog, 
    private notifier:NotifierService,
    private translate:TranslateService,
    private factoriesService:FactoriesService,
    private spinnerService:NgxSpinnerService,
  ){}

  ngOnInit(): void {
    this.loadFactories();
  }

  async loadFactories(){
    const response = await this.factoriesService.getFactoriesList();
    if(response && response.data){
      this.factories = new Factories;
      this.factories.list = response.data;
    };
  }

  openSelectMachine(): void {
    const dialogRef = this.dialog.open(SelectMachineWindowComponent, {
        panelClass: 'custom-modalbox-big',
        data: { hierarchy: this.hierarchy?.value, factory: this.factoryName }
    });

    dialogRef.afterClosed().subscribe((response) => {
        if (response?.result === 1) {
            const validMachines = response.data.map((machine: VendingMachineAtento) => ({ id:machine.id, idAtento:machine.idAtento }));
            this.removeSelected();
            this.showDropZone = false;
            this.hasUploadAFile =  false;
            this.hasSelectedFromPortal = true;
            this.machines = [...validMachines];
            this.machinesChange.emit(this.machines);
        }
    });
  }

  openDropZone(){
    this.showDropZone = true;
    this.hasUploadAFile =  false;
    this.hasSelectedFromPortal = false;
    this.removeSelected()
  }

  public get invalidFactories() {
    return Array.from(this.invalidFactory);
  }

  /**
   * @param parsedFactory  
   * @returns boolean
   * Verifico que el fabricante obtenido se corresponda al input factoryName. (Enviado en el contexto de perfil)
   * En caso que no se reciba el input devuelvo siempre true.
   */
  private hasSameFactoryName(parsedFactory:string): boolean {
    if(!this.factoryName) return true;
    return (parsedFactory.toUpperCase() === this.factoryName.toUpperCase());
  }

  async parseFileData(file:File){
    this.invalidRows = [];
    this.spinnerService.show();
    const parsedFileData: { array: { [key: string]: string; }[], invalidRows:string[] } = await convertFileToJson(file);

    
    this.spinnerService.hide();
    parsedFileData.array.forEach((fileData) => {
      const { numero_serie, fabricante, modelo } = fileData;
      const terminalFromFile = new TerminalFromFile(numero_serie,fabricante,modelo);
      if(!terminalFromFile.isTerminalDataComplete()) return;
      
      if(!this.factories.isAValidFactoryAndModel(fabricante, modelo)){
        this.invalidTerminals.push({ factory:fabricante, model: modelo });
        return;
      }

      if(!this.hasSameFactoryName(fabricante)){
        this.invalidFactory.add(fabricante.toUpperCase());
        return;
      }
      
      const vendingMachine = terminalFromFile.mapToTerminal();
      this.machines.push(vendingMachine);
    });

    this.invalidRows = parsedFileData.invalidRows;
  }

  async onFilesSelected(files: File[]): Promise<void> {
    if(!files.length) return;
    this.hasSelectedFromPortal = false;
    this.removeSelected();

    const file = files[0];
    if(file.size > FILE_SIZE_LIMIT_COMPARE_VALUES.terminals){
      this.notifier.notify('error', this.translate.instant('error.maxFileSize', { fileSize: FILE_SIZE_LIMIT_LABELS.terminals }));
      return;
    }
    this.selectedFile = file;
    await this.parseFileData(file);

    // Verificar el conteo de terminales después de procesar el archivo
    if (this.machines.length === 0 || this.invalidRows.length !== 0) {
        this.notifier.notify('error', this.translate.instant('warning.noValidTerminals'));
        return;
    } 

    if(this.invalidTerminals.length != 0 || this.invalidFactory.size != 0) {
      this.notifier.notify('error', this.translate.instant('warning.importValidFactories'));
      return;
    }

    this.hasUploadAFile = true;
    this.machinesChange.emit(this.machines);  
    this.notifier.notify('success', this.translate.instant('success.importValidTerms'));    
  }

  removeSelected(){
    this.selectedFile = null;
    this.machines = [];
    this.machinesChange.emit(this.machines); 
    this.invalidRows = [];
    this.invalidTerminals = [];
    this.invalidFactory.clear();
    this.hasUploadAFile = false;
  }

  downloadExampleFile(){
    let exampleFileData = '';
    exampleFileData = exampleFileData.concat(EXAMPLE_HEADER.toString().replace(/,/g,';'));
    exampleFileData = exampleFileData.concat(this.factories.getDataForExampleFile())

    downloadFile('import-terminals-example.csv', exampleFileData);
  }


  get inputInvalid():boolean{
    return !!this.invalidRows.length || !!this.invalidFactories.length || !!this.invalidTerminals.length || !this.machines.length
  }

}
