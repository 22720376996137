import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { Operator } from 'src/app/models/operator.model';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class OperatorService {
  
  constructor(
    private merchantRest : RestService, private authService:AuthService,
    public commonService: CommonService) {
    }
    

  async find(_query:FilterAndPagBean){
    let _body: UsersConsoleRequest = new UsersConsoleRequest()
    
    let userSession = this.authService.currentUserValue();
    if(userSession?.operator) _body.idEntorno=userSession.entorno?.id
    _body.filterAndPagBean = _query
          
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListOperator/',true,true )   
      .catch(err => {
        return null
    })
      
    return value;     
  }

  async getItem(id:number){
    let value = await this.merchantRest.commonRestCallConsolaRest({ id },'getOperator/',true,true )   
      .catch(err => {
        return null
      })
      
    return value?.data;   
  }

  async addUpdate(_body:Operator){
    let userSession = this.authService.currentUserValue();
    if(userSession) _body.idEntorno=userSession.entorno?.id

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'addOperator/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }
}