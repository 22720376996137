import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotifierService } from 'angular-notifier';
import { Parameters } from 'src/app/models/parameters.model';
import { ParamService } from '../param.service';
import { BooleanInput } from '@angular/cdk/coercion';
import { FILE_SIZE_LIMIT_COMPARE_VALUES, FILE_SIZE_LIMIT_LABELS, FormCreateActions, STORAGE_TYPE } from 'src/app/util/constants';
import { convertFileToBase64, downloadFileFromBase64 } from 'src/app/util/util';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { TranslateService } from '@ngx-translate/core';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { Tag } from '../../tags/tag.model';
import { DatesHandlerService } from 'src/app/services/dates-handler.service';

@Component({
  selector: 'app-edit-param',
  templateUrl: './edit-param.component.html',
  styleUrls: ['./edit-param.component.css']
})
export class EditParamComponent implements OnInit {

  form!: FormGroup;
  isLinear: BooleanInput;
  showDropZone = false;
  uploadProgress = 0;
  selectedFile: File | null = null;
  isOpen: { [key: string]: boolean } = {
    details: true,
    hierarchy:true,
    dates: true,
    fileUpload: true,
  };

  readOnly: boolean = false;
  fileUploadError = false; 
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { param: Parameters, readOnly: boolean },
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private paramService: ParamService,
    private translate: TranslateService,
    private datesHandler:DatesHandlerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditParamComponent>,
    public rol: RolHandlerService
  ) {
    this.readOnly = !!data.readOnly;
    this.form = this.formBuilder.group({
      name: [{ value: data.param.name, disabled: this.readOnly }, Validators.compose([Validators.required, Validators.maxLength(45)])],
      dateCreate: [{ value: data.param.dateCreate, disabled: true }],
      dateUpdate: [{ value: data.param.dateUpdate, disabled: true }],
      description: [{ value: data.param.description, disabled: this.readOnly }, Validators.compose([ Validators.maxLength(200)])],
      version: [{ value: data.param.version, disabled: this.readOnly }, Validators.compose([Validators.required, Validators.maxLength(45)])],
      storageType: [{value:data.param.storageType === STORAGE_TYPE.PUBLIC_DIR, disabled: this.readOnly}],
      fileName: [data.param.fileName],
      base64: [data.param.base64],
      tags: [{value:data.param.listEtiquetas ?? [], disabled: this.readOnly}],
      hierarchy:[{value:{ idOperator:null, idClient:null, idSubClient:null }, disabled:true}],
    });
  }

  ngOnInit(): void {
    // Obtener los datos del backend usando el id del parámetro
    this.paramService.get(this.data.param.id).then(response => {
      if (response && response.status === 0 && response.data) {
        // Carga los datos recibidos del backend en el formulario
        const paramData = response.data;
        this.form.patchValue({
          name: paramData.name,
          description: paramData.description,
          version: paramData.version,
          storageType: paramData.storageType === STORAGE_TYPE.PUBLIC_DIR,
          dateCreate: this.datesHandler.formatDateFromUTCDate(paramData.dateCreate, true),
          dateUpdate: this.datesHandler.formatDateFromUTCDate(paramData.dateUpdate, true),
          fileName: paramData.fileName,
          base64: paramData.base64,
          tags: paramData.listEtiquetas ?? [],
          hierarchy:{ idOperator:paramData.idOperator, idClient:paramData.idClient, idSubClient:paramData.idSubClient }
        });

        this.form.markAsPristine();
        // Establece el archivo del backend
        if (paramData.base64 && paramData.fileName) {
          this.selectedFile = new File([paramData.base64], paramData.fileName, { type: 'application/octet-stream' });
        }
      } else {
        this.onCancel();
      }
    });
  }

  onDelete(): void {
    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.parameter; 
    dialogData.action = ElementAction.delete;
    dialogData.class = DialogClass.error;
    dialogData.icon = 'trash-01';

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
          const response = await this.paramService.delete(this.data.param.id);
          if(response?.status === 0){
            this.notifier.notify('success', this.translate.instant('success.parameterDeleted'));
            this.dialogRef.close(FormCreateActions.SAVED);
          }
      }
    });
  }

  toggleDropZone(): void {
    this.showDropZone = !this.showDropZone;
  }
  toggleSection(section: string): void {
    this.isOpen[section] = !this.isOpen[section];
  }

  private mapFileToBase64(file: File) {
    // Convertir el archivo a base64 y actualizar el formulario
    convertFileToBase64(file).then(base64String => {
      if (!base64String) {
        this.fileUploadError = true; // Mostrar error si no hay archivo
        this.notifier.notify('warning', this.translate.instant('error.fileEmpty'));
        return;
      }
      this.selectedFile = file;
      this.fileUploadError = false; // Mostrar error si no hay archivo
      this.form.patchValue({ fileName: this.selectedFile.name });
      this.form.patchValue({ base64: base64String });
      this.form.markAsDirty();
      this.showDropZone = false; // Oculta la zona de arrastre
    }).catch(error => {
      this.fileUploadError = true; // Mostrar error si no hay archivo
      this.notifier.notify('warning', this.translate.instant('error.fileInvalid'))
    });
  }

  async onFileSelected(files: File[]): Promise<void> {
    if (files.length > 0) {
      const file = files[0];
      this.deleteFile();
      if(file.size > FILE_SIZE_LIMIT_COMPARE_VALUES.parameters){
        this.notifier.notify('error', this.translate.instant('error.maxFileSize', { fileSize: FILE_SIZE_LIMIT_LABELS.parameters }));
        return;
      }
      
      this.mapFileToBase64(file);
    }
  }

  deleteFile(): void {
    this.selectedFile = null;
    this.form.get('fileName')?.reset();
    this.form.get('base64')?.reset();
    this.uploadProgress = 0;
    this.fileUploadError = true; // Mostrar error si no hay archivo
  }

  async onSave(): Promise<void> {
    if (!this.form.valid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }

    // Extrae los valores del formulario
    const formData = this.form.value;

    // Asigna los valores al objeto Parameters que se enviará
    const updatedParam: Parameters = new Parameters;

    updatedParam.id = this.data.param.id;
    updatedParam.name = formData.name;
    updatedParam.dateCreate = this.data.param.dateCreate;
    updatedParam.description = formData.description;
    updatedParam.version = formData.version;
    updatedParam.base64 = formData.base64
    updatedParam.fileName = formData.fileName;
    updatedParam.storageType = formData.storageType ? STORAGE_TYPE.PUBLIC_DIR : STORAGE_TYPE.FILES_DIR;
    updatedParam.listEtiquetas = (formData.tags.length) ? formData.tags.map((tag: Tag) => ({ id: tag.id })) : [];
    
    updatedParam.idOperator = this.data.param.idOperator;
    updatedParam.idClient = this.data.param.idClient;
    updatedParam.idSubClient = this.data.param.idSubClient;

    if(this.selectedFile){
      updatedParam.base64 = formData.base64;
      updatedParam.fileName = formData.fileName;  
    }

    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.parameter; 
    dialogData.action = ElementAction.udpate;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const response =  await this.paramService.addUpdate(updatedParam);
        if(response && response.status === 0){
          this.notifier.notify('success', this.translate.instant('success.parameterUpdated'));
          this.dialogRef.close(FormCreateActions.SAVED);
        }
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  public downloadVersion(): void {
    const { fileName, base64 } = this.form.value;
    if (base64) {
      downloadFileFromBase64(fileName, base64);
    } else {
      this.notifier.notify('error', this.translate.instant('error.errorOnDownload'));
    }
  }

}
