<main id="main" class="main">
  <div class="pagetitle">
    <span class="header-large">{{ 'stadisticsFunctionally.title' | translate }}</span>
  </div>

  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>
  <ng-container *ngFor="let packageName of getKeys(groupedDataByPackageName)">

      <app-stadistic-details-item [packageName]="packageName"
        [data]="groupedDataByPackageName[packageName]"></app-stadistic-details-item>
  </ng-container>

</main>