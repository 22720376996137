<main id="main" class="main">
    <div class="my-2">
      <span class="header-large">{{ 'maintenace.tabs.createCommands' | translate }}</span>   
    </div>

    <section class="row w-100">
        <ng-container *ngIf="!hasVendingMachineSelected">
          <article class="col-lg-6">
            <app-select-machine-detail [dataSource]="selectedVendingMachines"></app-select-machine-detail>
          </article>  
        </ng-container>

        <article class="bloque col-lg-6 form-container entero-{{hasVendingMachineSelected}}">
            <form [formGroup]="commandParams">
                <!-- Command -->
                <div class="form-group" style="margin-top: 10px;">
                  <filterable-select formControlName="command" [options]="commandTypes" 
                    [title]="'maintenace.input.command' | translate " [required]="true"></filterable-select> 
                </div>
          
                <!-- PackageName -->
                <div class="form-group" *ngIf="commandParams.get('packageName')?.enabled">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'maintenace.input.packageName' | translate }}<span class="obligatorio"></span></mat-label>
                    <input matInput formControlName="packageName" placeholder="{{ 'maintenace.placeholder.packageName' | translate }}" required>
                    <mat-error *ngIf="commandParams.get('packageName')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                    <mat-error *ngIf="commandParams.get('packageName')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
                  </mat-form-field>
                </div>

                <!-- Level -->
                <div class="form-group" *ngIf="commandParams.get('level')?.enabled">
                    <mat-form-field appearance="outline">
                        <mat-label> {{ 'common.logType' | translate }}</mat-label>
                        <mat-select formControlName="level" required>
                            <mat-option *ngFor="let type of levelTypes" [value]="type">{{ type }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="commandParams.get('level')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="botonera">
                  <button class="ct-button ct-button--primary" (click)="onFormSubmit()" [disabled]="commandParams.pristine || commandParams.invalid">
                      {{ 'common.send' | translate }}
                  </button>
                </div>
              </form>
        </article>
    </section>

</main>