<div mat-dialog-title class="ct-dialog-header">
    <span class="header-large">{{ selectedPerfilApp?.appName }}</span>
    
    <button class="ct-button ct-button--tertiary" (click)="onClose()">
        <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
    </button>  
</div>

<mat-dialog-content class="ct-dialog-content">
<div class="profile-app">
    <form [formGroup]="perfilAppForm">
        <div class="profile-app-form">
            <div class="mb-3 w-100">
                <label for="paramSelect" class="form-label">{{ 'profiles.selectVersions' | translate }}</label>
                <select class="form-select" aria-label="Parameters select"  formControlName="idApp">
                    <option *ngFor="let vs of versionBBDD" [value]="vs.id">
                        {{ vs.version }} 
                        <ng-container *ngIf="vs.modelNumber">({{vs.modelNumber}})</ng-container>
                    </option>
                </select>
            </div>
        </div>
        
        <div class="profile-app-form">
            <div class="mb-3 w-50">
                <label for="paramSelect" class="form-label">{{ 'newProfile.selectParameter' | translate }}</label>
                <select class="form-select" aria-label="Parameters select"  formControlName="idParam">
                    <option [value]="null" selected></option>
                    <option *ngFor="let param of parameters" [value]="param.id">{{ param.name }} ({{param.version}})</option>
                </select>
            </div>

            <div class="select-file w-50">
                <div class="form-label">
                    <label for="fileSelect">{{ 'newProfile.paramExtra' | translate }} (CSV)</label>
                </div>
                   
                <!-- File upload -->
                <div *ngIf="paramExtraInput?.value">
                    <div class="selected-file">
                        <p> {{ fileNameInput?.value }}</p>
                        <button class="ct-button ct-button--icon" (click)="removeFileApp()">
                            <mat-icon class="ct-icon red" svgIcon="trash-01"></mat-icon>
                        </button>
                    </div>
                </div>

                <div *ngIf="!paramExtraInput?.value">
                    <button class="drop-zone w-100" (drop)="onFilesDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (click)="fileUpload.click()">
                        <mat-icon>cloud_upload</mat-icon>
                        <input id="fileSelect" type="file" (change)="onAppFileSelected($event)" #fileUpload accept=".csv" style="display:none;">
                    </button>
                </div>
            
            </div>    
        </div>

        <!-- [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad -->
        <!--div class="profile-app-form">
            <div class="mb-3 w-50">
                <mat-slide-toggle class="toggle-selector" formControlName="enableDate">{{ 'newProfile.immediateActivation' | translate }}</mat-slide-toggle>
            </div>
        </div>
    
        <div class="profile-app-form">
            <div class="d-flex flex-column mb-3 w-50">
                <label for="activationDate" class="form-label">{{ 'newProfile.activationDate' | translate }}</label>
                <input type=date class="form-control" formControlName="startDate" name="activationDate" id="activationDate">
            </div>
            <div class="d-flex flex-column mb-3 w-50">
                <label for="activationTime" class="form-label">{{ 'newProfile.activationTime' | translate }}</label>
                <input type=time class="form-control" formControlName="startTime" name="activationTime" id="activationTime">
            </div>
        </div!-->
    </form>
</div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="botonera">
    <button class="ct-button ct-button--secondary" (click)="onClose()">
        {{ 'common.cancel' | translate }}
    </button>
    <button class="ct-button ct-button--primary" (click)="submitChanges()">
        {{ 'common.submit' | translate }}
    </button>
</mat-dialog-actions>