import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FormCreateActions, LEVEL_HIERARCHY, RegexpValidators } from 'src/app/util/constants';
import { NotifierService } from 'angular-notifier';
import { ClientService } from '../client.service';
import { Client } from 'src/app/models/client.model';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { FilterableSelectComponent } from 'src/app/components/filterableSelect/filterableSelect.component';

@Component({
  selector: 'app-form-edit-client',
  templateUrl: './form-edit-client.component.html',
  styleUrls: ['./form-edit-client.component.css']
})
export class FormEditClientComponent implements OnInit {
  registerForm!: FormGroup;
  listCommerce: string[] = [];
  operators: Operator[] = []; // Lista de operadores como objetos
  isAdmin: boolean = true;  // Control de visibilidad para perfil ADMIN
  readOnly: boolean = false;

  private clienteBBDD: Client | undefined;

  @ViewChild('OperatorSelect') operatorSelect?:FilterableSelectComponent;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: { clientId: number, readOnly: boolean },
  public formBuilder: FormBuilder,
    public notifier: NotifierService,
    public service: ClientService,
    public utilServicios: UtilService,
    public translate:TranslateService,
    public hierarchy:HierarchyHandlerService,
    public client:RolHandlerService,
    public dialogRef: MatDialogRef<FormEditClientComponent>,
    public dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {
    // Obtener el rol del usuario actual
    this.readOnly = !!this.data.readOnly;

    this.isAdmin = this.utilServicios.isAdmin();
    // Inicializar el formulario
    this.registerForm = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required,Validators.maxLength(45)])],
      cod: [null,Validators.maxLength(45)],
      cif:[null,Validators.maxLength(45)],
      localidad:[null,Validators.maxLength(45)],
      codPostal:[null,Validators.maxLength(10)],
      web:[null, Validators.maxLength(100)],
      email: [null, Validators.compose([Validators.pattern(RegexpValidators.email),Validators.maxLength(255)])] ,   
      phone:[null, Validators.compose([Validators.pattern(RegexpValidators.phone),Validators.maxLength(25)])],
      personContact:[null, Validators.maxLength(45)],
      idOperator:[this.utilServicios.getOperatorId(),Validators.required]
    });

     // Cargar operadores si es admin
    if (this.isAdmin){
      this.registerForm.get('idOperator')?.enable(); 
      await this.loadOperators();
    }
    
    if (this.data) this.cargar()
  }

  

  async cargar(): Promise<void> {
    this.clienteBBDD = await this.service.getItem(this.data.clientId);
    if(this.clienteBBDD){
      this.registerForm.patchValue({
        name: this.clienteBBDD.name,
        cod: this.clienteBBDD.cod,
        cif:this.clienteBBDD.cif,
        localidad: this.clienteBBDD.localidad,
        codPostal: this.clienteBBDD.codPostal,
        web: this.clienteBBDD.web,
        email: this.clienteBBDD.email,
        phone: this.clienteBBDD.phone,
        personContact: this.clienteBBDD.personContact,
        idOperator: this.clienteBBDD.idOperator
      });
    }
  }

  // Método que carga operadores reales
  private async loadOperators(): Promise<void> {
    this.utilServicios.findOperators().subscribe((operators:Operator[]) => {
      this.operators = operators; // Almacena la lista de operadores
    });
  }
  
  private updateInputValidations(){
    this.operatorSelect?.updateValidity();
  }

  onFormSubmit(): void {
    if (this.registerForm.invalid) {
      this.updateInputValidations();
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return
    };
    
    const data = this.registerForm.value;
    let objNew: Client = new Client();
    objNew.name = data.name;
    objNew.cod = data.cod;
    objNew.cif = data.cif;
    objNew.localidad = data.localidad;
    objNew.codPostal = data.codPostal;
    objNew.web = data.web;
    objNew.email = data.email;
    objNew.phone = data.phone;
    objNew.personContact = data.personContact;
    objNew.idOperator = this.utilServicios.getOperatorId() as number;
    
    if (this.isAdmin) objNew.idOperator = data.idOperator;  // Agregar operador seleccionado si es ADMIN
    if (this.clienteBBDD) objNew.id = this.clienteBBDD.id;

    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.client; 
    dialogData.action = ElementAction.udpate;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) this.saveClient(objNew);
    });
  }

  async saveClient(objNew:Client){
    const val = await this.service.addUpdate(objNew);
    if (val!.status >= 0) {
      this.notifier.notify('success', this.translate.instant('success.clientSaved'));
      this.dialogRef.close(FormCreateActions.SAVED);
    }
  }

  formExit(): void {
    this.dialogRef.close(FormCreateActions.EXIT);
  }

  private async delete(clientId: number) {
    const result = await this.service.delete(clientId);
    if (result && result.status === 0) {
      this.notifier.notify('success', this.translate.instant('success.clientDeleted'));
      this.dialogRef.close(FormCreateActions.SAVED)
  
    }
  }

  async onDeleteClientById(clientId: number) {
    const client = await this.service.getItem(clientId); 
    if (client) {
      this.onDelete(client);
    } else {
      console.error('Cliente no encontrado');
    }
  }
  

  onDelete(clientId: number) {
    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.client; 
    dialogData.action = ElementAction.delete;
    dialogData.class = DialogClass.error;
    dialogData.icon = 'trash-01';

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });
  
    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.delete(clientId);
      }
    });
  }
  
}
