<section class="form-container">
    <div class="form-header">
        <h2>{{ 'filter.add' | translate }}</h2>
    </div>
    
    <div class="form-body">
        <form [formGroup]="filterForm">
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label> {{ 'filter.field' | translate }}</mat-label>
                    <mat-select formControlName="field" required>
                        <mat-option *ngFor="let field of fields" [value]="field">{{ field.label | translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="filterForm.get('field')?.hasError('required')">
                    {{ 'error.required.field' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-group" *ngIf="!hasOptions">
                <mat-form-field appearance="outline">
                    <mat-label> {{ 'filter.compare' | translate }}</mat-label>
                    <mat-select formControlName="operator" required>
                        <mat-option *ngFor="let op of operators" [value]="op">{{ op.label | translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="filterForm.get('operator')?.hasError('required')">
                        {{ 'error.required.field' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <ng-template #SelectTemplate>
                <filterable-select formControlName="compareValue" 
                    [options]="options" [title]="'filter.compareValue' | translate"
                    [required]="true"></filterable-select> 
            </ng-template>

            <div class="form-group">
                <ng-container *ngIf="!hasOptions; else SelectTemplate">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'filter.compareValue' | translate }}</mat-label>
                            <input matInput formControlName="compareValue" [type]="inputType" placeholder="{{ 'filter.placeholder.compareValue' | translate }}" required>
                        <mat-error *ngIf="filterForm.get('compareValue')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                          </mat-error>
                    </mat-form-field>
                </ng-container>
            </div>

             <!-- Botón de Enviar -->
            <div class="botonera">
                <button class="ct-button ct-button--secondary" (click)="dialog.close()">{{ 'common.cancel' | translate }}</button>
                <button class="ct-button ct-button--primary" (click)="onFormSubmit()" [disabled]="filterForm.invalid">{{ 'common.search' | translate }}</button>
            </div>
        </form>
    </div>
</section>