export class ConfirmDialogData {
  element?: Element;
  action?: ElementAction;
  class?:DialogClass;
  icon?:string;
  enableCancel: boolean = true;
  enableButtons: boolean = true;
  extraInfo?:string;
}

export enum ElementAction {
  create = 'create',
  udpate = 'udpate',
  delete = 'delete',
  upload = 'upload',
  block = 'block',
  unblock = 'unblock',
  select = 'select',
  send = 'send',
  request = 'request',
  resetPassword = 'resetPassword',
  resetTask = 'resetTask',
  clone = 'clone'
}

export enum Element {
  user = 'user',
  terminal = 'terminal',
  application = 'application',
  version = 'version',
  parameter = 'parameter',
  profile = 'profile',
  alert = 'alert',
  tag = 'tag',
  incidence = 'incidence',
  role = 'role',
  command = 'command',
  file = 'file',
  task = 'task',
  client = 'client',
  subClient = 'subClient',
  operator = 'operator'
}

export enum DialogClass {
  info = 'info',
  warning = 'warning',
  error = 'error'
}

export enum ConfirmDialogActions {
  CONFIRM = 1,
  CANCEL = 0
}

