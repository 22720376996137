import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { NotifierService } from 'angular-notifier';
import { Rol } from 'src/app/models/rol.model';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { RolService } from '../rol.service';
import { FUNCTION_IDS } from 'src/app/util/constants';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { Operator } from 'src/app/models/operator.model';
import { PERMISSIONS_DEFINITIONS, PERMISSIONS_RELATED } from '../permissions';

@Component({
  selector: 'app-form-new-rol',
  templateUrl: './form-new-rol.component.html',
  styleUrls: ['./form-new-rol.component.css']
})
export class FormNewRolComponent implements OnInit {
  registerFormStep1!: FormGroup;
  registerFormStep2!: FormGroup;

  listaRol: Rol[] = [];
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  rolForm!: FormGroup;
  rol: Rol | null = null;
  currentStep: number = 0; // Controlador de paso actual

  operatorsList:Operator[] = [];

  permissions = PERMISSIONS_DEFINITIONS;

  constructor(
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private service: RolService,
    private utilServicios: UtilService,
    public dialogRef: MatDialogRef<FormNewRolComponent>,
    public dialog: MatDialog,
    private translate: TranslateService,
    public handleRol:RolHandlerService
  ) { }

  async ngOnInit(): Promise<void> {
    // Paso 1: Crear un formulario con los campos necesarios
    this.registerFormStep1 = this.formBuilder.group({
      nombre: ['', Validators.compose([Validators.required,Validators.maxLength(45)])],
      description: ['', Validators.compose([Validators.required,Validators.maxLength(200)])],
      idOperator:[this.utilServicios.getOperatorId() ?? '']
    });

    // Paso 2: Crear un formulario para los permisos
    this.registerFormStep2 = this.createPermissionsFormGroup();

    // Escuchar los cambios en los permisos
    this.listenToPermissionChanges();
    if (this.handleRol.isAdmin()) this.loadOperators();
    
    await this.cargar();
  }

  private async loadOperators(){
    this.utilServicios.findOperators().subscribe((response)=> this.operatorsList = response);
  }

  async cargar(): Promise<void> {
    try {
      this.utilServicios.findRol().subscribe((listaRolTemp: Rol[]) => {
        this.listaRol = listaRolTemp;
      });
    } catch (error) {
      this.notifier.notify('error', this.translate.instant('error.general'));
    }
  }

  createPermissionsFormGroup(): FormGroup {
    const group: { [key: string]: FormControl } = {};
    this.permissions.forEach(category => {
      category.items.forEach(permission => {
        group[permission.name] = new FormControl(false);
      });
    });
    return this.formBuilder.group(group);
  }

 async onFormSubmit(): Promise<void> {
    if (this.currentStep === 0 && this.registerFormStep1.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
      if (this.currentStep === 1 && this.registerFormStep2.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
    console.log('Form Data:', FormData); 

    const rolData = this.buildRolForDatabase();
    
    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.role; 
    dialogData.action = ElementAction.create;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });
    
    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.saveRol(rolData);
      }
    });
  }
  
  
  
  private buildRolForDatabase(): any {
    const rolName = this.registerFormStep1.value.nombre;
    const rolDescripcion = this.registerFormStep1.value.description;
    const rolOperator = this.registerFormStep1.value.idOperator;
    const permissions = this.registerFormStep2.value;
  
    const listFunction = Object.keys(permissions).flatMap((key) => {
      const functionKey = key as keyof typeof FUNCTION_IDS;
      const idFunction = FUNCTION_IDS[functionKey];
  
      if (idFunction !== undefined && typeof permissions[functionKey] === 'boolean') {
        return [{ idFunction, status: permissions[functionKey] }];
      }
      return [];
    });
  
    return {
      name: rolName,
      description: rolDescripcion,
      listFunction: listFunction,
      idOperator:rolOperator
    };
  }
  

  onStepChange(event: StepperSelectionEvent) {
    this.currentStep = event.selectedIndex;
    console.log(`Pasaste al paso: ${this.currentStep}`);
    // Validación por paso
    if (this.currentStep === 0 && this.registerFormStep1.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
  
    if (this.currentStep === 1 && this.registerFormStep2.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
  }
  
  
  // Lógica para guardar el rol en la base de datos
  async saveRol(objNew: any) {
    try {
      const response = await this.service.addRol(objNew);
  
      if (response && response.status >= 0) {
        this.notifier.notify('success', this.translate.instant('success.addRol'));
        this.utilServicios.getRolsAndOperators();
        this.dialogRef.close(1);  
      } else {
        this.notifier.notify('error', this.translate.instant('error.addRol'));
      }
    } catch (error) {
      this.notifier.notify('error', this.translate.instant('error.general'));
    }
  }

  // Salir del formulario
  onClose(): void {
    this.dialogRef.close();
  }

  // Lógica para escuchar cambios en los permisos de manera dinámica
private listenToPermissionChanges(): void {
  const permissionGroups = PERMISSIONS_RELATED;

  // Itera sobre cada grupo de permisos y registra los listeners
  permissionGroups.forEach(group => {
    const viewControl = this.registerFormStep2.get(group.view);
    const editControl = group.edit ? this.registerFormStep2.get(group.edit) : null;
    const deleteControl = group.delete ? this.registerFormStep2.get(group.delete) : null;

    // Lógica para cambios en "view"
    viewControl?.valueChanges.subscribe(value => {
      if (!value) {
        editControl?.setValue(false, { emitEvent: false });
        deleteControl?.setValue(false, { emitEvent: false });
      }
    });

    // Lógica para cambios en "edit"
    editControl?.valueChanges.subscribe(value => {
      if (value) {
        viewControl?.setValue(true, { emitEvent: false });
      }
    });

    // Lógica para cambios en "delete"
    deleteControl?.valueChanges.subscribe(value => {
      if (value) {
        viewControl?.setValue(true, { emitEvent: false });
        editControl?.setValue(true, { emitEvent: false });
      }
    });
  });
}

}