export type InstalledAppFields = "appLabel" |
"packageName" |
"versionCode" |
"versionName" |
"commonParamVersion" |
"specificParamVersion";

export class InstalledApp {
    appLabel:string;
    packageName:string;
    versionCode:number;
    versionName:string;
    commonParamVersion:string;
    specificParamVersion:string;
    fromProfile:boolean = false;

    constructor(appLabel:string,packageName:string,versionCode:number,versionName:string,commonParamVersion:string,specificParamVersion:string){
        this.appLabel = appLabel;
        this.packageName = packageName;
        this.versionCode = versionCode;
        this.versionName = versionName;        
        this.commonParamVersion = commonParamVersion;
        this.specificParamVersion = specificParamVersion;
    }
}