import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tag } from 'src/app/pages/views/config/tags/tag.model';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'app-tags-display',
  standalone: true,
  imports: [CommonModule, MatLegacyTooltipModule],
  templateUrl: './tags-display.component.html',
  styleUrls: ['./tags-display.component.css']
})
export class TagsDisplayComponent implements OnInit{
  @Input() tags:Tag[] = [];
  @Input() limit?:number;
  
  tagsList:Tag[] = [];

  public ngOnInit(): void {
      this.tagsList = [...this.tags].slice(0, (this.limit ?? this.tags.length));
  }

  public getTagLabel(tag:unknown):string {
    return (tag as Tag).name;
  }
  
  public getTagColor(tag:unknown):string {
    return (tag as Tag).color;
  }

  public get tagsListed(){
    return this.tags.map((tag)=> tag.name).toString();
  }
}
