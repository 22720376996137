<div class="apps-container">
    <form [formGroup]="searchAppForm">
         <!-- Select for Apps and Versions -->
        <div class="app-version-row">
            <div class="app-version-column" >
                <filterable-select  formControlName="application" [options]="appsBBDD" [title]="'profiles.selectApps' | translate "></filterable-select> 
            </div>
            
            <div class="app-version-column">
                <filterable-select  formControlName="version" [options]="versionBBDD" [title]="'profiles.selectVersions' | translate "></filterable-select>
            </div>
        </div>
    </form>
    <!-- Parameters and File Upload Area -->
    <div class="parameter-file-column">
        <ng-container  *ngFor="let selectedApp of list; let i = index">
            <profile-app-box class="w-100" [data]="selectedApp" (remove)="removeApp($event)" [parameters]="parameters"></profile-app-box>
        </ng-container>
    </div>
</div>