import { Component } from '@angular/core';

@Component({
  selector: 'app-form-edit-txt-graf',
  templateUrl: './form-edit-txt-graf.component.html',
  styleUrls: ['./form-edit-txt-graf.component.css']
})
export class FormEditTxtGrafComponent {

}
