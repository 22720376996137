import { Injectable } from "@angular/core";
import { LEVEL_HIERARCHY } from "../util/constants";
import { Users } from "../models/users.model";
import { AuthService } from "../auth/auth.service";
import { HierarchyOptionsService } from "./hierarchy-options.service";
import { FilterField } from "../models/filter.model";
import { FilterableValue, OPERATION_FILTER } from "../models/FilterAndPagBean";
import { BehaviorSubject } from "rxjs";


enum HIERARCHY_COLUMNS {
    idOperator = 'idOperator',
    idClient = 'idClient',
    idSubClient = 'idSubClient',
}

@Injectable({
    providedIn: 'root',
})
  
export class HierarchyHandlerService {
    level = LEVEL_HIERARCHY;

    private hasData$: BehaviorSubject<boolean> = new BehaviorSubject(false);


    constructor(private _auth:AuthService,
        private filterOptions:HierarchyOptionsService
    ){
        this.filterOptions.state.subscribe((result)=>{
            if (result.size === 3) {
                this.hasData$.next(true)
            } else {
                this.hasData$.next(false)
            }
        })
    }


    get hasData(){
        return this.hasData$;
    }

    clearData(){
        this.filterOptions.cleanState();
    }

    /**
     * @param user - Users     * 
     * Este método devuelve el nivel de jerarquía de un usuario.
     * @returns LEVEL_HIERARCHY
     */
    getLevel(user:Users): LEVEL_HIERARCHY {
        const idLevel1 = user.operator?.id;
        const idLevel2 = user.client?.id;
        const idLevel3 = user.subClient?.id;
        
        if(idLevel1 && !idLevel2 && !idLevel3) return LEVEL_HIERARCHY.LEVEL_1;
        if(idLevel1 && idLevel2 && !idLevel3) return LEVEL_HIERARCHY.LEVEL_2;
        if(idLevel1 && idLevel2 && idLevel3) return LEVEL_HIERARCHY.LEVEL_3;
        return LEVEL_HIERARCHY.ADMIN;
    }

    /**
     * @param levelId 
     * Este método valida si el usuario loggeado puede visualizar elementos 
     * según el nivel de jerarquía al que pertenece. 
     * @returns boolean
     */
    get userLevel(): LEVEL_HIERARCHY {
        const userSession = this._auth.currentUserValue();
        return this.getLevel(userSession);
    }

    /**
     * @param levelId 
     * Este método valida si el usuario loggeado puede visualizar elementos 
     * según el nivel de jerarquía al que pertenece. 
     * @returns boolean
     */
    canByLevel(levelId:LEVEL_HIERARCHY): boolean {
        const userSession = this._auth.currentUserValue();
        const userLevel = this.getLevel(userSession);
        if(levelId > userLevel) return true
        return false;
    }

    /**
     * @param levelId 
     * Este método valida si el usuario loggeado puede visualizar elementos 
     * según el nivel de jerarquía al que pertenece. 
     * @returns boolean
     */
    configureUsersByLevel(levelId:LEVEL_HIERARCHY): boolean {
        const userSession = this._auth.currentUserValue();
        const userLevel = this.getLevel(userSession);
        if(levelId >= userLevel) return true
        return false;
    }

    public get level_1(): number | undefined {
        const userSession = this._auth.currentUserValue();
        return userSession.operator?.id;
    }
    
    public get level_2(): number | undefined {
        const userSession = this._auth.currentUserValue();
        return userSession.client?.id;
    }
    
    public get level_3(): number | undefined {
        const userSession = this._auth.currentUserValue();
        return userSession.subClient?.id;
    }

    public filterColumns(tableColumns:string[]):string[]{
        return tableColumns.filter( (col) => {
            if (col === HIERARCHY_COLUMNS.idOperator) {
                return this.canByLevel(this.level.LEVEL_1);
            }
            if (col === HIERARCHY_COLUMNS.idClient) {
                return this.canByLevel(this.level.LEVEL_2);
            }
            if (col === HIERARCHY_COLUMNS.idSubClient) {
                return this.canByLevel(this.level.LEVEL_3);
            }
            return true; 
        });
    }

    public hierarchyFilter(level:string, column:string, filterBy?:string, filterValue?:number):FilterField{
        const options = this.filterOptions.getFilteredLevelToOption(level, filterBy, filterValue);
        return new FilterField(column, `hierarchy.${level.toLowerCase()}`,'long', options);
    }

    public getFilterFields(level1Field:string, level2Field:string, level3Field:string): FilterField[]{
        const filters:FilterField[] = [];
        const userLevel = this.userLevel; 
        switch(userLevel){
            case (this.level.LEVEL_1):
                filters.push(this.hierarchyFilter('LEVEL_2', level2Field, 'operator.id', this.level_1));        
                filters.push(this.hierarchyFilter('LEVEL_3', level3Field, 'idOperator', this.level_1));
                break;
            case (this.level.LEVEL_2):
                filters.push(this.hierarchyFilter('LEVEL_3', level3Field, 'client.id', this.level_2));
            break;
            case (this.level.ADMIN):
                filters.push(this.hierarchyFilter('LEVEL_1', level1Field));        
                filters.push(this.hierarchyFilter('LEVEL_2', level2Field));        
                filters.push(this.hierarchyFilter('LEVEL_3', level3Field));
            break
        }
        return filters;
    }

    public getFilterFieldsAsync(filtersList:FilterField[], level1Field:string = 'idOperator', level2Field:string = 'idClient', level3Field:string = 'idSubClient'){
        this.hasData.subscribe((response)=>{
            if(response) filtersList.push.apply(filtersList, this.getFilterFields(level1Field, level2Field, level3Field));
        });
    }
    
    public getFilterableValues(hierarchy:({idOperator?:number,idClient?:number,idSubClient?:number} | null) = null): FilterableValue[] {
        const filterablesValues = [];
        const idOperator = hierarchy?.idOperator ?? this.level_1,
            idClient = hierarchy?.idClient ?? this.level_2,
            idSubClient = hierarchy?.idSubClient ?? this.level_3;
        if(idOperator) filterablesValues.push(new FilterableValue("idOperator", idOperator, "long", OPERATION_FILTER.EQUALS))
        if(idClient) filterablesValues.push(new FilterableValue("idClient", idClient, "long", OPERATION_FILTER.EQUALS))
        if(idSubClient) filterablesValues.push(new FilterableValue("idSubClient", idSubClient, "long", OPERATION_FILTER.EQUALS))
        
        return filterablesValues;
    }

    public getNullableHierarchyFilters(hierarchy:({idOperator?:(number | null),idClient?:(number | null),idSubClient?:(number | null)} | null) = null): FilterableValue[] {
        const filterablesValues = [];
        if(hierarchy){
            const { idOperator, idClient, idSubClient } = hierarchy;
            filterablesValues.push(new FilterableValue("idOperator", idOperator ?? '', "long", idOperator ? OPERATION_FILTER.EQUALS : OPERATION_FILTER.IS_NULL));
            filterablesValues.push(new FilterableValue("idClient", idClient ?? '', "long", idClient ? OPERATION_FILTER.EQUALS : OPERATION_FILTER.IS_NULL));
            filterablesValues.push(new FilterableValue("idSubClient", idSubClient ?? '', "long", idSubClient ? OPERATION_FILTER.EQUALS : OPERATION_FILTER.IS_NULL));
        }  
        return filterablesValues;
    }

    public get hierarchyLevelName(): string{
        const userSession = this._auth.currentUserValue();
        switch(this.getLevel(userSession)){
            case(LEVEL_HIERARCHY.LEVEL_1):
                return userSession.operator?.name ?? '';
            case(LEVEL_HIERARCHY.LEVEL_2):
                return userSession.client?.name ?? '';
            case(LEVEL_HIERARCHY.LEVEL_3):
                return userSession.subClient?.name ?? '';
            default:
                return '';
        }
    }

}