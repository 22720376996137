import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData, ConfirmDialogActions, Element, DialogClass, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FileInfoGet, GetFile } from 'src/app/models/getFile';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { EventsService } from 'src/app/services/events.service';
import { UtilService } from 'src/app/services/util.service';
import { STORAGE_TYPES } from 'src/app/util/constants';

@Component({
  selector: 'app-get-file',
  templateUrl: './get-file.component.html',
  styleUrls: ['./get-file.component.css']
})
export class GetFileComponent implements OnInit {
  @Input('data') vendingMachine!:VendingMachineAtento;
  hasVendingMachineSelected:boolean = false;
  verPaquete:boolean = true;
  storageTypes = STORAGE_TYPES.map((type)=> ({value:type, label:this.translate.instant(`maintenace.storageType.${type}`) }));
  firstFormGroup!: FormGroup;
  selectedVendingMachines = new MatLegacyTableDataSource<VendingMachineAtento>();

  isCloseFile: boolean = false;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    public service: UtilService,
    public formBuilder: FormBuilder,
    public notifier: NotifierService,
    public translate:TranslateService,
    public eventsHandler:EventsService,
    public dialog: MatDialog
  ) { }

  async ngOnInit(): Promise<void> {
    this.firstFormGroup = this.formBuilder.group({
      storageType: ['', Validators.required], 
      path: ['', Validators.compose([Validators.required,Validators.maxLength(100)])], 
      packageName: ['', Validators.maxLength(100)], 
      //fileName: ['', Validators.required], 
    });


    this.firstFormGroup.get('storageType')?.valueChanges.subscribe((value: string) => {
      this.verPaquete=true;
      if(value==='publicDirectory'){
        this.verPaquete=false;
      }
    });


    if(this.vendingMachine){
      this.selectedVendingMachines.data.push(this.vendingMachine);
      this.hasVendingMachineSelected = true
    }
  }

  onFormSubmit(): void {
    if (!this.selectedVendingMachines.data.length) {
      this.notifier.notify('warning', this.translate.instant('warning.terminalIsMissing'));
      return;
    }

    if (this.firstFormGroup.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
    const { packageName, path, storageType } = this.firstFormGroup.value;
    const fichero:FileInfoGet = new FileInfoGet
    fichero.storageType=storageType
    //fichero.fileName=fileName
    fichero.path=path
    if(storageType!=='publicDirectory'){
        fichero.packageName=packageName
        if(!packageName){
          alert(1)
          this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
          return;
        }
    }

    const objNew : GetFile = new GetFile
    objNew.listFile = [fichero];

    objNew.machines = this.selectedVendingMachines.data.map((vm)=>{ 
      const vendingMachine:VendingMachineAtento = new VendingMachineAtento;
      vendingMachine.id = vm.id;
      return vendingMachine;
    });

    const dialogData = new ConfirmDialogData(); 
    dialogData.element = Element.file;
    dialogData.action = ElementAction.request;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {        
          const response = await this.service.setGetFileAtento(objNew)
          this.resetFormState();       
          if(response!.status>=0){
            this.notifier.notify('success', this.translate.instant('success.fileSaved'));
            this.sendEventToTerminal();
          }
      }
    });
  }

  private sendEventToTerminal(){
    if(this.vendingMachine?.id) {
      this.eventsHandler.updateTerminalDetail(this.vendingMachine.id);
    }
  }

  private resetFormState(){
    this.resetFormControlStates();
    if(!this.vendingMachine?.id){
      this.selectedVendingMachines = new MatLegacyTableDataSource<VendingMachineAtento>();
    }
  }

  private resetFormControlStates(){
    this.firstFormGroup.reset();
    setTimeout(()=>{
      Object.keys(this.firstFormGroup.controls).forEach((key) => {
        const control = this.firstFormGroup.controls[key];
        control.setErrors(null);
      });
    })
  }

}
