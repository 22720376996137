import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { InputFilesComponent } from 'src/app/components/input-files/input-files.component';
import { FileDisplayComponent } from 'src/app/components/file-display/file-display.component';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Factories, TerminalModel } from 'src/app/models/factories.model';
import { FirmwareUpload } from 'src/app/models/firmwareUpload.model';
import { AppsService } from '../aplications.service';
import { NotifierService } from 'angular-notifier';
import { Aplications } from 'src/app/models/aplications.model';
import { convertFileToBase64, extractVersion, isApkFile } from 'src/app/util/util';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogModule, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FactoriesService } from 'src/app/services/factories.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { ApkInfo } from 'src/app/models/apkInfo.model';
import { ApkUtilsService } from 'src/app/util/apkUtilsService';
import { FilterableSelectComponent } from 'src/app/components/filterableSelect/filterableSelect.component';

@Component({
  selector: 'edit-version',
  standalone: true,
  imports: [CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacySelectModule,
    MatLegacyDialogModule,
    MatIconModule,
    InputFilesComponent,
    FileDisplayComponent,
    FilterableSelectComponent
  ],
  templateUrl: './edit-version.component.html',
  styleUrls: ['./edit-version.component.css']
})
export class EditVersionComponent implements OnInit {
  version?: Aplications;
  application!: Aplications;
  factories: Factories = new Factories;
  
  versionForm!: FormGroup;
  terminalModels: TerminalModel[] = [];  
  apkInfo: ApkInfo = {
      packageName: '',
      version: '',
    };
  isApk = false;
  fileUploadError=false;

  @ViewChild('manuFacSelect') manuFacSelect?:FilterableSelectComponent;
  
  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { application:Aplications, versionId: number},
    private dialogRef:MatLegacyDialogRef<EditVersionComponent>,
    private dialog:MatLegacyDialog,
    private formBuilder:FormBuilder,
    private appService:AppsService,
    private notifier:NotifierService,
    private translate:TranslateService,
    private factoriesService:FactoriesService,
    private apkUtilService: ApkUtilsService
  ){
    this.versionForm = this.formBuilder.group({
      fileName: [null, Validators.required],
      fileToUpload: [null],
      version: ['', [Validators.required,Validators.maxLength(45)]],
      description: ['', [Validators.maxLength(200)]], // Campo de descripción con validaciones
      manufacID: [null, Validators.required],
      modelNumber: [null],
    });
  }
    
  async ngOnInit(): Promise<void> {
    await this.loadFactoriesData();
    // Segundo formulario para gestionar los archivos y la versión
    this.versionForm.get('manufacID')?.valueChanges.subscribe((value)=>{
      if(value) this.terminalModels = this.factories ? this.factories.getModelList(value) : [];
    });

    this.loadVersionData();
  }

  async loadVersionData() {
    if(!this.data.versionId) return;

    const { versionId } = this.data;
    const response = await this.appService.get(versionId);
    if (response && response.data) {
      const version = response.data;
      this.updateVersionForm(version);
    }
  }

  private async loadFactoriesData(){
    const response = await this.factoriesService.getFactoriesList();
    if(response && response.data){
      this.factories.list = response.data;
    }
  }

  private updateVersionForm(app:Aplications){
    this.versionForm.patchValue({
      fileName: app.fileName,
      version: app.version,
      description: app.description,
    });
    this.versionForm.get('manufacID')?.setValue(app.manufacID);
    this.versionForm.get('modelNumber')?.setValue(app.modelNumber);
  }

  get fileNameInput(){
    return this.versionForm.get('fileName');
  }

  get fileInput(){
    return this.versionForm.get('fileBase64');
  }

  validateInput(){
    this.manuFacSelect?.updateValidity();
  }

  // Método que maneja la subida y confirmación de la nueva versión
  async onSaveVersion(): Promise<void> {
    // Validar si el formulario es válido
    if (this.versionForm.invalid) {
      this.validateInput();
      this.fileUploadError = true;
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }

    if(this.isApk && (this.data.application.pakage !== this.apkInfo.packageName)){
      this.notifier.notify('warning', this.translate.instant('warning.diferentPackage'));
      return;
    }

    const dialogData = new ConfirmDialogData();
        dialogData.element = Element.version; 
        dialogData.action = (this.data.versionId) ? ElementAction.udpate : ElementAction.create;
        dialogData.class = DialogClass.info;
        dialogData.icon = "save-01";
    
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '25%',
          panelClass: 'custom-modalbox',
          data: dialogData
        });
    
    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const { versionId } = this.data;
        if(versionId){
          this.updateVersion(versionId)
        } else {
          this.createVersion();
        }
      }
    });
       
  }

  private async createVersion(){
    // Verificar que haya un archivo y que cumpla con los requisitos de nombre
    const { fileToUpload, fileName, description, manufacID, modelNumber } = this.versionForm.value;

    // Crear el objeto de aplicación (Aplications) basado en la nueva versión
    const newVersion: Aplications = new Aplications;
    newVersion.version = this.versionForm.get('version')?.value?.trim();
    newVersion.description = description;
    newVersion.manufacID = manufacID;
    newVersion.modelNumber = modelNumber;
    newVersion.fileName = fileName;

    // Asigno parámetros de la aplicación global
    const { name, pakage, listEtiquetas, idOperator, idClient, idSubClient } = this.data.application;
    newVersion.name = name;
    newVersion.pakage = pakage.trim();
    newVersion.listEtiquetas = listEtiquetas;

    // Crear un objeto FirmwareUpload para hacer la actualización
    let objNew: FirmwareUpload = new FirmwareUpload();
    objNew.fileList = [newVersion]; // Añadimos la nueva versión a la lista de archivos    
    objNew.id = this.data.versionId;
    objNew.idOperator = idOperator;
    objNew.idClient = idClient;
    objNew.idSubClient = idSubClient;

    // Actualizamos en el backend la nueva versión
    const response = await this.appService.addUpdateWithFile(objNew, fileToUpload);

    if(response && response.status >= 0){
      // Actualizar dataSource para reflejar el cambio
      this.notifier.notify('success', this.translate.instant('success.versionCreated'));
      this.dialogRef.close(ConfirmDialogActions.CONFIRM);
    } 
  }

  private async updateVersion(versionId:number){
    // Verificar que haya un archivo y que cumpla con los requisitos de nombre
    const { fileName, description, manufacID, modelNumber } = this.versionForm.value;

    // Crear el objeto de aplicación (Aplications) basado en la nueva versión
    const newVersion: Aplications = new Aplications;
    newVersion.version = this.versionForm.get('version')?.value?.trim();
    newVersion.description = description;
    newVersion.manufacID = manufacID;
    newVersion.modelNumber = modelNumber;
    newVersion.fileName = fileName;

    // Asigno parámetros de la aplicación global
    const { name, pakage, listEtiquetas, idOperator, idClient, idSubClient } = this.data.application;
    newVersion.name = name;
    newVersion.pakage = pakage.trim();
    newVersion.listEtiquetas = listEtiquetas;

    // Crear un objeto FirmwareUpload para hacer la actualización
    let objNew: FirmwareUpload = new FirmwareUpload();
    objNew.fileList = [newVersion]; // Añadimos la nueva versión a la lista de archivos    
    objNew.id = versionId;
    objNew.idOperator = idOperator;
    objNew.idClient = idClient;
    objNew.idSubClient = idSubClient;

    // Actualizamos en el backend la nueva versión
    const response = await this.appService.addUpdate(objNew);

    if(response && response.status >= 0){
      // Actualizar dataSource para reflejar el cambio
      this.notifier.notify('success', this.translate.instant('success.versionUpdated'));
      this.dialogRef.close(ConfirmDialogActions.CONFIRM);
    } 
  }
   
  onClose(){
    this.dialogRef.close();
  }

  resetFileSelection(){
    this.versionForm.reset(); // Reiniciar el formulario
  }
  
  // Método que se llama cuando se selecciona un archivo
  async onFileSelected(files: File[]): Promise<void> {

    if (files.length === 0) {
      this.notifier.notify('warning', this.translate.instant('error.fileEmpty'));
      return;
    }

    const file = files[0];
    this.isApk = isApkFile(file);
    if (this.isApk) {
      this.apkInfo = await this.apkUtilService.processApk(file);
    } else {
      this.apkInfo.version = extractVersion(file.name)
    }


    this.versionForm.patchValue({
      fileToUpload: file,
      fileName:file.name,
      version: this.apkInfo.version,
      packageName: this.apkInfo.packageName
    });


    if (this.isApk) {
      this.versionForm.get('version')?.disable();
    } else {
      this.versionForm.get('version')?.enable();
    }
  }
}
