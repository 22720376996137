import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { Alerts } from 'src/app/models/alerts.model';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { AlertsService } from './alerts.service';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { StadisticBean } from 'src/app/models/stadisticBean.model';
import { UtilService } from 'src/app/services/util.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TabsComponent } from '../../monitoring/tabs/tabs.component';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { AuthService } from 'src/app/auth/auth.service';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { Operator } from 'src/app/models/operator.model';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TranslateService } from '@ngx-translate/core';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';

const BaseColumns = {  
  terminal: 'nameMachineID',
  dateCreate: 'dateCreate',
  operator: 'operator',
  cod: 'cod', 
  description:'description', 
  channel:'channel',
  priority: 'priority', 
  status: 'status',
  view: 'view'
}
const { terminal, operator, ...Columns } = BaseColumns;

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class AlertsComponent implements OnInit, AfterViewInit {
  displayedColumns =  Object.keys(Columns);
  isOnTerminalContext:boolean = false;
  dataSource = new MatTableDataSource<Alerts>();
  searchTerminal = "";
  isAdmin: boolean = true; 
  pageLength=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId:string | null = Columns.dateCreate+"" ;
  direccion:string | null ="DESC";
  exportFileName: string | undefined;


  request: StadisticBean = new StadisticBean();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  SATUS_OPTIONS:FilterOption[] = [
    { value:"0", label: this.translate.instant("alerts.statusClosed") },
    { value:"1", label: this.translate.instant("alerts.statusOpen") },
  ]
  
  FILTER_COLUMNS:{ field:string, type: FieldType, options?:FilterOption[]}[] = [
    { field:'dateCreate', type:'date' },
    { field:'cod', type:'string' },
    { field:'description', type:'string' },
    { field:'channel', type:'string' },
    { field:'status', type:'number', options:this.SATUS_OPTIONS},
  ]
  
  filterColumns:FilterField[] = this.FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `alerts.${column.field}`, column.type, column.options);
    return fielterField;
  })
  filterList: FilterableValue[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { terminalId:number },
      private servicios : AlertsService,
      private utilService : UtilService,
      public dialog: MatDialog,
      private authService:AuthService,
      private translate: TranslateService,
      public commonService : CommonService,
      public rol:RolHandlerService,
      private monitoringService : MonitoringService,
      private dialogRef: MatDialogRef<TabsComponent>,
      private events:EventsService
  ) {}

  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  async lanzarLLamada(){
    const idVMFilter = this.getFilterableValue("idVM");
    const idOperatorFilter = this.getFilterableValue("idOperator");

    if(this.data.terminalId && !idVMFilter){
      const idVM = this.data.terminalId;
      this.filterList.push(new FilterableValue("idVM", idVM, "long", "EQUALS"));
    }

    let userSession = this.authService.currentUserValue();
    if(userSession?.operator && !idOperatorFilter){
      this.filterList.push(new FilterableValue("idOperator", `${userSession.operator.id}`, "long", "EQUALS"));
    }

    const paginatorState = this.getPaginatorState();
    this.request.filterAndPagBean =  new FilterAndPagBean(
      this.direccion, 
      this.sortId, 
      this.filterList, 
      paginatorState.startPage,
      paginatorState.endPage,
      paginatorState.pageIndex
    );

    this.request.customId = this.searchTerminal;
    let value = await this.servicios.find(this.request);
    let pagCount = await this.servicios.countTotal(this.request);
    if(value){
      this.dataSource = new MatTableDataSource<Alerts>(value.data);
      if(pagCount){
        this.pageLength = pagCount.data?.total ?? 0;
      }
    }
  }

  private getPaginatorState(){
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;

    return { startPage, endPage, pageIndex };
  }

  async cargar(){   
    //operadores
    this.utilService.findOperators().subscribe((operators:Operator[]) => {
      const operationsOptions = operators.map((op:Operator)=>{ return { label:op.name, value:(op.id as number) }});
      this.filterColumns.push(new FilterField('idOperator', 'stadisticsFunctionally.idOperator', 'number', operationsOptions));
    });
  }

  private async loadMachines(){
    const request = new UsersConsoleRequest;
    request.filterAndPagBean = new FilterAndPagBean(null,null,[],null,null,null);
    const response = await this.monitoringService.find(request);
    if(response && response.data){
      const machinesOptions = response.data.map((machine:VendingMachineAtento)=> new FilterOption(machine.serialNumber as string, machine.id as number)); 
      this.filterColumns.unshift(new FilterField('idVM','common.terminalSN', 'long', machinesOptions));
    }
  }


  ngOnInit(): void {
    this.isAdmin = this.rol.isAdmin();
    this.exportFileName = this.translate.instant('export.fileAlert');
    if (this.isAdmin && !this.data.terminalId) {
      this.displayedColumns = [ operator, ...this.displayedColumns];
      this.cargar();
    }
 
    if(this.data.terminalId){
      this.isOnTerminalContext = true;
    } else {
      this.displayedColumns = [ terminal, ...this.displayedColumns];
    }
    this.loadMachines();
    this.lanzarLLamada();
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(){
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    this.sortId=active;
   
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  onView(row: Alerts): void {
    this.dialogRef = this.dialog.open(TabsComponent, {
      width: '60%',
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel',
      data: { terminalId: row.idVM }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        console.log('CONFIRM received from dialog window');
      } else if (result === 0) {
        console.log('CANCEL received from dialog window');
      }
    });

  }

  getStatusClass(status: number): string {
    if (status === 1) {
      return 'status-open';  // Abierta
    } else if (status === 0) {
      return 'status-closed';  // Cerrada
    } else {
      return 'status-undefined';  // Indefinido
    }
  }
 
  getNotExportColumns(){
    const actionsColIdx = (this.displayedColumns.length-1);
    return [actionsColIdx];
  }
}

