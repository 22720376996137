export interface ResponseData {
  status: number;
  data: StadisticDetail[];
}
export interface StadisticDetail{
  id: number;
  idVM: number;
  period: string;
  packageName: string;
  type: string;
  data: string; 
  chartType: ChartType;
}

export enum ChartType {
  LINE = 'line',
  AREA = 'area',
  BAR_HORIZONTAL = 'bar-horizontal',
  BAR_VERTICAL = 'bar-vertical',
  PIE = 'pie',
  DONUT = 'donut',
  SCATTER = 'scatter',
  BUBBLE = 'bubble',
  RADAR = 'radar',
  HEATMAP = 'heatmap',
  TREEMAP = 'treemap',
  SANKEY = 'sankey',
}

