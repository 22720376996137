import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { FileDisplayComponent } from 'src/app/components/file-display/file-display.component';
import { Parameters } from 'src/app/models/parameters.model';
import { ParamExtraFiles, PerfilApp } from 'src/app/models/perfilApp.model';
import { DATE_FORMATS } from 'src/app/util/constants';
import { convertFileToBase64, parseAndSetDateStart, parseDateToFormat, parsedDateFromTerminalFormat } from 'src/app/util/util';


@Component({
  standalone:true,
  imports:[CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule, 
    MatLegacySelectModule, 
    MatLegacyButtonModule,
    MatIconModule,
    TranslateModule,
    MatLegacySlideToggleModule,
    FileDisplayComponent
  ],
  selector: 'profile-app-box',
  templateUrl: './profile-app-box.component.html',
  styleUrls: ['./profile-app-box.component.css']
})
export class ProfileAppBoxComponent implements OnInit{
  @Input('data') selectedApp: PerfilApp = new PerfilApp;
  @Input() parameters: Parameters[] = [];
  @Input() removeButton:boolean = true;
  @Output() remove = new EventEmitter();

  perfilAppForm: FormGroup;
  constructor(){
    this.perfilAppForm = new FormGroup({
      enableDate:new FormControl(false),
      startDate:new FormControl(''),
      startTime:new FormControl(''),
      idParam: new FormControl(null)
    })

    this.perfilAppForm.valueChanges.subscribe((value)=>{
      if(value.enableDate){
        this.selectedApp.dateStart = null;
        this.perfilAppForm.get('startDate')?.disable({emitEvent:false});
        this.perfilAppForm.get('startTime')?.disable({emitEvent:false});
      } else {
        this.perfilAppForm.get('startDate')?.enable({emitEvent:false});
        this.perfilAppForm.get('startTime')?.enable({emitEvent:false}); 
        this.selectedApp.dateStart = parseAndSetDateStart(value.startDate, value.startTime);
      }
    });

    this.perfilAppForm.get('idParam')?.valueChanges.subscribe((value)=> this.selectedApp.idParam = (value ? Number(value) : null));
  }


  ngOnInit(): void {
    const { date, hour } = parsedDateFromTerminalFormat(this.selectedApp.dateStart);
    this.perfilAppForm.patchValue({
      enableDate: !this.selectedApp.dateStart,
      startDate: date,
      startTime: hour,
      idParam: this.selectedApp.idParam ?? null
    });
  }

  public removeApp(app: PerfilApp) {
    this.remove.emit(app);
  }

  removeFileApp(): void {
    this.selectedApp.fileName = '';
    this.selectedApp.paramExtra = '';
  }

  // Drag and drop events
  onFilesDrop(event: any, appSelect: PerfilApp) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      this.onAppFileSelected({ target: { files: [file] } }, appSelect);
    }
  }

  async onAppFileSelected(event: any, appSelect: PerfilApp): Promise<void> {
    const file = event.target.files[0];
    if (file) {
      appSelect.fileName = file.name;
  
      // Obtener la extensión del archivo y convertir a mayúsculas
      const fileExtension = file.name.split('.').pop()?.toUpperCase() || '';
      appSelect.typeParamExtra = (fileExtension === ParamExtraFiles.CSV || fileExtension === ParamExtraFiles.ZIP) ? fileExtension : null;
  
      const convertedFile = await convertFileToBase64(file);
      appSelect.paramExtra = convertedFile as string;
    }
  }
  
  onDragOver(event: any) {
    event.preventDefault();
  }

  onDragLeave(event: any) {
    event.preventDefault();
  }

}