<main id="main" class="main">
  <div class="pagetitle">
    <span class="header-large">{{ 'customizableAlerts.title' | translate }}</span>
    <div>
      <button class="ct-button ct-button--secondary" (click)="refrescar()">
        <mat-icon class="ct-icon" svgIcon="refresh-ccw-01"></mat-icon> 
        <span class="hide-on-mobile" >{{'common.refresh' | translate }}</span>
      </button>

      <ng-container *ngIf="rol.canByInput(rol.functIds.edit_custom_alerts)">
          <button class="ct-button ct-button--primary" (click)="onNew()">
              <mat-icon class="ct-icon" svgIcon="plus"></mat-icon> 
              <span class="hide-on-mobile" >{{ 'customizableAlerts.newAlert' | translate }}</span>
          </button>
      </ng-container>
    </div>
  </div>

  <!-- Filtro de perfiles -->
  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" style="display:none;" i18n>
    {{ 'customizableAlerts.tableDescription' | translate }}
  </div>

  <div class="mat-elevation-z2 tablaPersonal">
    <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" 
    matSort (matSortChange)="announceSortChange($event)"
    matTableExporter #exporter="matTableExporter" [hiddenColumns]="getNotExportColumns()">

      <!-- typeAlert Column -->
      <ng-container matColumnDef="typeAlert">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'customizableAlerts.typeAlert' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ getTypeLabel(element.typeAlert) }}</span>
        </td>
      </ng-container>

      <!-- Operator Column -->
      <ng-container matColumnDef="idOperator">
        <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>{{ 'hierarchy.level_1' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.idOperator | levelName:'LEVEL_1' }} </td>
      </ng-container>

      <!-- Client Column -->
      <ng-container matColumnDef="idClient">
          <th mat-header-cell *matHeaderCellDef class="mat-column-region">
              <div mat-sort-header>{{ 'hierarchy.level_2' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element" class="mat-column-region"> {{element.idClient | levelName:'LEVEL_2' }} </td>
      </ng-container>

      <!-- SubClient Column -->
      <ng-container matColumnDef="idSubClient">
          <th mat-header-cell *matHeaderCellDef class="mat-column-region">
              <div mat-sort-header>{{ 'hierarchy.level_3' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element" class="mat-column-region"> {{element.idSubClient | levelName:'LEVEL_3' }} </td>
      </ng-container>

      <!-- description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'customizableAlerts.description' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ getDescriptionLabel(element.typeAlert) }} {{element.operation}} {{element.value}}</span>
        </td>
      </ng-container>


      <!-- priority Column -->
      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'customizableAlerts.priority' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.priority === 0">{{ 'customizableAlerts.priorityOption.none' | translate }}</span>
          <span *ngIf="element.priority === 1">{{ 'customizableAlerts.priorityOption.low' | translate }}</span>
          <span *ngIf="element.priority === 2">{{ 'customizableAlerts.priorityOption.medium' | translate }}</span>
          <span *ngIf="element.priority === 3">{{ 'customizableAlerts.priorityOption.high' | translate }}</span>
        </td>
      </ng-container>


      <!-- dateCreate Column -->
      <ng-container matColumnDef="dateCreate">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'customizableAlerts.dateCreate' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
      </ng-container>
      

      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
        <td mat-cell *matCellDef="let element" class="button" style="width: 60px;">
          <button *ngIf="rol.canByInput(rol.functIds.delete_custom_alerts)" matTooltip="{{ 'tooltips.delete' | translate }}" (click)="onDelete(element)" mat-icon-button class="iconoTabla delete-icon">
            <img class="delete-icon" src="assets/img/delete_icon.svg" alt="Delete icon">
          </button>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    
    <!-- Empty state -->
    <ng-container *ngIf="!dataSource.data.length">
      <app-table-empty-state></app-table-empty-state>
    </ng-container>

    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', { fileName: exportFileName })"><i class="bi bi-file-earmark-arrow-down"></i>
    </button>


    <!-- Paginador -->
    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>
  </div>

</main>